import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/HighlightOff";
import {
  Typography14,
  Typography16,
  Typography22,
} from "../../../../components/typography/Typography";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import { useState } from "react";
import InterestDetailsData from "../../Reusable/InterestDetailsData";
import SimpleLoader from "../../../../components/Loader";
import PaymentActionButton from "../../Reusable/PaymentActionButton";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { SuccessMsg } from "../../../../components/Messages/SuccessMsg";
import { fontFamilys, fontSizes } from "../../../../utils/FontSize";
import { numberFormat, removeSubstring } from "../../../../utils/Validations";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InterestTableList from "../InProcess/InterestTableList";

const ExpiredPaymentRequest = (props: any) => {
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const orderDetails = props && props.orderDetails;

  //  Redirect To Chat Page
  const RedirectToSellerChat = () => {
    navigate("/dashboard/chat", { state: { orderDetails: orderDetails } });
  };
  const orderDetailsBox = (label: string, value: any) => {
    return (
      <Box>
        <Typography
          fontSize={fontSizes.xsm}
          fontFamily={fontFamilys.publicSans}
          color="#919EAB"
        >
          {label}
        </Typography>
        <Typography
          color={label === "Amount" ? "green" : "#212B36"}
          fontWeight={label === "Amount" ? 700 : 500}
          sx={{ maxWidth: "400px" }}
          fontSize={fontSizes.sm}
          fontFamily={fontFamilys.publicSans}
        >
          {value ? value : "N/A"}
        </Typography>
      </Box>
    );
  };
  function formatString(inputString: string) {
    // Splitting the string based on underscore
    const splitString = inputString.split("_");

    // Capitalizing the first character of each word and joining them with space
    const result = splitString
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return result;
  }
  const { copy } = useCopyToClipboard();
  const onCopy = (text: string) => {
    if (text) {
      SuccessMsg("Copied Successfully!");
      copy(text);
    }
  };
  return (
    <div>
      <>
        <Box pb={2}>
          {/*  step 1 */}
          <Stack px={4} pt={4}>
            <Box display={"flex"} justifyContent={"start"}>
              <Button
                size="small"
                onClick={() => navigate(-1)}
                sx={{ backgroundColor: "#303981", px: 1, mt: "20px", }}
                style={{
                  marginBottom: "20px",
                  borderRadius: "8px",
                  backgroundColor: "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "600",
                  color: "white",
                  textTransform: "none",
                }}
              >
                <ArrowBackIcon fontSize="small" sx={{ height: "15px" }} /> Back{" "}
              </Button>
            </Box>
            <Box display={"flex"} justifyContent={"center"} mt={-9}>
              <Stack
                textAlign={"center"}
                justifyContent={"center"}
                direction={"row"}
                spacing={1}
              >
                <Box>
                  <CloseIcon sx={{ color: "#B71D18" }} fontSize="large" />
                </Box>
                <Box pt={0.5}>
                  <Typography22
                    fontSize={22}
                    color={"#B71D18"}
                    text={"Payment Expired "}
                  ></Typography22>
                </Box>
              </Stack>
            </Box>
            <Box display={"flex"} justifyContent={"center"} mb={2}>
              <Typography14
                text={`${
                  orderDetails &&
                  orderDetails.target &&
                  orderDetails.target.Legal_Name_of_Business
                } is assured with this payment`}
              ></Typography14>
            </Box>
          </Stack>
          {/* step2 */}
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              backgroundColor: "#F7F9FB",
            }}
            // pb={2}
          >
            <Box
              boxShadow={0.5}
              mx={4}
              sx={{
                borderRadius: "16px",
                backgroundColor: "white",
                border: "1px solid #EEF2FA",
                boxShadow:
                  "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              }}
            >
              {/*  Payment Order Details & Payment Seller Details */}
              <Box px={5} mt={1.5}>
                <Stack direction={"row"} spacing={1}>
                  <Box pt={0.5}>
                    <Typography
                      fontWeight={700}
                      fontFamily={fontFamilys.publicSans}
                    >
                      Order Details :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={"#F58336"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={700}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.orderID}
                      <Tooltip title="Copy" placement="top">
                        <IconButton
                          onClick={() =>
                            onCopy(
                              orderDetails &&
                                orderDetails.order &&
                                orderDetails.order.orderID
                            )
                          }
                        >
                          <Icon icon="eva:copy-fill" width={20} />
                        </IconButton>
                      </Tooltip>
                    </Typography>{" "}
                  </Box>
                </Stack>
              </Box>
              <Box
                px={8}
                mx={5}
                my={0.5}
                sx={{
                  backgroundColor: "var(--grey-100, #F9FAFB)",
                  border: "1px solid var(--grey-300, #DFE3E8)",
                  borderRadius: "7px",
                }}
              >
                <Stack my={1.5} direction={"row"} spacing={"auto"}>
                  {orderDetailsBox(
                    "Order Title",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.title
                  )}
                  {orderDetailsBox(
                    "Payment Type",
                    orderDetails &&
                      orderDetails.order &&
                      formatString(orderDetails.order.orderType)
                  )}
                  {orderDetailsBox(
                    "Payment Method",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.paymentType === "full"
                      ? "Full Payment"
                      : "Partial Payment"
                  )}
                  <Tooltip
                    title={
                      orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.remark
                    }
                  >
                    {orderDetailsBox(
                      "Description",
                      orderDetails &&
                        orderDetails.order &&
                        removeSubstring(orderDetails.order.remark)
                    )}
                  </Tooltip>
                  {orderDetailsBox(
                    "Amount",
                    orderDetails &&
                      orderDetails.order &&
                      numberFormat(orderDetails.order.orderAmount)
                  )}
                  {orderDetailsBox(
                    "Agreed Days",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.paymentDays
                  )}
                </Stack>
              </Box>
              <Box px={5} mt={1.5}>
                <Typography
                  fontFamily={fontFamilys.publicSans}
                  fontWeight={700}
                >
                  {orderDetails &&
                    orderDetails.target &&
                    orderDetails.target.targetType}{" "}
                  Details
                </Typography>
              </Box>
              <Box
                px={8}
                mx={5}
                // my={1}
                pt={1}
                sx={{
                  backgroundColor: " var(--grey-100, #F9FAFB)",
                  border: "1px solid var(--grey-300, #DFE3E8)",
                  borderRadius: "7px",
                  maxHeight: "160px",
                }}
              >
                <Box maxWidth={"400px"} mt={1.2}>
                  <Typography16
                    fontWeight={700}
                    color="#212B36"
                    text={
                      orderDetails.target
                        ? orderDetails.target.Legal_Name_of_Business
                        : "N/A"
                    }
                  ></Typography16>
                </Box>
                <Box sx={{ px: 2, py: 2 }}>
                  <Grid
                    container
                    sx={{}}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <Avatar
                          src={
                            orderDetails &&
                            orderDetails.target &&
                            orderDetails.target.aadharPhotoLink
                          }
                        />
                        {orderDetailsBox(
                          " Authorised Business Representative",
                          orderDetails && orderDetails.target
                            ? orderDetails.target.nameInAadhaar
                            : "N/A"
                        )}
                      </Stack>

                      <Box mt={2}>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Rating
                        </Typography>
                        <Rating
                          name="simple-controlled"
                          value={4}
                          disabled
                          size="small"
                        />
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Box>
                        <Stack direction={"row"}>
                          {orderDetailsBox(
                            "Contact",
                            orderDetails && orderDetails.target
                              ? orderDetails.target.business_mobile
                              : "N/A"
                          )}
                          <Box pt={1.5}>
                            <Tooltip title="Copy" placement="top">
                              <IconButton
                                onClick={() =>
                                  onCopy(
                                    orderDetails &&
                                      orderDetails.target &&
                                      orderDetails.target.business_mobile
                                  )
                                }
                              >
                                <Icon icon="eva:copy-fill" width={20} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Stack>
                      </Box>

                      <Box mt={1}>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Since
                        </Typography>
                        <Typography
                          fontFamily={"Public Sans"}
                          fontSize={"14px"}
                          lineHeight={"22px"}
                          fontWeight={600}
                        >
                          2023
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1.2 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Tooltip
                        title={
                          orderDetails &&
                          orderDetails.target &&
                          orderDetails.target.Place_of_Business
                        }
                      >
                        {orderDetailsBox(
                          "Address",
                          orderDetails &&
                            orderDetails.target &&
                            removeSubstring(
                              orderDetails.target.Place_of_Business
                            )
                        )}
                      </Tooltip>
                      <Box mt={2}>
                        <Typography
                          component="legend"
                          fontSize={"12px"}
                          color={"#919EAB"}
                        >
                          Orders Full-filed
                        </Typography>
                        <Stack spacing={1} display={"flex"} direction={"row"}>
                          <Typography
                            fontFamily={"Public Sans"}
                            fontSize={"14px"}
                            lineHeight={"22px"}
                            fontWeight={600}
                          >
                            {orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.ordersFullfilled}{" "}
                            /{" "}
                            {orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.totalOrders}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1.2 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Stack direction={"row"}>
                        {orderDetailsBox(
                          "GST",
                          orderDetails && orderDetails.target
                            ? orderDetails.target.GSTIN_of_the_entity
                            : "N/A"
                        )}
                        <Box pt={1.5}>
                          <Tooltip title="Copy" placement="top">
                            <IconButton
                              onClick={() =>
                                onCopy(
                                  orderDetails &&
                                    orderDetails.target &&
                                    orderDetails.target.GSTIN_of_the_entity
                                )
                              }
                            >
                              <Icon icon="eva:copy-fill" width={20} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>

                      <Box mt={2}>
                        <Stack textAlign={"start"}>
                          <Box>
                            <Typography
                              component="legend"
                              fontSize={"11px"}
                              color={"#919EAB"}
                              // mt={-2}
                            >
                              Connections
                            </Typography>
                            <Typography
                              fontFamily={"Public Sans"}
                              fontSize={"14px"}
                              lineHeight={"22px"}
                              fontWeight={600}
                            >
                              {(orderDetails &&
                                orderDetails.target &&
                                orderDetails.target.connections) ||
                                0}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>

                    {/*  */}
                  </Grid>
                </Box>
              </Box>

              {/* Blocked or Used Bank Balance AND Receivables Balance */}

              <InterestDetailsData
                props={orderDetails}
                Text1={"Balance Used"}
                Text2={"Receivables Used"}
              />

              {/* <PartialInterestTable props={orderDetails} /> */}
              <InterestTableList
                TableText={"Payment Configure Details"}
                props={orderDetails}
              />

              {/* Payment Action Btn for Cancle Payment or Redirect to chat with your seller */}
              <PaymentActionButton
                RedirectToSellerChat={RedirectToSellerChat}
                ButtonTextOne={"Chat with Seller"}
                Button1TrueFalse={true}
                ButtonColor1={"#303981"}
              />
            </Box>
          </Box>
        </Box>
      </>
    </div>
  );
};

export default ExpiredPaymentRequest;
