import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { Box, Chip, Stack, Typography, Popover, Tooltip } from "@mui/material";
import NoRecordFound from "../../components/NoRecordFound";
export default function DenseTable(props: any) {
  const kycUserList: any = props ? props.props : [];

  const chipBackgroundColor = (value: any) => {
    if (value === "active") {
      return "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))";
    } else {
      return "var(--transparent-error-16, rgba(255, 86, 48, 0.16))";
    }
  };
  const chipTextColor = (value: any) => {
    if (value === "active") {
      return "var(--primary-dark, #007B55)";
    } else {
      return "var(--error-dark, #B71D18)";
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
            <TableCell
              sx={{
                borderBottom: "none",
                px: 3,
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Business
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Contact
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Industry Type
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Products
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Status
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kycUserList.length > 0 &&
            kycUserList.map((item: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      px: 3,
                    }}
                  >
                    <Stack direction={"row"} display={"flex"} spacing={1}>
                      <Box>
                        <Stack>
                          <Typography
                            fontSize={13}
                            color={"#303891"}
                            fontFamily={"Public Sans"}
                            fontWeight={600}
                          >
                            {item.businessDetails &&
                            item.businessDetails.Legal_Name_of_Business !== ""
                              ? item.businessDetails.Legal_Name_of_Business
                              : "N/A"}{" "}
                          </Typography>
                          <Typography
                            fontSize={11}
                            color={"#637381"}
                            fontWeight={400}
                          >
                            {item.businessDetails && item.businessDetails
                              ? item.businessDetails.GSTIN_of_the_entity
                              : "N/A"}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item &&
                      item.userDetails &&
                      item.userDetails.business_mobile
                        ? item.userDetails.business_mobile
                        : "N/A"}{" "}
                    </Typography>
                    <Typography
                      fontSize={12}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item &&
                      item.userDetails &&
                      item.userDetails.business_email
                        ? item.userDetails.business_email
                        : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      // fontWeight={00}
                    >
                      {item.industryDetails &&
                      item.industryDetails.industryName !== ""
                        ? item.industryDetails.industryName
                        : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      // fontWeight={00}
                    >
                      {item.productDetails &&
                      item.productDetails[0].productName !== ""
                        ? item.productDetails[0].productName
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {" "}
                    <Chip
                      label={item.status}
                      style={{
                        backgroundColor: chipBackgroundColor(item.status),
                        color: chipTextColor(item.status),
                        fontWeight: 700,
                        fontSize: 12,
                        lineHeight: "20px",
                        fontFamily: "Public Sans",
                      }}
                      sx={{
                        minheight: "24px",
                        minwidth: "32px",
                        "& .MuiChip-label": {
                          fontWeight: 700,
                        },
                        borderRadius: "8px",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {/* <MoreVertIcon fontSize="small" /> */}
                    <div>
                      {/* <Typography onClick={(e: any) => handleClick(e, item)}>
                        <Chip
                          label={<MoreVertIcon fontSize="small" />}
                          sx={{
                            borderRadius: "50%",
                            minWidth: "40px",
                            minHeight: "40px",
                          }}
                        />
                      </Typography> */}

                      <Tooltip
                        title={
                          selectedItem.status === "inActive"
                            ? "Enable"
                            : "Disable"
                        }
                        arrow
                      >
                        <Box sx={{ cursor: "pointer" }}>
                          <Typography
                            onClick={(e: any) => handleClick(e, item)}
                          >
                            <Chip
                              label={<MoreVertIcon fontSize="small" />}
                              sx={{
                                borderRadius: "50%",
                                minWidth: "40px",
                                minHeight: "40px",
                                cursor: "pointer",
                              }}
                            />
                          </Typography>
                          {/* {selectedItem.status === "inActive" ? (
                            <Typography
                              fontSize={14}
                              fontFamily={"Public Sans"}
                              sx={{ p: 1.5 }}
                              fontWeight={600}
                              onClick={() =>
                                props.handleClickActiveStatus(
                                  selectedItem.businessDetails.user
                                )
                              }
                            >
                              Enable
                            </Typography>
                          ) : (
                            <Typography
                              fontSize={14}
                              fontFamily={"Public Sans"}
                              sx={{ p: 1.5 }}
                              fontWeight={600}
                              onClick={() =>
                                props.handleClickInActiveStatus(
                                  selectedItem.businessDetails.user
                                )
                              }
                            >
                              Disable
                            </Typography>
                          )} */}
                        </Box>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {kycUserList.length <= 0 ? (
        <NoRecordFound text1={"Business"} text2={"business"} />
      ) : (
        ""
      )}
    </>
  );
}
