import React, { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PreClaim_Wallet from "../../../../../assets/PreClaim_Wallet.svg";
import ModalDialog from "../../../../../components/Dialog/ModelDialog";
import { LoadingButton } from "@mui/lab";
const Ap_PreClaim = (props: any) => {
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);
  const modelTitle = "Model Title";
  const modelSubtitle =
    "This refers to the pre-claims that have been assured against your assured receivables.";

  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          AssuredPay Pre-Claimed
          <img src={PreClaim_Wallet} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "9px",
            }}
          >
            Loan taken against my future receivables
          </Typography>
          <InfoIcon
            sx={{ color: "#FFF", height: 25, width: 25 }}
            fontSize="small"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          />
          {/* <Typography
            variant="body1"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}
          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={modelTitle}
            modelSubtitle={modelSubtitle}
            BgColor={"#FB9E37"}
            TextColor={"#FFFFFF"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          0
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          YOU DON'T HAVE ANY ASSURED PRE-CLAIM
        </Typography>
      </Box>
      <Box my={2} mx={2}>
        <LoadingButton
          size="small"
          fullWidth
          sx={{ px: 2 }}
          style={{
            // marginLeft: 12,
            textTransform: "none",
            // backgroundColor: "#00AB55",
            color: "#FFFFFF",
            borderRadius: "8px",
            fontSize: "11px",
            border: "1px solid #fff",
            fontWeight: "700",
          }}
          type="submit"
        >
          View all Pre-claimed
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default Ap_PreClaim;
