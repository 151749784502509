import { CircularProgress } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { Typography12 } from "./typography/Typography";

const SimpleLoader = () => {
  return (
    <Box display={"flex"} justifyContent={"center"} py={15}>
      <Stack spacing={1} alignItems={"center"}>
        <CircularProgress color="inherit" sx={{ color: "grey" }} size={30} />
        <Typography12 text="Please Wait..."></Typography12>
      </Stack>
      {/* <LoadingScreen props={true} /> */}
    </Box>
  );
};

export default SimpleLoader;
