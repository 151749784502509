import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Payables_Wallet_Y from "../../../../assets/Payables_Wallet_Y.svg";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import AlertDialog from "../../../../components/Dialog/AlertDialog";
import { numberFormat, numberToEnglish } from "../../../../utils/Validations";
import ModalDialog from "../../../../components/Dialog/ModelDialog";

const Accept_Payables = (props: any) => {
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);
  const modelTitle = "Model Title";
  const modelSubtitle =
    "Model Subtitle for app level and we are going to save the value";
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const redirectTo = () => {
    navigate("/dashboard/selectpayment", { state: { order: "buyer" } });
  };

  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FB9E37",
            fontWeight: 700,
          }}
        >
          Accept Payables
          <img src={Payables_Wallet_Y} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            sx={{
              fontFamily: "Public Sans",
              color: "#FB9E37",
              fontSize: "9px",
            }}
          >
            Request received from sellers for payment
          </Typography>

          <InfoIcon
            sx={{ color: "#FB9E37", height: 25, width: 25 }}
            fontSize="medium"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          />
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Public Sans",
              color: "#FB9E37",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}
          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={modelTitle}
            modelSubtitle={modelSubtitle}
            BgColor={"#fff"}
            TextColor={"#FB9E37"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FB9E37",
            fontWeight: 700,
          }}
        >
          {numberFormat(
            props.AcceptPayablesAmount.receivedPaymentRequests?.totalSum
          ) || 0}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#FB9E37",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          {props.AcceptPayablesAmount.receivedPaymentRequests?.totalSum > 0
            ? numberToEnglish(
                props.AcceptPayablesAmount.receivedPaymentRequests?.totalSum
              ).toLocaleUpperCase()
            : "YOU DON'T HAVE ANY PAYABLES"}
        </Typography>
      </Box>
      <Box my={2} mx={2}>
        <Stack direction={{xs:"column",md:"row"}} spacing={0.5}>
          <LoadingButton
            disabled={
              props.AcceptPayablesAmount.receivedPaymentRequests?.totalSum <= 0
            }
            onClick={() => navigate("/dashboard/acceptrequest")}
            size="small"
            fullWidth
            sx={{ px: 1 }}
            style={{
              textTransform: "none",
              backgroundColor: "#FB9E37",
              color: "#fff",
              borderRadius: "8px",
              fontSize: "11px",
              border: "1px solid #FB9E37",
              fontWeight: "600",
            }}
            type="submit"
          >
            Payables Request
          </LoadingButton>
          <LoadingButton
            size="small"
            fullWidth
            onClick={() => setOpen(true)}
            sx={{ px: 1 }}
            style={{
              textTransform: "none",
              backgroundColor: "#FB9E37",
              color: "#fff",
              borderRadius: "8px",
              fontSize: "11px",
              border: "1px solid #FB9E37",
              fontWeight: "600",
            }}
          >
            New Payment
            <Add fontSize="small" />
          </LoadingButton>
        </Stack>
        <AlertDialog
          OpenDailog={open}
          setOpen={setOpen}
          iconColor={"#FB9E37"}
          TitleText={"MAKE NEW PAYMENT"}
          // dialog_Background_color={"#f8f3e7"}
          dialog_Background_color={"#fff"}
          textColor={"#FB9E37"}
          Subtitle={
            "You are initiating this payment to the seller. After confirmation, you will be redirected to the payment screen. Are you sure you want to proceed with this payment?"
          }
          ButtonText1={"Confirm"}
          ButtonText2={"Decline"}
          redirectTo={redirectTo}
        />
      </Box>
    </Grid>
  );
};

export default Accept_Payables;
