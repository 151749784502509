import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Header from "../../components/Header";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import ApiService from "../../api/api";
import Footer from "../../components/Footer";

export default function ForgetPassword() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [username, setusername] = useState("");
  const [errorMsg, seterrorMsg] = useState(false);
  const [OtpTextFieldDisabled, setOtpTextFieldDisabled] = useState(false);
  const [successOTPMsg, setsuccessOTPMsg] = useState(false);
  const [OTP, setOTP] = useState("");
  const [OTPbtnDisabled, setOTPbtnDisabled] = useState(true);
  const [errorMsgSend, seterrorMsgSend] = useState(false);
  const [VerifiedUserNameSuccefully, setVerifiedUserNameSuccefully] =
    useState(true);
  const navigate = useNavigate();
  const handleonChangeBtnDisabled = (e: any) => {
    seterrorMsg(false);
    // setsuccessMsg(false);
    setusername(e.target.value);
    if (e.target.value && !OtpTextFieldDisabled) {
      setbtnDisabled(false);
    } else {
      setbtnDisabled(true);
    }
  };
  const handleSubmit = async (event: any) => {
    setbtnDisabled(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const dashboardData: any = await ApiService.post(
      `/auth/sendforgotpassotp`,
      { username: data.get("username") }
    );
    if (dashboardData.status === 200) {
      setsuccessOTPMsg(true);
      setOtpTextFieldDisabled(true);
      setVerifiedUserNameSuccefully(false);
    } else {
      seterrorMsg(true);
      // setbtnDisabled(false);
      setOtpTextFieldDisabled(false);
    }
  };

  const handleonChangeSendBtnDisabled = (e: any) => {
    seterrorMsgSend(false);
    setOTP(e.target.value);
    if (e.target.value) {
      setOTPbtnDisabled(false);
    } else {
      setOTPbtnDisabled(true);
    }
  };
  const handleSubmitOnSend = async (event: any) => {
    setVerifiedUserNameSuccefully(true);
    setOTPbtnDisabled(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // Base Url From ENV
    const dashboardData: any = await ApiService.post(`/auth/forgot-password`, {
      username: username,
      otp: data.get("OTP"),
    });
    if (dashboardData.status === 200) {
      navigate("/login");
      enqueueSnackbar("Password Successfully changed", {
        variant: "error",
      });
    } else {
      setVerifiedUserNameSuccefully(false);
      seterrorMsgSend(true);
    }
  };
  return (
    <>
      <Header />
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 1,
            borderRadius: 1,
            px: 4,
            pt: 4,
            pb: 2,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Stack
            spacing={2}
            width={"400px"}
            sx={{
              mb: 2,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LockOpenIcon fontSize="large" />
            <Typography
              maxWidth={"sx"}
              variant="h5"
              component="div"
              fontSize={22}
              fontWeight={700}
              fontFamily={"Public Sans"}
              lineHeight={"30px"}
              textAlign={"center"}
              color={"#334A67"}
            >
              Forgot Your Password ?
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                mb: 5,
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                color={"#919EAB"}
                fontSize={13}
                fontWeight={500}
                textAlign={"center"}
                fontFamily={"Public Sans"}
              >
                Please enter the email address associated with your account and
                we'll send your password to your registered email address.
              </Typography>
            </Stack>
          </Stack>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1, maxWidth: "320px", minWidth: "320px" }}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              spacing={3}
              sx={{ mt: 3 }}
            >
              <TextField
                disabled={OtpTextFieldDisabled}
                error={errorMsg}
                helperText={errorMsg ? "Invalid Username Please try again" : ""}
                type="text"
                label="Username"
                name="username"
                onChange={handleonChangeBtnDisabled}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                  "& .MuiFormHelperText-root": {
                    color: "Red",
                    fontFamily: "Public Sans",
                    fontWeight: "600",
                    Height: "20px",
                    mt: 1,
                  },
                }}
                fullWidth
                margin="normal"
                // required
                id="username"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    height: "40px",
                    borderColor: "#919EAB",
                  },
                }}
              />
              <LoadingButton
                size="small"
                disabled={btnDisabled}
                sx={{ p: 1 }}
                style={{
                  minWidth: "100px",
                  marginLeft: 12,
                  textTransform: "none",
                  backgroundColor: btnDisabled
                    ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                    : "#00AB55",
                  color: btnDisabled ? "" : "white",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "700",
                  height: "5.2vh",
                  textAlign: "center",
                }}
                // loading={VerifyLoading}
                type="submit"
              >
                Get OTP
              </LoadingButton>
            </Stack>
            {successOTPMsg ? (
              <div>
                <Stack direction="row" spacing={0.5} sx={{ mt: 0, mb: 1 }}>
                  <DoneIcon style={{ fontSize: "16px", color: "#36B37E" }} />{" "}
                  <Typography
                    fontWeight={600}
                    fontSize={12}
                    fontFamily={"Public Sans"}
                    color={"#36B37E"}
                  >
                    {" "}
                    OTP Sent Successfully
                  </Typography>
                </Stack>
              </div>
            ) : (
              ""
            )}
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmitOnSend}
            sx={{ mb: 1, maxWidth: "320px", minWidth: "320px" }}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              spacing={3}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                disabled={VerifiedUserNameSuccefully}
                error={errorMsgSend}
                helperText={
                  errorMsgSend ? "Invalid OTP, Please try again !" : ""
                }
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: 14,
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 500,
                  },
                }}
                fullWidth
                name="OTP"
                label="Enter Verify OTP"
                id="OTP"
                type="text"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    height: "40px",
                    borderColor: "#919EAB",
                  },
                }}
                onChange={handleonChangeSendBtnDisabled}
              />
            </Stack>
            <Button
              disabled={OTPbtnDisabled}
              // color="rgba(145, 158, 171, 0.24)"
              size="small"
              color="inherit"
              sx={{
                my: 2,
                mb: "0px",
                borderRadius: 2,
                textTransform: "none",
              }}
              style={{
                fontSize: 16,
                backgroundColor: OTPbtnDisabled
                  ? "rgba(145, 158, 171, 0.24)"
                  : "#303981",
                fontFamily: "Public Sans",
                fontWeight: "700",
                color: OTPbtnDisabled
                  ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                  : "white",
              }}
              fullWidth
              variant="contained"
              type="submit"
            >
              Send{" "}
            </Button>
          </Box>
          {/* {showOtpVerifyField ? ( */}

          <Stack direction="row" spacing={1} sx={{ mb: 4, mt: 1 }}>
            <ArrowBackIosIcon sx={{ fontSize: "16px", mt: "3px" }} />
            <Link to={"/login"} style={{ textDecoration: "none" }}>
              {" "}
              <Typography
                fontWeight={600}
                fontSize={14}
                fontFamily={"Public Sans"}
                color={"#303981"}
              >
                {" "}
                Return to Sign In
              </Typography>
            </Link>
          </Stack>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
