import "../../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Typography20 } from "../../../components/typography/Typography";
import PaymentBusinessInfo from "../PaymentBusinessInfo";
import PaymentFlowStepper from "./PaymentFlowBuyer/PaymentFlowStepper";
import PRBusinessInfo from "./PRBusinessInfo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ApiService from "../../../api/api";
import { useSelector } from "react-redux";

const CreateNewPayment = () => {
  const navigate = useNavigate();
  // use location for get State Data from Other pages
  const location = useLocation();
  const newToken = useSelector((state: any) => state.user.Token);

  // Arrange Business Records as per selection of payment Request (Seller / Recipent)
  const BusinessDetailsForPayment =
    location.state && location.state.businessNetwork
      ? location.state.businessNetwork
      : location.state.orderDetails;
  const fromPr = location.state && location.state.fromPr;

  const handleEmptyDreft = async () => {
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.delete(
      "/checkerRoutes/draft/milestone"
    );
    if (responseData.status === 200) {
      navigate(-1);
    }
  };
  return (
    <div>
      {" "}
      <>
        {/* 1. Main Page Box / Title / Subtitle */}
        {/* No Touch Code---1 */}
        <Stack mx={4}>
          <Typography20
            sx={{ mt: 4 }}
            fontFamily={"Public Sans"}
            fontWeight={700}
            color={"#000000"}
            text={"Create New Payment"}
          ></Typography20>
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                fontFamily: "Public Sans",
              }}
            >
              Payments
            </Typography>
            <Typography>.</Typography>

            <Typography
              fontFamily={"Public Sans"}
              fontSize={"13px"}
              fontWeight={400}
              fontStyle={"normal"}
              color={"rgba(145, 158, 171, 1)"}
            >
              Create New Payment
            </Typography>
          </Stack>
          <Box display={"flex"} justifyContent={"end"} mt={-10}>
            <Button
              size="small"
              onClick={handleEmptyDreft}
              sx={{ backgroundColor: "#303981", px: 1 ,mt: "20px",}}
              style={{
                marginBottom: "20px",
                borderRadius: "8px",
                backgroundColor: "#303981",
                fontFamily: "Public Sans",
                fontWeight: "600",
                color: "white",
                textTransform: "none",
              }}
            >
              <ArrowBackIcon fontSize="small" sx={{ height: "15px" }} /> Back{" "}
            </Button>
          </Box>
        </Stack>
        {/* No Touch Code---1 */}
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
          pb={8}
        >
          {/* 2. Subtitle Box */}
          <Box
            boxShadow={1}
            mx={4}
            my={2}
            py={2}
            pb={4}
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
            }}
          >
            {fromPr ? (
              <>
                <PRBusinessInfo props={BusinessDetailsForPayment} />
              </>
            ) : (
              <PaymentBusinessInfo props={BusinessDetailsForPayment} />
            )}

            {/* Payment Box */}
            <Box>
              <PaymentFlowStepper />
            </Box>
          </Box>
        </Box>
      </>
    </div>
  );
};

export default CreateNewPayment;
