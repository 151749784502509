import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import {
  Typography14,
  Typography22,
} from "../../../components/typography/Typography";
// import PaymentConfirmIcon from "../../../assets/paymentConfirm.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const OrderStack = (props: any) => {
  const navigate = useNavigate();
  const OrderDetails = props ? props.props : {};
  const OrderText = props ? props.OrderText : "";
  const titleIcon = props ? props.titleIcon : "";
  const confirmationTitle = props ? props.confirmationTitle : "";
  const confirmationSubtitle = props ? props.confirmationSubtitle : "";
  const titleIconColor = props ? props.titleIconColor : "";
  return (
    <>
      <Stack px={4} pt={4}>
        <Box display={"flex"} justifyContent={"start"}>
          <Button
            size="small"
            onClick={() => navigate(-1)}
            sx={{ backgroundColor: "#303981", px: 1,mt: "20px", }}
            style={{
              marginBottom: "20px",
              borderRadius: "8px",
              backgroundColor: "#303981",
              fontFamily: "Public Sans",
              fontWeight: "600",
              color: "white",
              textTransform: "none",
            }}
          >
            <ArrowBackIcon fontSize="small" sx={{ height: "15px" }} /> Back{" "}
          </Button>
        </Box>
        <Box display={"flex"} justifyContent={"center"} mt={-9}>
          <Stack
            textAlign={"center"}
            justifyContent={"center"}
            direction={"row"}
            spacing={1}
          >
            <Box>
              <img src={titleIcon} height={35} width={35} alt="" />
            </Box>
            <Box pt={0.5}>
              <Typography22
                fontSize={22}
                // color={"#36B37E"}
                color={titleIconColor}
                text={confirmationTitle}
              ></Typography22>
            </Box>
          </Stack>
        </Box>
        <Box display={"flex"} justifyContent={"center"} mb={2}>
          <Typography14 text={confirmationSubtitle}></Typography14>
        </Box>
      </Stack>
    </>
  );
};

export default OrderStack;
