import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router";
import "./App.css";
import { SnackbarProvider } from "notistack";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

function App() {
  const Theme = createTheme({
    typography: {
      fontFamily: "Public Sans",
      fontWeightLight: "300",
      fontWeightRegular: "400",
      fontWeightMedium: "500",
    },
  });
  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2500}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // style={{
        //   color: " var(--success-darker, #0A5554)",
        //   // color: "rgba(54, 179, 126, 1)",
        //   backgroundColor: "var(--success-lighter, #D8FBDE)",
        //   marginRight: "100px",
        //   fontFamily: "Public Sans",
        //   fontWeight: 500,
        //   fontSize: "14px",
        //   lineHeight: "22px",
        //   maxWidth: "400px",
        //   marginTop: "50px",
        // }}
      >
        <MuiThemeProvider theme={Theme}>
          <BrowserRouter>
            <main className="MainPages">
              <Router />
            </main>
          </BrowserRouter>
        </MuiThemeProvider>
      </SnackbarProvider>
    </>
  );
}

export default App;
