import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Or '@mui/material/Grid'
import { Button, Divider, TextField, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PaymnetsIcon from "../../assets/paymentsImage.jpg"; // Adjust path as needed

const Payments = () => {
  const navigate = useNavigate();
  const [CouponCode, setCouponCode] = useState("FREE100");
  const [btnDisabled, setbtnDisabled] = useState(true);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log({
      couponCode: CouponCode,
    });
  };

  const handleProceedCheckout = () => {
    navigate("/auth/userkyc");
  };

  useEffect(() => {
    setbtnDisabled(CouponCode.trim() === "");
  }, [CouponCode]);

  return (
    <Box
      justifyContent={"center"}
      sx={{ display: "flex", flexDirection: "row", height: "100vh" }}
      alignItems={"center"}
    >
      <Grid container spacing={12} justifyContent="center">
        {/* Form Grid */}
        <Grid xs={12} md={6} lg={6}>
          <Box
            sx={{
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              borderRadius: 2,
              px: 4,
              pt: 4,
              pb: 2,
              mt: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack sx={{ mb: 4, position: "relative" }}>
              <Typography
                variant="h5"
                fontSize={21}
                fontWeight={700}
                fontFamily={"Public Sans"}
                lineHeight={"22px"}
                color={"#334A67"}
              >
                Onboarding Enrolment Fees
              </Typography>
              <Typography
                variant="h5"
                fontSize={14}
                fontWeight={400}
                fontFamily={"Public Sans"}
                lineHeight={"30px"}
                color={"#334A67"}
              >
                This is a one time fee.
              </Typography>

              {/* Fee Information */}
              <Stack
                direction="row"
                sx={{ mt: "20px", justifyContent: "space-between" }}
              >
                <div>
                  <Typography color="#919EAB" fontSize={14} fontWeight={400}>
                    One time Joining Fee
                  </Typography>
                  <Typography color="#212B36" fontSize={16} fontWeight={500}>
                    AssuredPay Inc.
                  </Typography>
                </div>
                <Typography fontWeight={500} fontSize={14} color="#212B36">
                  00 INR
                </Typography>
              </Stack>

              {/* Coupon Information */}
              <Stack
                direction="row"
                sx={{ mt: "20px", justifyContent: "space-between" }}
              >
                <div>
                  <Typography color="#919EAB" fontSize={14} fontWeight={400}>
                    Coupon Code
                  </Typography>
                  <Typography color="#212B36" fontSize={16} fontWeight={500}>
                    VSBDHJ-HSBS
                  </Typography>
                </div>
                <Typography fontWeight={500} color="green" fontSize={14}>
                  -00 INR
                </Typography>
              </Stack>

              <Divider sx={{ mt: "60px", maxWidth: "460px" }} />

              {/* Total Information */}
              <Stack
                direction="row"
                sx={{ mt: "20px", justifyContent: "space-between" }}
              >
                <div>
                  <Typography color="#212B36" fontSize={14} fontWeight={500}>
                    Total
                  </Typography>
                </div>
                <Typography fontWeight={500} fontSize={14} color="#212B36">
                  00 INR
                </Typography>
              </Stack>

              {/* Coupon Code Input */}
              <Box sx={{ my: 3 }} component="form" onSubmit={handleSubmit}>
                <Grid
                  container
                  alignItems={"flex-start"}
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Grid xs={8}>
                    <TextField
                      placeholder="Enter Coupon Code"
                      size="small"
                      value={CouponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      sx={{
                        minWidth: "40ch",
                        borderRadius: "8px",
                        "& .MuiFormLabel-root": {
                          color: "#919EAB",
                          fontSize: "15px",
                          fontWeight: "500",
                        },
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Button
                      size="small"
                      sx={{
                        textTransform: "none",
                        backgroundColor: btnDisabled
                          ? "var(--grey-300, #DFE3E8)"
                          : "#303981",
                        borderRadius: "8px",
                        mr: "5px",
                        color: btnDisabled ? "black" : "white",
                        "&:hover": {
                          backgroundColor: btnDisabled
                            ? "var(--grey-300, #DFE3E8)"
                            : "#DFE3E8",
                          color: "#303981", 
                        },
                      }}
                      type="submit"
                      disabled={btnDisabled}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Button
                size="large"
                color="inherit"
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                  fontSize: "14px",
                  backgroundColor: "#303981",
                  fontWeight: "700",
                  color: btnDisabled ? "black" : "white",
                  "&:hover": {
                    backgroundColor: btnDisabled
                      ? "var(--grey-300, #DFE3E8)"
                      : "#DFE3E8",
                    color: "#303981", 
                  },
                }}
                onClick={handleProceedCheckout}
                variant="contained"
              >
                Proceed To Checkout
              </Button>
            </Stack>
          </Box>
        </Grid>

        {/* Image Grid - Only visible on large screens */}
        <Grid
          xs={12}
          md={6}
          lg={6}
          sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}
        >
          <img
            src={PaymnetsIcon}
            style={{
              maxHeight: "450px",
              maxWidth: "550px",
              alignItems: "center",
            }}
            alt="Payments"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Payments;
