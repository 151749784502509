// import { LoadingButton } from "@mui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ReceiptIcon from "@mui/icons-material/Receipt";

const PaymentConfirmDialog = (props: any) => {
  console.log(props, "ye check props k liye hai");
  const OpenDailog = props ? props.OpenDailog : false;
  console.log(
    OpenDailog,
    "APConsumerFront/build/asset-manifest.json APConsumerFront/build/favicon.ico APConsumerFront/build/index.html APConsumerFront/build/manifest.json APConsumerFront/build/robots.txt APConsumerFront/build/static"
  );
  const TitleText = props ? props.TitleText : "";
  const ConfirmationText = props ? props.ConfirmationText : "";
  const iconColor = props ? props.iconColor : "#919EAB";
  const dialogBtnDisable = props ? props.dialogBtnDisable : false;
  return (
    <div>
      <Dialog
        open={OpenDailog}
        aria-labelledby="responsive-dialog-title"
        style={{ borderRadius: "10px" }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ px: 4, maxWidth: "500px" }}
        >
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <ReceiptIcon sx={{ color: iconColor }} />
            <Typography
              component="div"
              fontFamily={"Public Sans"}
              fontSize={"20px"}
              fontWeight={700}
              lineHeight={"28px"}
              color={"#212B36"}
            >
              {TitleText}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ px: 4, maxWidth: "430px" }}>
          <DialogContentText
            color={"#454F5B"}
            textAlign={"start"}
            justifyContent={"center"}
            alignSelf={"stretch"}
            fontFamily={"Public Sans"}
            fontSize={"16px"}
            fontWeight={400}
          >
            {ConfirmationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            textAlign: "center",
            px: 8,
            mb: 2,
            // maxWidth: "430px",
          }}
        >
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <LoadingButton
              loading={props.VerifyAcceptPaymentLoader || false}
              // type="submit"
              // disabled={dialogBtnDisable}
              sx={{ ml: 0.5, px: 3 }}
              style={{
                fontFamily: "Public Sans",
                color: "white",
                width: "auto",
                fontSize: "14px",
                backgroundColor: "#303981",
                textTransform: "none",
                borderRadius: "8px",
                fontWeight: "700",
              }}
              onClick={props.ApprovedPaymentConfigure}
            >
              Yes
            </LoadingButton>
            <Button
              sx={{ ml: 0.5, px: 2, py: 0.6 }}
              style={{
                fontFamily: "Public Sans",
                color: "Black",
                width: "auto",
                border: "1px solid ",
                fontSize: "14px",
                textTransform: "none",
                borderRadius: "8px",
                fontWeight: "700",
              }}
              // onClick={}
              onClick={props.ClosePaymentAcceptDialog}
            >
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentConfirmDialog;
