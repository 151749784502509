import { Box, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  const companyName = process.env.companyName || "Assured Pay";
  return (
    <div>
      <Box
        component="footer"
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "#F7F9FB",
          display:"flex",
          justifyContent:"space-between",
          boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          fontWeight={700}
          color={"var(--text-light-secondary, #637381)"}
          fontFamily={"Public Sans"}
          fontSize={14}
        >
          © {new Date().getFullYear()} {companyName}.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          All rights reserved.
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
