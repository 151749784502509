import { Box, Grid, Stack, Typography } from "@mui/material";
import AcceptR_Wallet from "../../../../assets/AcceptR_Wallet.svg";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import AlertDialog from "../../../../components/Dialog/AlertDialog";
import { numberFormat, numberToEnglish } from "../../../../utils/Validations";
import ModalDialog from "../../../../components/Dialog/ModelDialog";

const Request_New_Receivables = (props: any) => {
  const navigate = useNavigate();
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);

  const modelTitle = "Model Title";
  const modelSubtitle =
    "This refers to the payment requests you make to to you or business network. ";
  const [open, setOpen] = useState(false);
  const redirectTo = () => {
    navigate("/dashboard/selectpayment", { state: { order: "seller" } });
  };
  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#36A979",
            fontWeight: 700,
          }}
        >
          Request for Receivables
          <img src={AcceptR_Wallet} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Public Sans",
              color: "#36A979",
              fontSize: "9px",
            }}
          >
            My future receivables, payments & pre-claim.
          </Typography>

          <InfoIcon
            sx={{ color: "#36A979", height: 25, width: 25 }}
            fontSize="small"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          />
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Public Sans",
              color: "#36A979",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}
          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={modelTitle}
            modelSubtitle={modelSubtitle}
            BgColor={"#FFFFFF"}
            TextColor={"#36A979"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#36A979",
            fontWeight: 700,
          }}
        >
          {numberFormat(
            props.RequestNewReceivablesAmount.createdPaymentRequests?.totalSum
          ) || 0}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#36A979",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          {/* AT PRESENT, NO ASSURED RECEIVABLES ARE AVAILABLE  */}
          {props.RequestNewReceivablesAmount.createdPaymentRequests?.totalSum > 0
            ? numberToEnglish(
                props.RequestNewReceivablesAmount?.createdPaymentRequests
                  ?.totalSum
              ).toLocaleUpperCase()
            : "YOU DON'T HAVE ANY RECEIVABLES."}
        </Typography>
      </Box>
      <Box my={2} mx={2}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={0.5}>
          <LoadingButton
            disabled={
              props.RequestNewReceivablesAmount.createdPaymentRequests
                ?.totalSum <= 0
            }
            onClick={() => navigate("/dashboard/requestpayments")}
            size="small"
            sx={{ px: 1 }}
            style={{
              textTransform: "none",
              backgroundColor: "#36A979",
              color: "#fff",
              borderRadius: "8px",
              fontSize: "11px",
              border: "1px solid #36A979",
              fontWeight: "600",
            }}
            type="submit"
          >
            View Requests
          </LoadingButton>
          <LoadingButton
            size="small"
            sx={{ px: 1 }}
            // onClick={() =>
            //   navigate("/dashboard/selectpayment", {
            //     state: { order: "buyer" },
            //   })
            // }
            onClick={() => setOpen(true)}
            style={{
              textTransform: "none",

              backgroundColor: "#36A979",
              color: "#fff",
              borderRadius: "8px",
              fontSize: "11px",
              border: "1px solid #36A979",
              fontWeight: "600",
            }}
            type="submit"
          >
            New Receivable <Add fontSize="small" />
          </LoadingButton>
        </Stack>
        {/* sx=
        {{
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow:
            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
        }} */}
        <AlertDialog
          OpenDailog={open}
          setOpen={setOpen}
          iconColor={"#36A979"}
          TitleText={"MAKE NEW REQUEST"}
          dialog_Background_color={"#fff"}
          textColor={"#36A979"}
          Subtitle={
            "You are initiating this payment request to buyer. After confirmation, you will be redirected to the payment request screen. Are you sure you want to proceed with this request ?"
          }
          ButtonText1={"Confirm"}
          ButtonText2={"Decline"}
          redirectTo={redirectTo}
        />
      </Box>
    </Grid>
  );
};

export default Request_New_Receivables;
