import "../../../App.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ic_info from "../../../assets/ic_info.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import {
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
  Tab,
  Tabs,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";

import { useSelector } from "react-redux";
import {
  Typography12,
  Typography14,
} from "../../../components/typography/Typography";
import { enqueueSnackbar } from "notistack";
import {
  daysConversionFromDate,
  findGreatest,
  getPaymentDays,
  numberToEnglish,
  onlyNumberValidation,
} from "../../../utils/Validations";
import PaymentBusinessInfo from "../PaymentBusinessInfo";
import PaymentInterestConfig from "./PaymentInterestConfig";
import PaymentConfirmDialog from "../Reusable/PaymentConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { SuccessMsg } from "../../../components/Messages/SuccessMsg";
import ApiService from "../../../api/api";
const CreateNewPaymentSeller = () => {
  const GeneratePayment = "seller";

  // use location for get State Data from Other pages
  const location = useLocation();
  // set business Data
  const BusinessDetailsForPayment = location.state
    ? location.state.businessNetwork
    : {};
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("MakePayment");
  const [PaymentMethod, setPaymentMethod] = useState("full");
  const newToken = useSelector((state: any) => state.user.Token);
  const navigate = useNavigate();
  const [interestDetailsArray, setinterestDetailsArray] = useState<any>([]);
  const paymentType = [
    { label: "Payment Against Invoice", value: "payment_against_invoice" },
    { label: "Advance Payment", value: "advanced_payment" },
    { label: "Payment on Account", value: "payment_on_account" },
  ];
  const [CreatePaymentOrder, setCreatePaymentOrder] = useState({
    orderTitle: "",
    PO: "",
    amount: "",
    paymentType: "full",
    paymentDays: "",
    useBankBalance: "",
    description: "",
    orderType: "",
  });

  const handleOnChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setTabValue(newValue);
  };
  const createOrderForPayment = (event: any) => {
    const { name, value } = event.target;
    setCreatePaymentOrder((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };
  const [dialogBtnDisable, setdialogBtnDisable] = useState(false);
  const [verifybtnLoader, setverifybtnLoader] = useState(false);
  const HandleonClickSendPayment = async (e: any) => {
    e.preventDefault();
    setverifybtnLoader(true);
    setdialogBtnDisable(true);
    let body = {};
    if (PaymentMethod === "full") {
      body = {
        paymentRequestDetails: {
          business_id: BusinessDetailsForPayment.businessDetails.user,
          paymentType: PaymentMethod,
          recipientName:
            BusinessDetailsForPayment.businessDetails.Legal_Name_of_Business,
          remark: CreatePaymentOrder.description,
          title: CreatePaymentOrder.orderTitle,
          POPI: CreatePaymentOrder.PO,
          orderAmount: parseInt(CreatePaymentOrder.amount),
          paymentIdentifier: "seller",
          paymentDays: parseInt(CreatePaymentOrder.paymentDays),
          paymentSettlementDate: getPaymentDays(CreatePaymentOrder.paymentDays),
          orderType: CreatePaymentOrder.orderType,
        },
        // milestones Config
        milestonesDetails: [
          {
            date: getPaymentDays(CreatePaymentOrder.paymentDays),
            days: parseInt(CreatePaymentOrder.paymentDays),
            amount: parseInt(CreatePaymentOrder.amount),
          },
        ],
      };
    } else {
      if (parseInt(partialPaymentOption) === 2) {
        body = {
          paymentRequestDetails: {
            paymentSettlementDate: getPaymentDays(
              partialPaymentDays.milestonePaymentDays2
            ),
            business_id: BusinessDetailsForPayment.businessDetails.user,
            recipientName:
              BusinessDetailsForPayment.businessDetails.Legal_Name_of_Business,
            paymentType: PaymentMethod,
            remark: CreatePaymentOrder.description,
            title: CreatePaymentOrder.orderTitle,
            orderAmount: parseInt(CreatePaymentOrder.amount),
            POPI: CreatePaymentOrder.PO,
            paymentIdentifier: "seller",
            orderType: CreatePaymentOrder.orderType,

            paymentDays: findGreatest(
              parseInt(partialPaymentDays.milestonePaymentDays1),
              parseInt(partialPaymentDays.milestonePaymentDays2)
            ),
          },
          // milestones Config
          milestonesDetails: [
            {
              date: getPaymentDays(partialPaymentDays.milestonePaymentDays1),
              days: parseInt(partialPaymentDays.milestonePaymentDays1),
              amount: parseInt(partialPaymentDays.milestoneAmount1),
            },
            {
              date: getPaymentDays(partialPaymentDays.milestonePaymentDays2),
              days: parseInt(partialPaymentDays.milestonePaymentDays2),
              amount: parseInt(partialPaymentDays.milestoneAmount2),
            },
          ],
        };
      } else {
        body = {
          paymentRequestDetails: {
            paymentSettlementDate: getPaymentDays(
              partialPaymentDays.milestonePaymentDays3
            ),
            business_id: BusinessDetailsForPayment.businessDetails.user,
            recipientName:
              BusinessDetailsForPayment.businessDetails.Legal_Name_of_Business,
            paymentType: PaymentMethod,
            remark: CreatePaymentOrder.description,
            title: CreatePaymentOrder.orderTitle,
            POPI: CreatePaymentOrder.PO,
            orderAmount: parseInt(CreatePaymentOrder.amount),
            orderType: CreatePaymentOrder.orderType,
            paymentIdentifier: "seller",
            paymentDays: findGreatest(
              parseInt(partialPaymentDays.milestonePaymentDays1),
              parseInt(partialPaymentDays.milestonePaymentDays2),
              parseInt(partialPaymentDays.milestonePaymentDays3)
            ),
          },
          // milestones Config
          milestonesDetails: [
            {
              date: getPaymentDays(partialPaymentDays.milestonePaymentDays1),
              days: parseInt(partialPaymentDays.milestonePaymentDays1),
              amount: parseInt(partialPaymentDays.milestoneAmount1),
            },
            {
              date: getPaymentDays(partialPaymentDays.milestonePaymentDays2),
              days: parseInt(partialPaymentDays.milestonePaymentDays2),
              amount: parseInt(partialPaymentDays.milestoneAmount2),
            },
            {
              date: getPaymentDays(partialPaymentDays.milestonePaymentDays3),
              days: parseInt(partialPaymentDays.milestonePaymentDays3),
              amount: parseInt(partialPaymentDays.milestoneAmount3),
            },
          ],
        };
      }
    }

    const token = await ApiService.setToken(newToken);
    const responseData: any = await ApiService.post(
      "/checkerRoutes/request/payment",
      body
    );

    if (responseData.status === 200) {
      SuccessMsg("Payment Request created Successfully");
      navigate("/dashboard/orderrequest", {
        state: {
          orderDetails: responseData.data.paymentRequest,
          GeneratePayment: GeneratePayment,
        },
      });
    } else {
      setdialogBtnDisable(false);
      console.log(responseData);
      if (responseData.response.data.error) {
        enqueueSnackbar(responseData.response.data.error, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Something went wrong, Please try again", {
          variant: "error",
        });
      }
    }
    setOpenAccept(false);
  };

  const getRejectedDocus = async () => {
    const token = await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/checkerRoutes/getrejecteddocuments`
    );
    if (responseData.status === 200) {
      SuccessMsg("Payment Request created Successfully");
      navigate("/dashboard/request/payment", {
        state: {
          orderDetails: responseData.data.paymentRequest,
          GeneratePayment: GeneratePayment,
        },
      });
      setverifybtnLoader(false);
    } else {
      setdialogBtnDisable(false);
      if (responseData.response.data.error) {
        enqueueSnackbar(responseData.response.data.error, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Something went wrong, Please try again", {
          variant: "error",
        });
      }
      setverifybtnLoader(false);
    }
  };

  const resetOnClickcreatePaymentValue = () => {
    setCreatePaymentOrder({
      orderTitle: "",
      PO: "",
      amount: "",
      paymentType: "",
      paymentDays: "",
      useBankBalance: "",
      description: "",
      orderType: "",
    });
    setinterestDetailsArray([]);
    setpartialPaymentOption("1");
    setpartialPaymentDays({});
    setContinueBtn(false);
    setpartialContinueBtn(false);
  };

  const [continueBtn, setContinueBtn] = useState(false);
  const [partialContinueBtn, setpartialContinueBtn] = useState(false);
  const arrangeArrayForInterestDetails = () => {
    setContinueBtn(true);
    let body = [
      {
        amount: CreatePaymentOrder.amount,
        amountType: "Full Payment",
        platformFee: 0,
        total: CreatePaymentOrder.amount,
        days: CreatePaymentOrder.paymentDays,
      },
    ];
    if (
      parseInt(CreatePaymentOrder.amount) > 0 &&
      parseInt(CreatePaymentOrder.paymentDays)
    ) {
      setinterestDetailsArray(body);
    } else {
      setinterestDetailsArray([]);
    }
  };

  const arrangeArrayForPartialInterestDetails = () => {
    setpartialContinueBtn(true);
    let milestoneCount = parseInt(partialPaymentOption);
    if (milestoneCount > 0) {
      let body = {};
      if (milestoneCount === 2) {
        body = [
          {
            amount: partialPaymentDays.milestoneAmount1,
            amountType: "Partial Payment",
            platformFee: 0,
            total: partialPaymentDays.milestoneAmount1,
            days: partialPaymentDays.milestonePaymentDays1,
          },
          {
            amount: partialPaymentDays.milestoneAmount2,
            amountType: "Partial Payment",
            platformFee: 0,
            total: partialPaymentDays.milestoneAmount2,
            days: partialPaymentDays.milestonePaymentDays2,
          },
        ];
        setinterestDetailsArray(body);
      } else if (milestoneCount === 3) {
        body = [
          {
            amount: partialPaymentDays.milestoneAmount1,
            platformFee: 0,
            amountType: "Partial Payment",
            total: partialPaymentDays.milestoneAmount1,
            days: partialPaymentDays.milestonePaymentDays1,
          },
          {
            amount: partialPaymentDays.milestoneAmount2,
            amountType: "Partial Payment",
            platformFee: 0,
            total: partialPaymentDays.milestoneAmount2,
            days: partialPaymentDays.milestonePaymentDays2,
          },
          {
            amount: partialPaymentDays.milestoneAmount3,
            amountType: "Partial Payment",
            platformFee: 0,
            total: partialPaymentDays.milestoneAmount3,
            days: partialPaymentDays.milestonePaymentDays3,
          },
        ];
        setinterestDetailsArray(body);
      } else {
        setinterestDetailsArray([]);
      }
    }
  };

  // partial payment setup
  const [partialPaymentOption, setpartialPaymentOption] = useState<string>("");
  const selectMilestones = (event: any) => {
    setpartialPaymentOption(event.target.value);
  };
  const [partialAmt1, setPartialAmt1] = useState<number>(0);
  const [partialAmt2, setPartialAmt2] = useState<number>(0);
  const [partialAmt3, setPartialAmt3] = useState<number>(0);

  // step 3 - Partial Payment Days & Amount Selection/Input Option
  const [partialPaymentDays, setpartialPaymentDays] = useState<any>({
    milestoneOption: 1,
    milestonePaymentDays1: 0,
    milestoneAmount1: 0,
    useBankBalance1: 0,
    useBankBalance2: 0,
    useBankBalance3: 0,
    milestonePaymentDays2: 0,
    milestoneAmount2: 0,
    milestonePaymentDays3: 0,
    milestoneAmount3: 0,
  });

  const partialPaymentDaysSelection = (event: any) => {
    const { name, value } = event.target;
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
    if (name === "milestoneAmount1") {
      setPartialAmt1(parseInt(value));
    } else if (name === "milestoneAmount2") {
      setPartialAmt2(parseInt(value));
    } else if (name === "milestoneAmount3") {
      setPartialAmt3(parseInt(value));
    }
  };

  const [openAccept, setOpenAccept] = useState(false);

  // Final Order Confirmation
  const confirmPaymentOrder = () => {
    setOpenAccept(true);
  };
  const ClosePaymentAcceptDialog = () => {
    setOpenAccept(false);
  };

  const handleonChangeForDate = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setCreatePaymentOrder((prevState: any) => ({
      ...prevState, // Copy the previous state
      paymentDays: selectedDate,
      // isGSTDetailSaveManually: true, // Add the new key-value pair
    }));
  };
  const handleonChangeForDate1 = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setpartialPaymentDays((prevState: any) => ({
      ...prevState, // Copy the previous state
      milestonePaymentDays1: selectedDate,
      selectedDate1: value,
    }));
  };
  const handleonChangeForDate2 = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setpartialPaymentDays((prevState: any) => ({
      ...prevState, // Copy the previous state
      milestonePaymentDays2: selectedDate,
      selectedDate2: value,
    }));
    secondMilestoneAmount();
  };
  const handleonChangeForDate3 = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setpartialPaymentDays((prevState: any) => ({
      ...prevState, // Copy the previous state
      milestonePaymentDays3: selectedDate,
      selectedDate3: value,
    }));
    secondMilestoneAmount();
  };
  const minDaysValidation = dayjs().add(7, "day");
  const maxDaysValidation = dayjs().add(180, "day");

  // partial
  const minDaysValidation2 = dayjs().add(
    partialPaymentDays.milestonePaymentDays1,
    "day"
  );
  const maxDaysValidation2 = dayjs().add(180, "day");
  const minDaysValidation3 = dayjs().add(
    partialPaymentDays.milestonePaymentDays2,
    "day"
  );
  const maxDaysValidation3 = dayjs().add(180, "day");

  const emptyResetValue = () => {
    setCreatePaymentOrder({
      orderTitle: "",
      PO: "",
      amount: "",
      paymentType: "",
      paymentDays: "",
      useBankBalance: "",
      description: "",
      orderType: "",
    });
    setpartialPaymentDays({});
    setpartialPaymentOption("");
  };

  const secondMilestoneAmount = () => {
    console.log("cll huuu");
    let leftAmt = parseInt(CreatePaymentOrder.amount);
    leftAmt -= parseInt(partialPaymentDays.milestoneAmount1);
    if (parseInt(partialPaymentOption) === 2) {
      setpartialPaymentDays((prevState: any) => ({
        ...prevState, // Copy the previous state
        milestoneAmount2: leftAmt,
      }));
    } else if (
      parseInt(partialPaymentOption) === 3 &&
      partialPaymentDays.milestoneAmount2
    ) {
      leftAmt -= partialPaymentDays.milestoneAmount2;
      setpartialPaymentDays((prevState: any) => ({
        ...prevState, // Copy the previous state
        milestoneAmount3: leftAmt,
      }));
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 200,
        borderRadius: "8px",
      },
    },
  };

  // Ap Fees Data
  const [platformFee, setplatformFee] = useState<number>(0);
  const getplatformFee = async () => {
    const token = await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(`/auth/getlegaldocuments`);
    if (responseData.status === 200) {
      let platformFee = responseData.data.result[0].platformFee;
      setplatformFee(platformFee);
    } else {
      console.log(responseData);
    }
  };
  useEffect(() => {
    getplatformFee();
  }, []);
  const platformFeeData = (value: number) => {
    const getFees = (value * platformFee) / 100;
    return getFees || 0;
  };
  console.log(platformFee, "platformFee");
  return (
    <div>
      {" "}
      <>
        <Stack mx={4}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"20px"}
            sx={{ mt: 4 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Request New Payment
          </Typography>
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                fontFamily: "Public Sans",
              }}
            >
              Payments
            </Typography>
            <Typography>.</Typography>

            <Typography
              fontFamily={"Public Sans"}
              fontSize={"13px"}
              fontWeight={400}
              fontStyle={"normal"}
              color={"rgba(145, 158, 171, 1)"}
            >
              Create New Payment Request
            </Typography>
          </Stack>
        </Stack>
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
          pb={8}
        >
          <Box
            boxShadow={1}
            mx={4}
            my={2}
            py={2}
            pb={4}
            component={"form"}
            // onSubmit={HandleonClickSendPayment}
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
            }}
          >
            <PaymentBusinessInfo props={BusinessDetailsForPayment} />
            <Box mx={3}>
              <Tabs
                value={tabValue}
                onChange={handleOnChangeTabValue}
                sx={{
                  // px: 2,
                  "& .MuiTabs-indicator": {
                    width: "auto",
                    backgroundColor: "rgba(48, 57, 129, 1)",
                    ml: 1,
                  },
                  "& .MuiTab-textColorPrimary": {
                    color: "rgba(33, 43, 54, 1)",
                  },
                  "& .MuiTab-textColorSecondary": {
                    color: "rgba(33, 43, 54, 1)",
                  },
                }}
              >
                <Tab
                  icon={<PaymentIcon fontSize="small" />}
                  iconPosition="start"
                  label="Request To Pay"
                  value={"MakePayment"}
                  sx={{
                    "&.MuiTab-labelIcon": {
                      fontSize: "13px",
                      fontWeight: "600",
                      fontFamily: "Public Sans",
                      textTransform: "none",
                    },
                  }}
                />
                {/* <Tab
                  icon={<ArticleIcon fontSize="small" />}
                  iconPosition="start"
                  label="Previous Payment"
                  sx={{
                    "&.MuiTab-labelIcon": {
                      fontSize: "13px",
                      fontWeight: "600",
                      fontFamily: "Public Sans",
                      textTransform: "none",
                    },
                  }}
                /> */}
              </Tabs>
              <Stack mx={2} my={3} spacing={2}>
                <TextField
                  label="Order Title (Optional)"
                  size="medium"
                  type="text"
                  fullWidth
                  name="orderTitle"
                  value={CreatePaymentOrder.orderTitle}
                  onChange={createOrderForPayment}
                  disabled={
                    parseInt(CreatePaymentOrder.amount) > 0 ? true : false
                  }
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "#919EAB",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 50,
                      maxWidth: "50%",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      Height: "20px",
                    },
                  }}
                />
                <TextField
                  label="PO/PI (Optional)"
                  size="medium"
                  type="text"
                  name="PO"
                  fullWidth
                  value={CreatePaymentOrder.PO}
                  onChange={createOrderForPayment}
                  disabled={
                    parseInt(CreatePaymentOrder.amount) > 0 ? true : false
                  }
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "#919EAB",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 50,
                      // minWidth: "23vw",
                      maxWidth: "50%",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      Height: "20px",
                    },
                  }}
                />
                <FormControl sx={{ maxWidth: "50%" }}>
                  <InputLabel
                    sx={{
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "16",
                    }}
                  >
                    Payment Type
                  </InputLabel>
                  <Select
                    name="orderType"
                    label="Request Type"
                    disabled={CreatePaymentOrder.amount !== ""}
                    value={CreatePaymentOrder.orderType}
                    onChange={createOrderForPayment}
                    sx={{
                      border: "none",
                      borderRadius: "8px",
                      height: 50,
                      "& .MuiSelectLabel-root": {
                        color: "#919EAB",
                        fontFamily: "Public Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        Height: "20px",
                      },
                    }}
                    MenuProps={MenuProps}
                  >
                    {paymentType.map((name, index) => (
                      <MenuItem key={index} value={name.value}>
                        <Stack display={"flex"} direction={"row"} spacing={1}>
                          <Typography
                            fontFamily={"Public Sans"}
                            fontSize={"14px"}
                            fontWeight={400}
                          >
                            {name.label}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ px: 2, maxWidth: "50%" }}>
                  <RadioGroup
                    row
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    // defaultValue={true}

                    value={PaymentMethod}
                    sx={{
                      "& .MuiRadio-root.Mui-checked": {
                        color: "#919EAB",
                      },
                    }}
                  >
                    <FormControlLabel
                      control={<Radio size="small" />}
                      disabled={
                        parseInt(CreatePaymentOrder.amount) > 0 ? true : false
                      }
                      label={<Typography12 text="Full Payment"></Typography12>}
                      value={"full"}
                    />
                    {CreatePaymentOrder.orderType ===
                    "payment_against_invoice" ? (
                      <FormControlLabel
                        value={"partial"}
                        disabled={
                          parseInt(CreatePaymentOrder.amount) > 0 ? true : false
                        }
                        control={<Radio size="small" />}
                        label={
                          <Typography12 text="Partial Payment"></Typography12>
                        }
                      />
                    ) : (
                      ""
                    )}
                  </RadioGroup>
                </FormControl>
                <TextField
                  label="Amount"
                  size="medium"
                  type="text"
                  helperText={
                    parseInt(CreatePaymentOrder.amount) > 0
                      ? numberToEnglish(parseInt(CreatePaymentOrder.amount))
                          .charAt(0)
                          .toUpperCase() +
                        numberToEnglish(
                          parseInt(CreatePaymentOrder.amount)
                        ).slice(1)
                      : "Acceptance in next 48 hours."
                  }
                  disabled={
                    parseInt(CreatePaymentOrder.paymentDays) ||
                    partialPaymentDays.milestoneAmount1
                  }
                  name="amount"
                  fullWidth
                  value={onlyNumberValidation(CreatePaymentOrder.amount)}
                  onChange={createOrderForPayment}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "#919EAB",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 50,
                      maxWidth: "50%",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      Height: "20px",
                    },
                  }}
                />

                {PaymentMethod === "full" ? (
                  <>
                    {/* <TextField
                      label="Payment Days"
                      size="medium"
                      type="number"
                      name="paymentDays"
                      className={"hide-spin-buttons"}
                      fullWidth
                      // disabled
                      value={CreatePaymentOrder.paymentDays}
                      onChange={createOrderForPayment}
                      onBlur={arrangeArrayForInterestDetails}
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          borderColor: "#919EAB",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 50,
                          maxWidth: "50%",
                          // minWidth: "23vw",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#919EAB",
                          fontFamily: "Public Sans",
                          fontWeight: "500",
                          fontSize: "14px",
                          Height: "20px",
                        },
                      }}
                      
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Payment Date"
                          format="DD/MM/YYYY"
                          disablePast
                          disabled={
                            interestDetailsArray.length > 0 ? true : false
                          }
                          // defaultValue={dayjs}
                          minDate={minDaysValidation}
                          maxDate={maxDaysValidation}
                          sx={{
                            style: {
                              borderRadius: "8px",
                            },
                          }}
                          // value={
                          //   CreatePaymentOrder
                          //     ? dayjs(
                          //         CreatePaymentOrder.paymentDays
                          //       )
                          //     : ""
                          // }
                          onChange={(value: any) =>
                            handleonChangeForDate(
                              moment(value ? value.$d : "").format("YYYY/MM/DD")
                            )
                          }
                          slotProps={{
                            textField: {
                              size: "small",
                              helperText:
                                CreatePaymentOrder.paymentDays !== ""
                                  ? `You have selected ${
                                      CreatePaymentOrder.paymentDays
                                    } days payment configuration from ${new Date().toDateString()}`
                                  : "Payment Days should between 7 to 180 days.",
                              sx: {
                                "& .MuiInputBase-root": {
                                  height: 50,
                                  borderRadius: "8px",
                                },
                                "& .MuiFormLabel-root": {
                                  color: "#919EAB",
                                  fontFamily: "Public Sans",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  mt: 0.2,
                                },
                              },
                              style: {
                                borderRadius: "16px",
                                borderColor: "#919EAB",
                                width: "100%",
                                maxWidth: "50%",
                                margin: 0,
                              },
                            },

                            layout: {
                              sx: {
                                ".css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    color: "white",
                                    borderRadius: 5,
                                    borderWidth: 1,
                                    borderColor: "#303981",
                                    border: "1px solid",
                                    backgroundColor: "#303981",
                                  },
                                ".css-1beqopj-MuiPickersYear-yearButton.Mui-selected":
                                  {
                                    color: "white",
                                    borderRadius: 5,
                                    borderWidth: 1,
                                    borderColor: "#303981",
                                    border: "1px solid",
                                    backgroundColor: "#303981",
                                  },
                                ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                                  {
                                    color: "#303981",
                                    borderRadius: 2,
                                    borderWidth: 1,
                                    borderColor: "#303981",
                                    border: "1px solid",
                                    // backgroundColor: "#303981",
                                  },
                              },
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </>
                ) : (
                  ""
                )}
                {PaymentMethod === "partial" ? (
                  <>
                    <>
                      <Stack mb={2}>
                        <FormControl sx={{ maxWidth: "50%" }}>
                          <InputLabel>
                            {" "}
                            <Typography14
                              text={"Select Milestone"}
                              fontWeight={400}
                              color="#919EAB"
                            ></Typography14>{" "}
                          </InputLabel>
                          <Select
                            sx={{ borderRadius: "8px" }}
                            value={partialPaymentOption}
                            disabled={partialPaymentDays.milestoneAmount1}
                            label="Select Milestone"
                            onChange={selectMilestones}
                          >
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                      <>
                        {(parseInt(partialPaymentOption) === 2 ||
                          parseInt(partialPaymentOption) === 3) &&
                        parseInt(CreatePaymentOrder.amount) > 0 ? (
                          <Box
                            flexGrow={1}
                            py={2}
                            px={2}
                            sx={{
                              backgroundColor: "#F9FAFB",
                              border: "1px solid var(--Grey-300, #DFE3E8)",
                              borderRadius: "7px",
                            }}
                          >
                            <>
                              {/* partial Box 1 */}
                              <Stack
                                display={"flex"}
                                direction={"row"}
                                spacing={5}
                                maxWidth={"100%"}
                                my={2}
                              >
                                <Stack width={"50%"}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer components={["DatePicker"]}>
                                      <DatePicker
                                        label="Payment Date"
                                        format="DD/MM/YYYY"
                                        disablePast
                                        disabled={
                                          partialPaymentDays.milestoneAmount1
                                        }
                                        // defaultValue={dayjs}
                                        minDate={minDaysValidation}
                                        maxDate={maxDaysValidation}
                                        sx={{
                                          style: {
                                            borderRadius: "8px",
                                          },
                                        }}
                                        // value={
                                        //   CreatePaymentOrder
                                        //     ? dayjs(
                                        //         CreatePaymentOrder.paymentDays
                                        //       )
                                        //     : dayjs("")
                                        // }
                                        onChange={(value: any) =>
                                          handleonChangeForDate1(
                                            moment(
                                              value ? value.$d : ""
                                            ).format("YYYY/MM/DD")
                                          )
                                        }
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            helperText:
                                              CreatePaymentOrder.paymentDays !==
                                              ""
                                                ? `You have selected ${
                                                    CreatePaymentOrder.paymentDays
                                                  } days payment configuration from ${new Date().toDateString()}`
                                                : "Payment Days should between 7 to 180 days.",
                                            sx: {
                                              "& .MuiInputBase-root": {
                                                height: 50,
                                                borderRadius: "8px",
                                              },
                                              "& .MuiFormLabel-root": {
                                                color: "#919EAB",
                                                fontFamily: "Public Sans",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                mt: 0.2,
                                              },
                                            },
                                            style: {
                                              borderRadius: "16px",
                                              borderColor: "#919EAB",
                                              width: "100%",
                                              margin: 0,
                                              // height: 40,
                                            },
                                          },
                                          layout: {
                                            sx: {
                                              ".css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                                {
                                                  color: "white",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#303981",
                                                  border: "1px solid",
                                                  backgroundColor: "#303981",
                                                },
                                              ".css-1beqopj-MuiPickersYear-yearButton.Mui-selected":
                                                {
                                                  color: "white",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#303981",
                                                  border: "1px solid",
                                                  backgroundColor: "#303981",
                                                },
                                              ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                                                {
                                                  color: "#303981",
                                                  borderRadius: 2,
                                                  borderWidth: 1,
                                                  borderColor: "#303981",
                                                  border: "1px solid",
                                                  // backgroundColor: "#303981",
                                                },
                                            },
                                          },
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Stack>
                                <Stack width={"50%"} pt={1}>
                                  <Stack direction={"row"} spacing={3}>
                                    <TextField
                                      label="Amount"
                                      size="medium"
                                      type="number"
                                      name="milestoneAmount1"
                                      fullWidth
                                      error={
                                        parseInt(CreatePaymentOrder.amount) <=
                                        parseInt(
                                          partialPaymentDays.milestoneAmount1
                                        )
                                      }
                                      helperText={
                                        parseInt(
                                          partialPaymentDays.milestoneAmount1
                                        ) > 0
                                          ? numberToEnglish(
                                              parseInt(
                                                partialPaymentDays.milestoneAmount1
                                              )
                                            )
                                              .charAt(0)
                                              .toUpperCase() +
                                            numberToEnglish(
                                              parseInt(
                                                partialPaymentDays.milestoneAmount1
                                              )
                                            ).slice(1)
                                          : parseInt(
                                              CreatePaymentOrder.amount
                                            ) <=
                                            parseInt(
                                              partialPaymentDays.milestoneAmount1
                                            )
                                          ? "Amount should be less then the payment request amount"
                                          : ""
                                      }
                                      className={"hide-spin-buttons"}
                                      value={
                                        partialPaymentDays.milestoneAmount1 > 0
                                          ? partialPaymentDays.milestoneAmount1
                                          : ""
                                      }
                                      disabled={
                                        partialPaymentDays.milestoneAmount2
                                      }
                                      onChange={partialPaymentDaysSelection}
                                      InputProps={{
                                        style: {
                                          borderRadius: "8px",
                                          borderColor: "#919EAB",
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          height: 50,
                                        },
                                        "& .MuiFormLabel-root": {
                                          color: "#919EAB",
                                          fontFamily: "Public Sans",
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          Height: "20px",
                                        },
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </Stack>

                              {/* partial box 2 */}
                              <Stack
                                display={"flex"}
                                direction={"row"}
                                spacing={5}
                                maxWidth={"100%"}
                                my={2}
                              >
                                <Stack width={"50%"}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer components={["DatePicker"]}>
                                      <DatePicker
                                        label="Payment Date"
                                        format="DD/MM/YYYY"
                                        disablePast
                                        disabled={
                                          partialPaymentDays.milestoneAmount2 ||
                                          !partialPaymentDays.milestoneAmount1
                                        }
                                        minDate={minDaysValidation2}
                                        maxDate={maxDaysValidation2}
                                        sx={{
                                          style: {
                                            borderRadius: "8px",
                                          },
                                        }}
                                        // value={
                                        //   CreatePaymentOrder
                                        //     ? dayjs(
                                        //         CreatePaymentOrder.paymentDays
                                        //       )
                                        //     : dayjs("")
                                        // }
                                        onChange={(value: any) =>
                                          handleonChangeForDate2(
                                            moment(
                                              value ? value.$d : ""
                                            ).format("YYYY/MM/DD")
                                          )
                                        }
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            helperText:
                                              CreatePaymentOrder.paymentDays !==
                                              ""
                                                ? `You have selected ${
                                                    CreatePaymentOrder.paymentDays
                                                  } days payment configuration from ${new Date().toDateString()}`
                                                : "Payment Days should between 7 to 180 days.",
                                            sx: {
                                              "& .MuiInputBase-root": {
                                                height: 50,
                                                borderRadius: "8px",
                                              },
                                              "& .MuiFormLabel-root": {
                                                color: "#919EAB",
                                                fontFamily: "Public Sans",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                mt: 0.2,
                                              },
                                            },
                                            style: {
                                              borderRadius: "16px",
                                              borderColor: "#919EAB",
                                              width: "100%",
                                              // marginTop: -8,
                                              margin: 0,
                                              // height: 40,
                                            },
                                          },
                                          layout: {
                                            sx: {
                                              ".css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                                {
                                                  color: "white",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#303981",
                                                  border: "1px solid",
                                                  backgroundColor: "#303981",
                                                },
                                              ".css-1beqopj-MuiPickersYear-yearButton.Mui-selected":
                                                {
                                                  color: "white",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#303981",
                                                  border: "1px solid",
                                                  backgroundColor: "#303981",
                                                },
                                              ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                                                {
                                                  color: "#303981",
                                                  borderRadius: 2,
                                                  borderWidth: 1,
                                                  borderColor: "#303981",
                                                  border: "1px solid",
                                                  // backgroundColor: "#303981",
                                                },
                                            },
                                          },
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Stack>
                                <Stack width={"50%"} pt={1}>
                                  <Stack direction={"row"} spacing={3}>
                                    <TextField
                                      label="Amount"
                                      size="medium"
                                      type="number"
                                      error={
                                        parseInt(CreatePaymentOrder.amount) <=
                                        parseInt(
                                          partialPaymentDays.milestoneAmount1
                                        ) +
                                          parseInt(
                                            partialPaymentDays.milestoneAmount2
                                          )
                                      }
                                      helperText={
                                        parseInt(
                                          partialPaymentDays.milestoneAmount2
                                        ) > 0
                                          ? numberToEnglish(
                                              parseInt(
                                                partialPaymentDays.milestoneAmount2
                                              )
                                            )
                                              .charAt(0)
                                              .toUpperCase() +
                                            numberToEnglish(
                                              parseInt(
                                                partialPaymentDays.milestoneAmount2
                                              )
                                            ).slice(1)
                                          : ""
                                      }
                                      name="milestoneAmount2"
                                      fullWidth
                                      disabled={
                                        partialPaymentDays.milestoneAmount3 ||
                                        parseInt(partialPaymentOption) === 2
                                      }
                                      className={"hide-spin-buttons"}
                                      value={
                                        partialPaymentDays.milestoneAmount2
                                          ? partialPaymentDays.milestoneAmount2
                                          : ""
                                      }
                                      onChange={partialPaymentDaysSelection}
                                      InputProps={{
                                        // inputProps: { min: 7, max: 180 },
                                        style: {
                                          borderRadius: "8px",
                                          borderColor: "#919EAB",
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          height: 50,
                                          // minWidth: "23vw",
                                          // maxWidth: "35vw",
                                        },
                                        "& .MuiFormLabel-root": {
                                          color: "#919EAB",
                                          fontFamily: "Public Sans",
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          Height: "20px",
                                        },
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </Stack>
                            </>

                            {/* partial Box 3 */}
                            {parseInt(partialPaymentOption) === 3 ? (
                              <>
                                <Stack
                                  display={"flex"}
                                  direction={"row"}
                                  spacing={5}
                                  maxWidth={"100%"}
                                  my={2}
                                >
                                  <Stack width={"50%"}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          label="Payment Date"
                                          format="DD/MM/YYYY"
                                          disablePast
                                          disabled={
                                            partialPaymentDays.milestoneAmount3 ||
                                            !partialPaymentDays.milestoneAmount2
                                          }
                                          // defaultValue={dayjs}
                                          minDate={minDaysValidation3}
                                          maxDate={maxDaysValidation3}
                                          sx={{
                                            style: {
                                              borderRadius: "8px",
                                            },
                                          }}
                                          onChange={(value: any) =>
                                            handleonChangeForDate3(
                                              moment(
                                                value ? value.$d : ""
                                              ).format("YYYY/MM/DD")
                                            )
                                          }
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              // onBlur: secondMilestoneAmount,
                                              helperText:
                                                CreatePaymentOrder.paymentDays !==
                                                ""
                                                  ? `You have selected ${
                                                      CreatePaymentOrder.paymentDays
                                                    } days payment configuration from ${new Date().toDateString()}`
                                                  : "Payment Days should between 7 to 180 days.",
                                              sx: {
                                                "& .MuiInputBase-root": {
                                                  height: 50,
                                                  borderRadius: "8px",
                                                },
                                                "& .MuiFormLabel-root": {
                                                  color: "#919EAB",
                                                  fontFamily: "Public Sans",
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                  mt: 0.2,
                                                },
                                              },
                                              style: {
                                                borderRadius: "16px",
                                                borderColor: "#919EAB",
                                                width: "100%",
                                                // marginTop: -8,
                                                margin: 0,
                                                // height: 40,
                                              },
                                            },
                                            layout: {
                                              sx: {
                                                ".css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                                  {
                                                    color: "white",
                                                    borderRadius: 5,
                                                    borderWidth: 1,
                                                    borderColor: "#303981",
                                                    border: "1px solid",
                                                    backgroundColor: "#303981",
                                                  },
                                                ".css-1beqopj-MuiPickersYear-yearButton.Mui-selected":
                                                  {
                                                    color: "white",
                                                    borderRadius: 5,
                                                    borderWidth: 1,
                                                    borderColor: "#303981",
                                                    border: "1px solid",
                                                    backgroundColor: "#303981",
                                                  },
                                                ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                                                  {
                                                    color: "#303981",
                                                    borderRadius: 2,
                                                    borderWidth: 1,
                                                    borderColor: "#303981",
                                                    border: "1px solid",
                                                    // backgroundColor: "#303981",
                                                  },
                                              },
                                            },
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </Stack>
                                  <Stack width={"50%"} pt={1}>
                                    <Stack direction={"row"} spacing={3}>
                                      <TextField
                                        label="Amount"
                                        size="medium"
                                        type="number"
                                        name="milestoneAmount3"
                                        fullWidth
                                        helperText={
                                          parseInt(
                                            partialPaymentDays.milestoneAmount3
                                          ) > 0
                                            ? numberToEnglish(
                                                parseInt(
                                                  partialPaymentDays.milestoneAmount3
                                                )
                                              )
                                                .charAt(0)
                                                .toUpperCase() +
                                              numberToEnglish(
                                                parseInt(
                                                  partialPaymentDays.milestoneAmount3
                                                )
                                              ).slice(1)
                                            : ""
                                        }
                                        className={"hide-spin-buttons"}
                                        disabled
                                        value={
                                          partialPaymentDays.milestoneAmount3
                                            ? partialPaymentDays.milestoneAmount3
                                            : ""
                                        }
                                        InputProps={{
                                          // inputProps: { min: 7, max: 180 },
                                          style: {
                                            borderRadius: "8px",
                                            borderColor: "#919EAB",
                                          },
                                        }}
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            height: 50,
                                            // minWidth: "23vw",
                                            // maxWidth: "35vw",
                                          },
                                          "& .MuiFormLabel-root": {
                                            color: "#919EAB",
                                            fontFamily: "Public Sans",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            Height: "20px",
                                          },
                                        }}
                                      />
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    </>
                  </>
                ) : (
                  ""
                )}

                {PaymentMethod === "full" ? (
                  <>
                    <Box display={"flex"} justifyContent={"start"}>
                      <Stack display={"flex"} direction={"row"} spacing={2}>
                        {parseInt(CreatePaymentOrder.paymentDays) > 0 ? (
                          <LoadingButton
                            loading={loading}
                            size="small"
                            onClick={arrangeArrayForInterestDetails}
                            disabled={continueBtn}
                            sx={{
                              textTransform: "none",
                              maxWidth: "150px",
                              fontSize: "11px",
                              backgroundColor: continueBtn
                                ? "rgba(145, 158, 171, 0.24)"
                                : "#303981",
                              fontFamily: "Public Sans",
                              fontWeight: "400",
                              borderRadius: "8px",
                              color: continueBtn ? "" : "white",
                              "&:hover": {
                                backgroundColor: continueBtn
                                  ? "rgba(145, 158, 171, 0.24)"
                                  : "#303981",
                              },
                              py: 1,
                              px: 4,
                              mt: 1,
                            }}
                          >
                            Continue
                          </LoadingButton>
                        ) : (
                          ""
                        )}

                        {interestDetailsArray.length > 0 && continueBtn ? (
                          <Box>
                            <Stack direction="row" spacing={1} sx={{}}>
                              <>
                                <Alert
                                  sx={{
                                    backgroundColor: "white",
                                    "& .MuiAlert-icon": {
                                      color: "#36B37E",
                                    },
                                  }}
                                  severity="success"
                                >
                                  {" "}
                                  <Typography
                                    fontWeight={600}
                                    fontSize={13}
                                    fontFamily={"Public Sans"}
                                    color={"#36B37E"}
                                  >
                                    {" "}
                                    Details fetched successfully.
                                    {/* <b>
                                      {numberFormat(
                                        parseInt(CreatePaymentOrder.amount)
                                      )}
                                    </b> */}
                                  </Typography>
                                </Alert>
                              </>
                            </Stack>
                          </Box>
                        ) : (
                          ""
                        )}
                        {interestDetailsArray.length <= 0 ? (
                          <>
                            <Box display={"flex"} justifyContent={"start"}>
                              <Stack
                                display={"flex"}
                                direction={"row"}
                                spacing={2}
                              >
                                <LoadingButton
                                  loading={loading}
                                  size="small"
                                  onClick={emptyResetValue}
                                  disabled={continueBtn}
                                  sx={{
                                    textTransform: "none",
                                    maxWidth: "150px",
                                    fontSize: "11px",
                                    backgroundColor: continueBtn
                                      ? "rgba(145, 158, 171, 0.24)"
                                      : "#303981",
                                    fontFamily: "Public Sans",
                                    fontWeight: "400",
                                    borderRadius: "8px",
                                    color: continueBtn ? "" : "white",
                                    "&:hover": {
                                      backgroundColor: continueBtn
                                        ? "rgba(145, 158, 171, 0.24)"
                                        : "#303981",
                                    },
                                    py: 1,
                                    px: 4,
                                    mt: 1,
                                  }}
                                >
                                  Reset
                                </LoadingButton>
                              </Stack>
                            </Box>
                          </>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {PaymentMethod === "partial" ? (
                  <>
                    <Box display={"flex"} justifyContent={"start"}>
                      <Stack display={"flex"} direction={"row"} spacing={2}>
                        {parseInt(partialPaymentDays.milestonePaymentDays1) >
                          0 &&
                        parseInt(partialPaymentDays.milestonePaymentDays2) >
                          0 ? (
                          <LoadingButton
                            loading={loading}
                            size="small"
                            onClick={arrangeArrayForPartialInterestDetails}
                            disabled={partialContinueBtn}
                            sx={{
                              textTransform: "none",
                              maxWidth: "150px",
                              fontSize: "11px",
                              backgroundColor: partialContinueBtn
                                ? "rgba(145, 158, 171, 0.24)"
                                : "#303981",
                              fontFamily: "Public Sans",
                              fontWeight: "400",
                              borderRadius: "8px",
                              color: partialContinueBtn ? "" : "white",
                              "&:hover": {
                                backgroundColor: partialContinueBtn
                                  ? "rgba(145, 158, 171, 0.24)"
                                  : "#303981",
                              },
                              py: 1,
                              px: 4,
                              mt: 1,
                            }}
                          >
                            Continue
                          </LoadingButton>
                        ) : (
                          ""
                        )}

                        {interestDetailsArray.length > 0 &&
                        partialContinueBtn ? (
                          <Box>
                            <Stack direction="row" spacing={1} sx={{ mx: 3 }}>
                              <>
                                <Alert
                                  sx={{
                                    backgroundColor: "white",
                                    "& .MuiAlert-icon": {
                                      color: "#36B37E",
                                    },
                                  }}
                                  severity="success"
                                >
                                  {" "}
                                  <Typography
                                    fontWeight={600}
                                    fontSize={13}
                                    fontFamily={"Public Sans"}
                                    color={"#36B37E"}
                                  >
                                    {" "}
                                    Details fetched successfully.
                                  </Typography>
                                </Alert>
                              </>
                            </Stack>
                          </Box>
                        ) : (
                          ""
                        )}
                        {interestDetailsArray.length <= 0 ? (
                          <>
                            <Box display={"flex"} justifyContent={"start"}>
                              <Stack
                                display={"flex"}
                                direction={"row"}
                                spacing={2}
                              >
                                <LoadingButton
                                  loading={loading}
                                  size="small"
                                  onClick={emptyResetValue}
                                  disabled={partialContinueBtn}
                                  sx={{
                                    textTransform: "none",
                                    maxWidth: "150px",
                                    fontSize: "11px",
                                    backgroundColor: partialContinueBtn
                                      ? "rgba(145, 158, 171, 0.24)"
                                      : "#303981",
                                    fontFamily: "Public Sans",
                                    fontWeight: "400",
                                    borderRadius: "8px",
                                    color: partialContinueBtn ? "" : "white",
                                    "&:hover": {
                                      backgroundColor: partialContinueBtn
                                        ? "rgba(145, 158, 171, 0.24)"
                                        : "#303981",
                                    },
                                    py: 1,
                                    px: 4,
                                    mt: 1,
                                  }}
                                >
                                  Reset
                                </LoadingButton>
                              </Stack>
                            </Box>
                          </>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Stack>
            </Box>

            <Box mx={3}>
              {interestDetailsArray.length > 0 ? (
                <>
                  <PaymentInterestConfig
                    props={interestDetailsArray}
                    platformFeeData={platformFeeData}
                  />

                  <Stack sx={{ py: 2, ml: 2 }}>
                    {" "}
                    <TextField
                      label="Description"
                      type="text"
                      name="description"
                      value={CreatePaymentOrder.description}
                      fullWidth
                      multiline
                      onChange={createOrderForPayment}
                      rows={3}
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          borderColor: "#919EAB",
                        },
                      }}
                      sx={{
                        maxWidth: "50%",
                        "& .MuiInputBase-root": {
                          height: "fit-content",
                          alignItems: "start",
                          // justifyContent:"start"
                        },
                        "& .MuiFormLabel-root": {
                          color: "#919EAB",
                          fontFamily: "Public Sans",
                          fontWeight: "500",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <Stack direction={"row"} py={0.5} spacing={0.5}>
                      <img src={ic_info} height={20} width={20} alt="" />
                      <Typography12
                        fontSize={11}
                        text={
                          "Tell your buyer more about this payment, a brief note about this request."
                        }
                        fontWeight="400"
                      ></Typography12>
                    </Stack>
                  </Stack>
                </>
              ) : (
                ""
              )}
            </Box>
            {interestDetailsArray.length > 0 ? (
              <Box px={8} display={"flex"} justifyContent={"end"}>
                <Stack display={"flex"} direction={"row"} spacing={2}>
                  <Button
                    size="small"
                    onClick={resetOnClickcreatePaymentValue}
                    sx={{
                      color: "#303981",
                      px: 2,
                      borderRadius: "8px",
                      textAlign: "right",
                      fontFamily: "Public Sans",
                      textTransform: "none",
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={confirmPaymentOrder}
                    size="small"
                    disabled={
                      CreatePaymentOrder.description !== "" ? false : true
                    }
                    sx={{
                      color: "white",
                      px: 2,
                      borderRadius: "8px",
                      backgroundColor:
                        CreatePaymentOrder.description !== ""
                          ? "#303981"
                          : "#919EAB",
                      textAlign: "right",
                      fontFamily: "Public Sans",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor:
                          CreatePaymentOrder.description !== ""
                            ? "#303981"
                            : "#919EAB",
                      },
                    }}
                  >
                    <Typography12
                      color={"white"}
                      text={"Send Request"}
                    ></Typography12>
                  </Button>
                </Stack>
              </Box>
            ) : (
              ""
            )}
          </Box>
          <PaymentConfirmDialog
            OpenDailog={openAccept}
            ClosePaymentAcceptDialog={ClosePaymentAcceptDialog}
            VerifyAcceptPaymentLoader={verifybtnLoader}
            dialogBtnDisable={dialogBtnDisable}
            iconColor={"green"}
            ApprovedPaymentConfigure={HandleonClickSendPayment}
            TitleText={"Payment Request Confirmation"}
            ConfirmationText={
              "Are you sure you want to proceed with this payemnt request Confirmation ?"
            }
          />
        </Box>
      </>
    </div>
  );
};

export default CreateNewPaymentSeller;
