// import AdminLoginSvg from "../../assets/illustration_maintenance.svg";
import Grid from "@mui/material/Grid";
import SimpleLoader from "../../components/Loader";
import ResistoreIcon from "../../assets/signup.jpg";
import {
  Container,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import shapeResend from "../../assets/resend.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser, storeToken, userInfo } from "../../redux/slices/user";
import { useDispatch } from "react-redux";
import "../../App.css";
import { LoadingButton } from "@mui/lab";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import { Typography18 } from "../../components/typography/Typography";
import ApiService from "../../api/api";
import { firstCharacterUppercase } from "../../utils/Validations";
import Footer from "../../components/Footer";

export default function TwoStepVerification() {
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  // const [verifyBtnDisabled, setverifyBtnDisabled] = useState(true);
  const [ResendVerifyLoading, setResendVerifyLoading] = useState(false);
  const [open, setopen] = useState(false);
  const { state } = useLocation();
  const { username } = state;
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [ResendOTPBtnDisabled, setResendOTPBtnDisabled] = useState(false);

  const handleOnChangeOTP = (e: any) => {
    setOTP(e.target.value);
    if (e.target.value !== "") {
      // setverifyBtnDisabled(false);
    } else {
      // setverifyBtnDisabled(true);
    }
  };

  // handleFormSubmit
  const handleFormSubmitLogin = async (e: any) => {
    e.preventDefault();
    setopen(true);
    // setverifyBtnDisabled(true);
    let data = new FormData(e.currentTarget);
    let token;
    const responseData: any = await ApiService.post(
      `/auth/verifylogin`,
      {
        username: username,
        otp: data.get("OTP"),
      },
      {},
      true
    );
    if (responseData.status === 200) {
      let getUserLogin = responseData.data;
      setopen(false);
      dispatch(loginUser(true));
      token = getUserLogin.result.token;
      dispatch(storeToken(getUserLogin.result.token));
      dispatch(userInfo(getUserLogin.result.userKyc.photo));
      setResendVerifyLoading(false);
      // sessionStorage.setItem("User", getUserLogin.result);
      // sessionStorage.setItem(
      //   "Token",
      //   JSON.stringify(getUserLogin.result.token)
      // );
      SuccessMsg("Login Successfully !");
    } else {
      ErrorMsg(`${responseData.response.data.message}, Please try again`);
      setResendVerifyLoading(false);
      setopen(false);
    }
    await ApiService.setToken(token);
    const responseData1: any = await ApiService.get(`/auth/profileStatus`);
    if (responseData1.status === 200) {
      console.log(responseData1);
      let ProfileStatus = responseData1.data.result.profileStatus;
      if (ProfileStatus <= 0) {
        navigate("/verify");
      }
      //  PROCEED TO CHECKOUT --- COUPON
      else if (ProfileStatus === 1) {
        navigate("/auth/onboardingpays");
      }
      // GST PROCESS
      else if (ProfileStatus === 2) {
        navigate("/auth/userkyc");
      }
      // UPLOAD GST DOUCMENTS
      else if (ProfileStatus === 3) {
        navigate("/auth/upload-files");
      }
      // VERIFY ADHAAR
      else if (ProfileStatus === 4) {
        navigate("/auth/verifyaadhar");
      }
      // VERIFY PAN
      else if (ProfileStatus === 5) {
        navigate("/auth/verifypan");
      }
      // VERIFY PAN UPLOAD DOCUMENTS
      else if (ProfileStatus === 6) {
        navigate("/auth/panuploadfiles");
      }
      // VERIFY DOCUMENT APPROVAL
      else if (ProfileStatus === 7) {
        navigate("/auth/approval");
      }
      // AFTER SUCCESSFULL FINAL STATUS
      else if (ProfileStatus === 8) {
        navigate("/dashboard/main");
      }
    } else {
      ErrorMsg("Something went Wrong, Please try again");
    }
  };

  const resendOTPTimeSet = async () => {
    setResendVerifyLoading(true);
    setResendOTPBtnDisabled(true);
    setSeconds(30);
    const responseData: any = await ApiService.post(`/auth/resendverifycode`, {
      username: username,
    });
    if (responseData.status === 200) {
      // I will get token from response and set into Redux Store
      setResendVerifyLoading(false);
      SuccessMsg(" OTP resent successfully! ");
    } else {
      console.log("Resend Otp Error -- ", responseData);
      ErrorMsg("Something went wrong, Please try again");
      setResendVerifyLoading(false);
      setResendOTPBtnDisabled(false);
    }
  };
  const resendOTPOnClick = () => {
    setTimeout(() => {
      if (seconds === 0) {
        setResendOTPBtnDisabled(false);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  useEffect(() => {
    resendOTPOnClick();
  }, [seconds]);

  return (
    <>
    <Header />
    <Box margin={2} sx={{ flexGrow: 1 }}>
      <Container>
        <Box
          margin={4}
          justifyContent={"center"}
          sx={{ display: "flex", flexDirection: "column" }}
          alignItems={"center"}
        >
          <Grid container spacing={{ xs: 5, md: 9, lg: 13 }}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  boxShadow: 1,
                  borderRadius: 2,
                  px: 6,
                  pt: 4,
                  pb: 1,
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "340px",
                  mx: "auto", // Center the box horizontally
                }}
              >
                <Stack spacing={0.5} sx={{ mb: 3, position: "relative" }}>
                  <Typography18
                    fontWeight={700}
                    lineHeight={"30px"}
                    color={"#334A67"}
                    text={"Two-step Verification"}
                  />
                  <Typography
                    component="div"
                    fontSize={12}
                    fontWeight={500}
                    fontFamily={"Public Sans"}
                    color={"#556486"}
                    letterSpacing={0.2}
                  >
                    Please enter your code for Two-step verification to access
                    <br /> your account with username{" "}
                    <b style={{ fontSize: 13 }}>
                      {username && username
                        ? firstCharacterUppercase(username)
                        : ""}
                    </b>
                  </Typography>
  
                  <Box
                    component="form"
                    onSubmit={handleFormSubmitLogin}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      className={"hide-spin-buttons"}
                      helperText={"Enter a six-digit numeric OTP"}
                      autoFocus
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: "#919EAB",
                          fontFamily: "Public Sans",
                          fontWeight: "500",
                          fontSize: 14,
                        },
                        "& .MuiFormHelperText-root": {
                          fontFamily: "Public Sans",
                          fontWeight: 500,
                        },
                      }}
                      fullWidth
                      name="OTP"
                      label="Enter Your OTP"
                      id="OTP"
                      value={OTP && OTP.replace(/\D/g, "")}
                      onChange={handleOnChangeOTP}
                      type="text"
                      size="small"
                      InputProps={{
                        inputProps: {
                          maxLength: 6,
                          inputMode: "numeric",
                          pattern: "[0-9]*", // Only allows numbers
                        },
                        style: {
                          borderRadius: "8px",
                          height: "40px",
                          borderColor: "#919EAB",
                        },
                      }}
                    />
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"center"}
                      spacing={3}
                      sx={{ mt: 2, mb: 2.5 }}
                    >
                      <LoadingButton
                        loading={ResendVerifyLoading}
                        onClick={resendOTPTimeSet}
                        disabled={ResendOTPBtnDisabled}
                        style={{
                          color: ResendOTPBtnDisabled ? "grey" : "green",
                          textTransform: "none",
                          background: ResendOTPBtnDisabled
                            ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                            : "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))",
                          borderRadius: "8px",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "155px",
                          fontWeight: "600",
                          fontFamily: "Public Sans",
                        }}
                        size="small"
                      >
                        {!ResendOTPBtnDisabled ? (
                          <img
                            src={shapeResend}
                            style={{
                              marginRight: 2,
                              color: "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                            }}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                        Resend Code
                      </LoadingButton>
                      <Button
                        disabled={
                          OTP && OTP.trim().replace(/\D/g, "").length === 6
                            ? false
                            : true
                        }
                        style={{
                          fontWeight: 600,
                          color:
                            OTP.replace(/\D/g, "").trim().length !== 6
                              ? "grey"
                              : "white",
                          textTransform: "none",
                          background:
                            OTP.replace(/\D/g, "").trim().length !== 6
                              ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                              : "#303981",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "4px",
                          minWidth: "155px",
                          fontFamily: "Public Sans",
                        }}
                        size="small"
                        type="submit"
                      >
                        Verify
                      </Button>
                    </Stack>
                    {seconds > 0 ? (
                      <Typography
                        component="div"
                        fontSize={13}
                        fontWeight={500}
                        fontFamily={"Public Sans"}
                        color={"#556486"}
                        sx={{ mt: 2 }}
                      >
                        Please wait <b style={{ fontSize: 14 }}>{seconds}</b>{" "}
                        seconds for Resend OTP
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
                <SimpleLoader props={open} />
              </Box>
            </Grid>
            <Grid item xs={0} md={6} sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center" }}>
              <Stack
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src={ResistoreIcon}
                  style={{
                    margin: "60px 0 0 0",
                    maxHeight: "380px",
                    maxWidth: "380px",
                  }}
                  alt=""
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
    <Footer />
  </>
  
  );
}
