import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Ap_Balance from "./Items/Ap_Balance";
import Ap_Receivable from "./Items/Ap_Receivable";
import Ap_Payables from "./Items/Ap_Payables";
import Ap_PreClaim from "./Items/Ap_PreClaim";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  borderRadius: "18px",
  color: theme.palette.text.secondary,
  gap: 2,
}));

// Balances on Dashboard -----
const BalanceItem = (props: any) => {
  return (
    <Box sx={{ px: { xs: 0, sm: 4 } }}>
      <Typography
        fontFamily={"Public Sans"}
        fontSize={"22px"}
        fontWeight={700}
        fontStyle={"normal"}
        color={"#212B36"}
      >
        Balances
      </Typography>
      <>
        <Grid container spacing={3} sx={{ display: 'flex', flexWrap: 'wrap',width:"100%" }}>
          {props.open ? (
            <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex'}}>
              <Item
                sx={{
                  backgroundColor: "#36A979",
                  borderRadius: "16px",
                  flexGrow:1,
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  py: 10,
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <CircularProgress
                  color="inherit"
                  sx={{ color: "#fff" }}
                  size={30}
                />
              </Item>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={6}>
              <Item sx={{ backgroundColor: "#36A979", boxShadow: "none" ,height:"100%"}}>
                <Grid container alignItems="center" py={1}>
                  {/* AssuredPay Balance */}
                  <Ap_Balance Av_Balance={props.BalanceValues} />

                  {/* AssuredPay Receivables */}
                  <Ap_Receivable Av_Receivable={props.BalanceValues} />
                </Grid>
              </Item>
            </Grid>
          )}
          {props.open ? (
            <Grid item xs={12} sm={12} md={6}>
              <Item
                sx={{
                  backgroundColor: "#FB9E37",
                  borderRadius: "16px",
                  textAlign: "center",
                  justifyContent: "center",
                  flexGrow:1,
                  display: "flex",
                  py: 10,
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <CircularProgress
                  sx={{ color: "#fff" }}
                  size={30}
                  color="inherit"
                />
              </Item>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={6}>
              <Item sx={{ backgroundColor: "#FB9E37", boxShadow: "none",height:"100%" }}>
                <Grid container alignItems="center" py={1}>
                  {/* AssuredPay Payables */}
                  <Ap_Payables Av_Payables={props.BalanceValues} />

                  {/* AssuredPay Pre-Claimed */}
                  <Ap_PreClaim Av_PreClaim={props.BalanceValues} />
                </Grid>
              </Item>
            </Grid>
          )}
        </Grid>
      </>
    </Box>
  );
};

export default BalanceItem;
