// import "./styles.css";
import React, { useState,useEffect } from "react";
import {
  Stack,
  Box,
  ListItem,
  List,
  IconButton,
  Avatar,
  Typography,
  Collapse,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import APLogo from "../assets/Assure_Logo.svg";
import newDashboardIcon from "../assets/ic_dashboard.svg";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Logo from "../assets/LogoSvg.svg";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PaymentIcon from "@mui/icons-material/Payment";
import PreClaimsIcon from "../assets/ic_ecommerce.svg";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Typography14 } from "../components/typography/Typography";
import { useSelector } from "react-redux";
import { firstCharacterUppercase, formatedData } from "../utils/Validations";
const { version, lastUpdated } = require("../../package.json");

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  // overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Sidebars() {
  const [open, setOpen] = useState(true);
  const [orderMenu, setorderMenu] = useState(false);

  const userPic = useSelector((state: any) =>
    state.user ? state.user.receivableState : ""
  );
  const userSaveData = useSelector((state: any) =>
    state.user.userSave.username ? state.user.userSave : ""
  );
  const handleDrawerClose = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setOpen(!event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    if (mediaQuery.matches) {
      setOpen(false);
    }

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  const UserProfilePhoto = localStorage.getItem("photo");

  function VersionDate() {
    return (
      <Box
        sx={{
          position: "relative",
          padding: 1.5,
          backgroundColor: "#fff",
          borderTop: "1px solid #ddd",
          textAlign: "center",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "auto", // Set marginTop to auto to push the box to the bottom
        }}
        // sx={{
        //   position: "relative", // Change position to fixed
        //   bottom: 0,
        //   left: 0,
        //   right: 0,
        //   top: "100%",
        //   padding: 2,
        //   backgroundColor: "#fff",
        //   borderTop: "1px solid #ddd",
        //   textAlign: "center",
        //   zIndex: 1, // Ensure it stays above the scrolling content
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "start", // Center align vertically
        //   justifyContent: "start", // Center align horizontally
        //   // position: "relative",
        //   // bottom: 0,
        //   // left: 0,
        //   // right: 0,
        //   // padding: 2,
        //   // backgroundColor: "#fff",
        //   // borderTop: "1px solid #ddd",
        //   // textAlign: "center",
        //   // zIndex: 1, // Ensure it stays above the scrolling content
        //   // display: "flex",
        //   // flexDirection: "column",
        //   // alignItems: "center", // Center align vertically
        //   // justifyContent: "center", // Center align horizontally
        // }}
      >
        <Stack display={"flex"} direction={"row"} spacing={0.5}>
          <Typography
            fontWeight={600}
            color={"var(--text-light-secondary, #637381)"}
            fontFamily={"Public Sans"}
            fontSize={14}
          >
            Version -
          </Typography>
          <Typography
            fontWeight={700}
            color={"var(--text-light-secondary, #637381)"}
            fontFamily={"Public Sans"}
            fontSize={14}
          >
            {version || "0.0.0.0"}
          </Typography>
        </Stack>

        <Typography
          fontWeight={600}
          color={"var(--text-light-secondary, #637381)"}
          fontFamily={"Public Sans"}
          fontSize={11}
        >
          {(lastUpdated && formatedData(lastUpdated)) || ""}
        </Typography>
      </Box>
    );
  }
  return (
    <Drawer
      variant="permanent"
      open={open}
      anchor="left"
      sx={{ overflow: "visible", position: "relative" }}
    >
      <Box sx={{ position: "relative" }}>
        <Box sx={{ backgroundColor: "white"
        }}>
          <DrawerHeader onClick={handleDrawerClose} sx={{padding:{md:"0",justifyContent:"center"}}}>
            {!open ? (
              <img src={APLogo} alt="AP Logo" />
            ) : (
              <img src={Logo} alt="Logo" />
            )}
          </DrawerHeader>
        </Box>

        {/* <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "-10%",
            transform: "translateY(-50%)",
            overflow: "visible",
          }}
        >
          <IconButton
            sx={{
              bgcolor: "primary.light",
              "&:hover": { bgcolor: "primary.light" },
            }}
            onClick={handleDrawerClose}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box> */}
      </Box>

      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <Box px={1}>
            <Box
              sx={{
                borderRadius: "12px",
                px: 0.5,
                py: 3,
                maxWidth: "240px",
                backgroundColor: "rgba(145, 158, 171, 0.08)",
              }}
            >
              {/* Avatar and Username */}
              <Stack direction={"row"} display={"flex"} spacing={2}>
                <Avatar src={userPic?.toString()} alt="" />
                <ListItemText
                  primary={
                    <Stack>
                      <Typography14
                        sx={{ mt: 1 }}
                        text={firstCharacterUppercase(userSaveData.username)}
                      ></Typography14>
                    </Stack>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </Stack>
            </Box>
          </Box>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <Link
            to={"/dashboard/main"}
            style={{
              textDecoration: "none",
            }}
          >
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    height={"25px"}
                    width={"30px"}
                    src={newDashboardIcon}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography14 text={"Dashboard"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            to={"/dashboard/addbusinessnetwork"}
            style={{
              textDecoration: "none",
            }}
          >
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AddBusinessIcon sx={{ height: "25px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14 text={"Business Network"}></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            to={"/dashboard/orders"}
            style={{
              textDecoration: "none",
            }}
          >
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PaymentIcon sx={{ height: "25px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography14 text={"My Orders"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            to={"/dashboard/users"}
            style={{
              textDecoration: "none",
            }}
          >
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PaymentIcon sx={{ height: "25px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography14 text={"My Users"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            to={"/dashboard/reports"}
            style={{
              textDecoration: "none",
            }}
          >
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PaymentIcon sx={{ height: "25px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography14 text={"Reports"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        </ListItem>
      </List>
      {open ? <VersionDate /> : ""}
    </Drawer>
  );
}

export default Sidebars;
