import React from "react";
import OrderDetails from "./OrderDetails";
import BuisnessDetails from "./BuisnessDetails";
import InterestDetailsData from "../Reusable/InterestDetailsData";
import { Box } from "@mui/system";
import InterestTableList from "../ViewOrders/InProcess/InterestTableList";
import ReceivableTableList from "../CreatePaymentSeller/ReceivableTableList";

const View = (props: any) => {
  const orderDetails = props ? props.orderDetails : {};
  return (
    <>
      {/*  Order Details*/}
      <OrderDetails props={orderDetails} />
      {/* Business Details */}
      <BuisnessDetails props={orderDetails} />
      {/* Blocked or Used Bank Balance AND Receivables Balance */}

      {/* Table list details of all milestones And their Interest Details */}
      <Box mx={2}>
        <ReceivableTableList props={orderDetails} />
      </Box>
    </>
  );
};

export default View;
