import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../../api/api";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
import SimpleLoader from "../../../components/Loader";
import OrderStack from "../Reusable/OrderStack";
import PaymentConfirmIcon from "../../../assets/icons8-complete.gif";
import CancleOrderIcon from "../../../assets/CancleOrder.svg";
import View from "./view";
import PaymentActionButton from "../Reusable/PaymentActionButton";
import CancleOrderSeller from "../ViewOrders/Canceled/CancleOrderSeller";
import CompleteOrderSeller from "../ViewOrders/Completed/CompleteOrderSeller";
import ExpiredPaymentRequestSeller from "../ViewOrders/Expired/ExpiredPaymentRequestSeller";
import GeneratePaymentRequestSeller from "../ViewOrders/InProcess/Seller/GeneratePaymentRequestSeller";
import RejectedPaymentRequestSeller from "../ViewOrders/Rejected/RejectedPaymentRequestSeller";

const Main = () => {
  const newToken = useSelector((state: any) => state.user.Token);
  const location = useLocation();
  const orderId = location.state ? location.state.orderDetails : {};
  console.log(orderId, "-------------------orderId, Main.............");
  const [open, setopen] = useState(false);
  const [orderDetails, setorderDetails] = useState<any>({});
  document.title = `Payment order : ${
    orderDetails && orderDetails.order && orderDetails.order.status
  }`;
  const getOrderDetails = async () => {
    setopen(true);
    console.log("seller id details", orderId);
    let id = orderId ? orderId._id : "";
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/checkerRoutes/request/payment/created/${id}`
    );
    if (responseData.status === 200) {
      let referenceData = responseData.data.data;
      let array = [];
      for (let i in referenceData.milestones) {
        array.push(referenceData.milestones[i]);
      }
      referenceData.milestones = array.flat(1);
      setorderDetails(referenceData);
      setopen(false);
    } else {
      ErrorMsg("Something went wrong,Please try again !");
      setopen(false);
    }
  };
  useEffect(() => {
    getOrderDetails();
  }, []);
  // const ButtonTextOne = props ? props.ButtonTextOne : "";
  // const ButtonTextTwo = props ? props.ButtonTextTwo : "";
  // const ButtonTextThree = props ? props.ButtonTextThree : "";
  // const ButtonColor1 = props ? props.ButtonColor1 : "";
  // const ButtonColor2 = props ? props.ButtonColor2 : "";
  // const ButtonColor3 = props ? props.ButtonColor3 : "";
  // const Button2TrueFalse = props ? props.Button2TrueFalse : false;
  // const Button1TrueFalse = props ? props.Button1TrueFalse : false;
  // const Button3TrueFalse = props ? props.Button3TrueFalse : false;
  return (
    <Box>
      {open ? (
        <SimpleLoader props={open} />
      ) : (
        <>
          <>
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "approved by checker" ? (
              <Box pb={2}>
                <GeneratePaymentRequestSeller orderDetails={orderDetails} />
              </Box>
            ) : (
              ""
            )}
          </>
          <>
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "cancelled" ? (
              <>
                <Box pb={2}>
                  <OrderStack
                    props={orderDetails}
                    OrderText={"Business Order"}
                    titleIconColor={"#B71D18"}
                    confirmationTitle={"Cancelled Payment"}
                    confirmationSubtitle={`${
                      orderDetails &&
                      orderDetails.target &&
                      orderDetails.target.Legal_Name_of_Business
                    } is assured with this payment`}
                  />
                  <CancleOrderSeller orderDetails={orderDetails} />
                </Box>
              </>
            ) : (
              ""
            )}
          </>
          <>
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "active" ? (
              <Box pb={2}>
                <OrderStack
                  props={orderDetails}
                  OrderText={"Business Order"}
                  // titleIcon={CancleOrderIcon}
                  titleIconColor={"#B71D18"}
                  confirmationTitle={"Cancelled Payment"}
                  confirmationSubtitle={`${
                    orderDetails &&
                    orderDetails.target &&
                    orderDetails.target.Legal_Name_of_Business
                  } is assured with this payment`}
                />
                <View orderDetails={orderDetails} />
                <PaymentActionButton />
              </Box>
            ) : (
              ""
            )}
          </>
          <>
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "rejected" ? (
              <Box pb={2}>
                <RejectedPaymentRequestSeller orderDetails={orderDetails} />
              </Box>
            ) : (
              ""
            )}
          </>
          <>
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "expired" ? (
              <Box pb={2}>
                <ExpiredPaymentRequestSeller orderDetails={orderDetails} />
              </Box>
            ) : (
              ""
            )}
          </>
          <>
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "completed" ? (
              <Box pb={2}>
                <CompleteOrderSeller orderDetails={orderDetails} />
              </Box>
            ) : (
              ""
            )}
          </>
        </>
      )}
    </Box>
  );
};

export default Main;
