import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ReceiptIcon from "@mui/icons-material/Receipt";

const AlertDialog = (props: any) => {
  const OpenDailog = props ? props.OpenDailog : false;
  const TitleText = props ? props.TitleText : "";
  const Subtitle = props ? props.Subtitle : "";
  const iconColor = props ? props.iconColor : "#919EAB";
  const TitleIcon = (props && props.TitleIcon) || (
    <ReceiptIcon sx={{ color: iconColor }} />
  );
  const ButtonText1 = props && props.ButtonText1;
  const ButtonText2 = props && props.ButtonText2;

  return (
    <Box borderRadius={"12px"}>
      <Dialog
        open={OpenDailog}
        sx={{
          ".MuiDialog-paper": {
            backgroundColor: props.dialog_Background_color,
            borderRadius: "16px", // Adjust the value as needed for your design
          },
        }}
      >
        <DialogTitle sx={{ px: 4, maxWidth: "500px", my: 1 }}>
          <Stack display={"flex"} direction={"row"} spacing={2}>
            {TitleIcon}
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"20px"}
              fontWeight={700}
              lineHeight={"28px"}
              color={props.textColor ? props.textColor : "#212B36"}
            >
              {TitleText}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ px: 4, maxWidth: "430px" }}>
          <DialogContentText
            color={props.textColor ? props.textColor : "#454F5B"}
            textAlign={"start"}
            justifyContent={"center"}
            alignSelf={"stretch"}
            fontFamily={"Public Sans"}
            fontSize={"14px"}
            fontWeight={600}
          >
            {Subtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "end",
            textAlign: "center",
            px: 8,
            mb: 2,
          }}
        >
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <LoadingButton
              size="small"
              loading={props.VerifyAcceptPaymentLoader}
              sx={{
                ml: 0.5,
                px: 3,
                fontFamily: "Public Sans",
                color: "white",
                width: "auto",
                fontSize: "13px",
                backgroundColor: `${props.textColor}`,
                textTransform: "none",
                borderRadius: "8px",
                fontWeight: "600",
                ":hover": {
                  backgroundColor: `${props.textColor}`,
                },
              }}
              onClick={props.redirectTo}
            >
              {ButtonText1}
            </LoadingButton>
            <Button
              size="small"
              sx={{
                ml: 0.5,
                px: 2,
                py: 0.6,
                fontFamily: "Public Sans",
                color: props.textColor ? props.textColor : "#Black",
                width: "auto",
                backgroundColor: props.dialog_Background_color || "#303981",
                border: `1px solid ${props.textColor}`,
                fontSize: "13px",
                textTransform: "none",
                borderRadius: "8px",
                fontWeight: "600",
              }}
              onClick={() => props.setOpen(!OpenDailog)}
            >
              {ButtonText2}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AlertDialog;
