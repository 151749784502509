import { Box, Stack } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Typography12,
  Typography14,
  Typography16,
} from "../../../../components/typography/Typography";
import {
  numberFormat,
  splitDateConversion,
} from "../../../../utils/Validations";
import { fontFamilys, fontSizes } from "../../../../utils/FontSize";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { enqueueSnackbar } from "notistack";
// import { numberFormat } from "../../utils/Validations";

const InterestTableList = (props: any) => {
  const { copy } = useCopyToClipboard();
  const orderDetails = props ? props.props : {};

  const TableText = props ? props.TableText : "";
  // const TotalInterest = () => {
  //   let totalInterstAmount = 0;
  //   if (orderDetails && orderDetails.milestones && orderDetails.milestones) {
  //     for (const milestoneKey in orderDetails.milestones) {
  //       if (orderDetails.milestones.hasOwnProperty(milestoneKey)) {
  //         const milestonesArray = orderDetails.milestones[milestoneKey];
  //         for (const milestone of milestonesArray) {
  //           totalInterstAmount += milestone.amount;
  //         }
  //       }
  //     }
  //   }
  //   return totalInterstAmount;
  // };
  const TotalInterest = () => {
    let totalInterstAmount = 0;
    for (let i in orderDetails &&
      orderDetails.milestones &&
      orderDetails.milestones) {
      totalInterstAmount += orderDetails.milestones[i].Approx_Interest;
    }
    return totalInterstAmount;
  };
  const onCopy = (text: string) => {
    if (text) {
      enqueueSnackbar("Copied!");
      copy(text);
    }
  };
  return (
    <>
      <Box display={"flex"} mb={0.5}>
        <Stack mx={5}>
          <Typography16
            color="#212B36"
            fontWeight={700}
            text={TableText}
          ></Typography16>
        </Stack>
      </Box>
      <Box
        mx={5}
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
        }
        borderRadius={"16px"}
        sx={{
          backgroundColor: "rgba(48, 57, 129, 1)",
        }}
      >
        <Table>
          <TableHead
            sx={{
              borderRadius: "10px",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "none",
                  px: 4,
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Payment
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Amount Type
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Amount
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Request Payment Date
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  FD Status
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  FD Ref No
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  FD Days
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Approx Interest
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {orderDetails &&
              orderDetails.milestones &&
              orderDetails.milestones.map((item: any, index: any) => {
                return (
                  <>
                    <TableRow
                      sx={{
                        boxShadow:
                          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                        borderRadius: "16px",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          px: 4,
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          color={"#212B36"}
                          fontFamily={fontFamilys.publicSans}
                          fontWeight={500}
                        >
                          {item.Payment}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          color={"#212B36"}
                          fontFamily={fontFamilys.publicSans}
                          fontWeight={500}
                        >
                          {item.Amount_Type}
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          color={"green"}
                          fontFamily={fontFamilys.publicSans}
                          fontWeight={500}
                        >
                          {numberFormat(item.Amount || 0)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          color={"#212B36"}
                          fontWeight={500}
                          fontFamily={fontFamilys.publicSans}
                        >
                          {splitDateConversion(item.Payment_Date)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          color={item.isFDAllowed ? "green" : "red"}
                          fontWeight={500}
                          fontFamily={fontFamilys.publicSans}
                        >
                          {item.FD_Status}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          color={"#212B36"}
                          fontWeight={500}
                          fontFamily={fontFamilys.publicSans}
                        >
                          {item.FD_Ref_No}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          color={"#212B36"}
                          fontWeight={500}
                          fontFamily={fontFamilys.publicSans}
                        >
                          {item.FD_Days}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={fontSizes.sm}
                          fontWeight={500}
                          color={"rgba(0, 123, 85, 1)"}
                          fontFamily={fontFamilys.publicSans}
                        >
                          {item.Approx_Interest > 0
                            ? item.Approx_Interest.toFixed(2)
                            : 0}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          py: 0.5,
          px: 5,
        }}
      >
        <Stack display={"flex"} direction={"row"} spacing={"auto"}>
          <Box
            sx={{
              maxWidth: "700px",
            }}
          >
            <Typography14 color={"black"} text="Disclaimer"></Typography14>
            <Typography12
              fontSize={11}
              fontWeight={500}
              lineHeight={"12px"}
              text="The displayed Fixed Deposit interest rates are current as of this moment and are subject to change. Rates are updated every 15 seconds to provide you with the most accurate information. Please note that the actual interest rates applicable to your Fixed Deposit may differ at the time of confirmation. We recommend verifying the rates before finalizing any transactions or investments."
            ></Typography12>
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Stack display={"flex"} justifyContent={"end"} textAlign={"end"}>
              <Typography14
                color="#007B55"
                text="Total Interest"
              ></Typography14>
              <Typography16
                color="#007B55"
                text={TotalInterest() > 0 ? TotalInterest().toFixed(2) : 0}
              ></Typography16>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default InterestTableList;
