import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import PaymentConfirmIcon from "../../../assets/icons8-complete.gif";

import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import OrderStack from "../Reusable/OrderStack";
import PaymentConfirmDialog from "../Reusable/PaymentConfirmDialog";
import PaymentRejectDialog from "../Reusable/PaymentRejectDialog";
import PaymentActionButton from "../Reusable/PaymentActionButton";
import AcceptPaymentInterestTable from "./AcceptOrderInterestTable";
import { Typography16 } from "../../../components/typography/Typography";
import { Stack } from "@mui/system";
import {
  formatString,
  numberFormat,
  removeSubstring,
} from "../../../utils/Validations";
import SimpleLoader from "../../../components/Loader";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";
import { SuccessMsg } from "../../../components/Messages/SuccessMsg";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import ApiService from "../../../api/api";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";

const ApprovePayment = () => {
  const PaymentAcceptType = "ApprovePayment";

  const [open, setopen] = useState(false);
  const newToken = useSelector((state: any) => state.user.Token);
  const navigate = useNavigate();
  const location = useLocation();
  const [orderDetails, setorderDetails] = useState<any>({});
  const [openAccept, setOpenAccept] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [RemarksValue, setRemarksValue] = useState("");

  const paymentOrder = location.state ? location.state.orderDetails : "";
  const [VerifyRejectPaymentLoader, setVerifyRejectPaymentLoader] =
    useState(false);
  const [VerifyAcceptPaymentLoader, setVerifyAcceptPaymentLoader] =
    useState(false);
  const [acceptBtnDisable, setacceptBtnDisable] = useState(false);
  const [RejectpaymentConfirmation, setRejectpaymentConfirmation] =
    useState(true);
  const rejectRemarkValue = (e: any) => {
    setRemarksValue(e.target.value);
    if (e.target.value !== "") {
      setRejectpaymentConfirmation(false);
    } else {
      setRejectpaymentConfirmation(true);
    }
  };
  useEffect(() => {
    getorderDetails();
  }, [paymentOrder]);
  const getorderDetails = async () => {
    setopen(true);
    const id = paymentOrder._id;
    await ApiService.setToken(newToken);
    const dashboardData: any = await ApiService.get(
      `/checkerRoutes/order/${id}`
    );

    if (dashboardData.status === 200) {
      let data: any = dashboardData.data.data;
      let array = [];
      for (let i in data.milestones) {
        array.push(data.milestones[i]);
      }
      data.milestones = array.flat(1);
      setorderDetails(data);
      setopen(false);
    } else {
      console.log(dashboardData);
      setopen(false);
    }
  };

  const openAcceptPayment = () => {
    setOpenAccept(true);
    setOpenReject(false);
  };
  const ApprovedPaymentConfigure = async () => {
    setacceptBtnDisable(true);
    setVerifyAcceptPaymentLoader(true);
    let body = {
      action: "active",
      docId: orderDetails.order ? orderDetails.order._id : "",
    };
    await ApiService.setToken(newToken);
    const dashboardData: any = await ApiService.post(
      `/checkerRoutes/businessactiononpaymentrequest`,
      body
    );

    if (dashboardData.status === 200) {
      enqueueSnackbar("Payment Request created Successfully", {
        variant: "success",
        style: {
          color: " var(--success-darker, #0A5554)",
          backgroundColor: "var(--success-lighter, #D8FBDE)",
          marginRight: "100px",
          fontFamily: "Public Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "22px",
          maxWidth: "400px",
          margin: "50px 0 0 0",
        },
      });

      setVerifyAcceptPaymentLoader(false);
      setOpenAccept(false);
      setacceptBtnDisable(false);
      navigate("/dashboard/main");
    } else {
      console.log(dashboardData);
      enqueueSnackbar("Something went wrong, Please Try Again", {
        variant: "error",
      });
      setVerifyAcceptPaymentLoader(false);
    }
  };
  const openRejectPayment = () => {
    setOpenReject(true);
    setOpenAccept(false);
  };
  const RejectPaymentConfigure = async () => {
    setVerifyRejectPaymentLoader(true);
    let body = {
      action: "rejected",
      docId: orderDetails.order ? orderDetails.order._id : "",
      remark: RemarksValue,
      // remark: "Good To work with you",
    };
    await ApiService.setToken(newToken);
    const dashboardData: any = await ApiService.post(
      `/checkerRoutes/businessactiononpaymentrequest`,
      body
    );
    if (dashboardData.status === 200) {
      SuccessMsg("Payment Request Rejected Successfully");
      navigate("/dashboard/main");
      setVerifyRejectPaymentLoader(false);
      setOpenAccept(false);
    } else {
      console.log("Error-", dashboardData);
      ErrorMsg("Something went wrong, Please Try Again");
      setVerifyRejectPaymentLoader(false);
    }
  };
  const ClosePaymentAcceptDialog = () => {
    setOpenAccept(false);
  };
  const CloseRejectPayment = () => {
    setOpenReject(false);
  };

  const orderDetailsBox = (label: string, value: any) => {
    return (
      <Box>
        <Typography
          fontSize={fontSizes.xsm}
          fontFamily={fontFamilys.publicSans}
          color="#919EAB"
        >
          {label}
        </Typography>
        <Typography
          color={label === "Amount" ? "green" : "#212B36"}
          fontWeight={label === "Amount" ? 700 : 500}
          sx={{ maxWidth: "400px" }}
          fontSize={fontSizes.sm}
          fontFamily={fontFamilys.publicSans}
        >
          {value ? value : "N/A"}
        </Typography>
      </Box>
    );
  };
  const { copy } = useCopyToClipboard();
  const onCopy = (text: string) => {
    if (text) {
      SuccessMsg("Copied Successfully!");
      copy(text);
    }
  };
  const RedirectToSellerChat = () => {
    navigate("/dashboard/chat", { state: { orderDetails: orderDetails } });
  };
  return (
    <>
      {open ? (
        <SimpleLoader props={open} />
      ) : (
        <>
          <OrderStack
            props={orderDetails}
            OrderText={"Business Order"}
            titleIcon={PaymentConfirmIcon}
            titleIconColor={"#36B37E"}
            confirmationTitle={"Accept Payment"}
            confirmationSubtitle={`${
              orderDetails &&
              orderDetails.target &&
              orderDetails.target.Legal_Name_of_Business
            } is make this payment`}
          />

          {/*  Api Seller details needed from api */}
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              backgroundColor: "#F7F9FB",
            }}
            // pb={2}
          >
            <Box
              boxShadow={0.5}
              mx={4}
              sx={{
                borderRadius: "16px",
                backgroundColor: "white",
                border: "1px solid #EEF2FA",
                boxShadow:
                  "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              }}
              // component={"form"}
            >
              {/*  Payment Order Details & Payment Seller Details */}
              <Box px={5} mt={1.5}>
                <Stack direction={"row"} spacing={1}>
                  <Box pt={0.5}>
                    <Typography
                      fontWeight={700}
                      color={"#212B36"}
                      fontFamily={fontFamilys.publicSans}
                    >
                      Order Details :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={"#F58336"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={700}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.orderID}
                      <Tooltip title="Copy" placement="top">
                        <IconButton
                          onClick={() =>
                            onCopy(
                              orderDetails &&
                                orderDetails.order &&
                                orderDetails.order.orderID
                            )
                          }
                        >
                          <Icon icon="eva:copy-fill" width={20} />
                        </IconButton>
                      </Tooltip>
                    </Typography>{" "}
                  </Box>
                </Stack>
              </Box>
              <Box
                px={8}
                mx={5}
                my={0.5}
                sx={{
                  backgroundColor: "var(--grey-100, #F9FAFB)",
                  border: "1px solid var(--grey-300, #DFE3E8)",
                  borderRadius: "7px",
                }}
              >
                <Stack my={1.5} direction={"row"} spacing={"auto"}>
                  {orderDetailsBox(
                    "Order Title",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.title
                  )}
                  {orderDetailsBox(
                    "Payment Type",
                    orderDetails &&
                      orderDetails.order &&
                      formatString(orderDetails.order.orderType)
                  )}
                  {orderDetailsBox(
                    "Payment Method",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.paymentType === "full"
                      ? "Full Payment"
                      : "Partial Payment"
                  )}
                  <Tooltip
                    title={
                      orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.remark
                    }
                  >
                    {orderDetailsBox(
                      "Description",
                      orderDetails &&
                        orderDetails.order &&
                        removeSubstring(orderDetails.order.remark)
                    )}
                  </Tooltip>
                  {orderDetailsBox(
                    "Amount",
                    orderDetails &&
                      orderDetails.order &&
                      numberFormat(orderDetails.order.orderAmount)
                  )}
                  {orderDetailsBox(
                    "Agreed Days",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.paymentDays
                  )}
                  {/* {orderDetailsBox(
                    "Proposal Validity",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.proposalExpireDate
                  )} */}
                </Stack>
              </Box>
              <Box px={5} mt={1.5} mb={0.5}>
                <Typography
                  fontFamily={fontFamilys.publicSans}
                  fontWeight={700}
                  color={"#212B36"}
                >
                  {orderDetails &&
                    orderDetails.target &&
                    orderDetails.target.targetType}{" "}
                  Details
                </Typography>
              </Box>
              <Box
                px={8}
                mx={5}
                // my={1}
                pt={1}
                sx={{
                  backgroundColor: " var(--grey-100, #F9FAFB)",
                  border: "1px solid var(--grey-300, #DFE3E8)",
                  borderRadius: "7px",
                  maxHeight: "160px",
                }}
              >
                <Box maxWidth={"400px"} mt={1.2}>
                  <Typography16
                    fontWeight={700}
                    // color="#303981"
                    color="#212B36"
                    text={
                      orderDetails.target
                        ? orderDetails.target.Legal_Name_of_Business
                        : "N/A"
                    }
                  ></Typography16>
                </Box>
                <Box sx={{ px: 2, py: 2 }}>
                  <Grid
                    container
                    sx={
                      {
                        // my: 2,
                      }
                    }
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <Avatar
                          src={
                            orderDetails &&
                            orderDetails.target &&
                            orderDetails.target.aadharPhotoLink
                          }
                        />
                        {orderDetailsBox(
                          " Authorised Business Representative",
                          orderDetails && orderDetails.target
                            ? orderDetails.target.nameInAadhaar
                            : "N/A"
                        )}
                      </Stack>

                      <Box mt={2}>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Rating
                        </Typography>
                        <Rating
                          name="simple-controlled"
                          value={4}
                          size="small"
                          // onChange={(event, newValue) => {
                          //   setValue(newValue);
                          // }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Box>
                        <Stack direction={"row"}>
                          {orderDetailsBox(
                            "Contact",
                            orderDetails && orderDetails.target
                              ? orderDetails.target.business_mobile
                              : "N/A"
                          )}
                          <Box pt={1.5}>
                            <Tooltip title="Copy" placement="top">
                              <IconButton
                                onClick={() =>
                                  onCopy(
                                    orderDetails &&
                                      orderDetails.target &&
                                      orderDetails.target.business_mobile
                                  )
                                }
                              >
                                <Icon icon="eva:copy-fill" width={20} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Stack>
                      </Box>

                      <Box mt={1}>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Since
                        </Typography>
                        <Typography
                          fontFamily={"Public Sans"}
                          fontSize={"14px"}
                          lineHeight={"22px"}
                          fontWeight={600}
                        >
                          2023
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1.2 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Tooltip
                        title={
                          orderDetails &&
                          orderDetails.target &&
                          orderDetails.target.Place_of_Business
                        }
                      >
                        {orderDetailsBox(
                          "Address",
                          orderDetails &&
                            orderDetails.target &&
                            removeSubstring(
                              orderDetails.target.Place_of_Business
                            )
                        )}
                      </Tooltip>
                      <Box mt={2}>
                        <Typography
                          component="legend"
                          fontSize={"12px"}
                          color={"#919EAB"}
                        >
                          Orders Full-filed
                        </Typography>
                        <Stack spacing={1} display={"flex"} direction={"row"}>
                          <Typography
                            fontFamily={"Public Sans"}
                            fontSize={"14px"}
                            lineHeight={"22px"}
                            fontWeight={600}
                          >
                            {orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.ordersFullfilled}{" "}
                            /{" "}
                            {orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.totalOrders}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1.2 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      {/* {orderDetailsBox(
                        "GST Number",
                        orderDetails && orderDetails.target
                          ? orderDetails.target.GSTIN_of_the_entity
                          : "N/A"
                      )} */}
                      <Stack direction={"row"}>
                        {orderDetailsBox(
                          "GST",
                          orderDetails && orderDetails.target
                            ? orderDetails.target.GSTIN_of_the_entity
                            : "N/A"
                        )}
                        <Box pt={1.5}>
                          <Tooltip title="Copy" placement="top">
                            <IconButton
                              onClick={() =>
                                onCopy(
                                  orderDetails &&
                                    orderDetails.target &&
                                    orderDetails.target.GSTIN_of_the_entity
                                )
                              }
                            >
                              <Icon icon="eva:copy-fill" width={20} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>

                      <Box mt={2}>
                        <Stack
                          textAlign={"start"}
                          // spacing={5}
                          sx={
                            {
                              // px: 3,
                              // py: 1.5,
                            }
                          }
                        >
                          <Box>
                            <Typography
                              component="legend"
                              fontSize={"11px"}
                              color={"#919EAB"}
                              // mt={-2}
                            >
                              Connections
                            </Typography>
                            <Typography
                              fontFamily={"Public Sans"}
                              fontSize={"14px"}
                              lineHeight={"22px"}
                              fontWeight={600}
                            >
                              {(orderDetails &&
                                orderDetails.target &&
                                orderDetails.target.connections) ||
                                0}
                            </Typography>
                          </Box>
                          {/* <Stack
                            display={"flex"}
                            direction={"row"}
                            spacing={1.5}
                            mt={1}
                          >
                            <Box>
                              <Stack
                                display={"flex"}
                                direction={"row"}
                                spacing={1}
                              >
                                <Typography
                                  component="legend"
                                  fontSize={"11px"}
                                  color={"#919EAB"}
                                >
                                  Buyers
                                </Typography>
                                <Typography
                                  fontFamily={"Public Sans"}
                                  fontSize={"14px"}
                                  lineHeight={"22px"}
                                  fontWeight={600}
                                >
                                  0
                                </Typography>
                              </Stack>
                            </Box>
                            <Stack
                              display={"flex"}
                              direction={"row"}
                              spacing={1}
                            >
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#919EAB"}
                              >
                                Sellers
                              </Typography>
                              <Typography
                                fontFamily={"Public Sans"}
                                fontSize={"14px"}
                                lineHeight={"22px"}
                                fontWeight={600}
                              >
                                0
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </Box>
                    </Grid>

                    {/*  */}
                  </Grid>
                </Box>
              </Box>

              {/* Interest Table Details as per Order Details and Seller Business Details */}
              {/* <InterestTableList
                props={orderDetails}
                TableText="Receivables Details"
              /> */}
              <Box my={3} mx={1}>
                <Stack mx={4}>
                  <Typography16
                    color="#212B36"
                    text={"Receivables Details"}
                  ></Typography16>
                </Stack>
                <AcceptPaymentInterestTable props={orderDetails} />
              </Box>

              {/* Action Btn Accept and Reject */}
              <PaymentActionButton
                openRejectPayment={openRejectPayment}
                openAcceptPayment={openAcceptPayment}
                RedirectToSellerChat={RedirectToSellerChat}
                ButtonTextOne={"Chat with Seller"}
                ButtonTextTwo={"Accept"}
                ButtonTextThree={"Reject"}
                ButtonColor1={"#303981"}
                ButtonColor3={"#B71D18"}
                ButtonColor2={"#36B37E"}
                Button2TrueFalse={true}
                Button1TrueFalse={true}
                Button3TrueFalse={true}
              />
              {/* Payment Confirmation Dialog */}
              <PaymentConfirmDialog
                OpenDailog={openAccept}
                ClosePaymentAcceptDialog={ClosePaymentAcceptDialog}
                VerifyAcceptPaymentLoader={VerifyAcceptPaymentLoader}
                ApprovedPaymentConfigure={ApprovedPaymentConfigure}
                TitleText={"Order Confirmation"}
                ConfirmationText={
                  "Are you sure you want to proceed with this order Confirmation ?"
                }
              />
              {/* Payment Rejection Dialog */}
              <PaymentRejectDialog
                OpenDailog={openReject}
                RejectPaymentConfigure={RejectPaymentConfigure}
                CloseRejectPayment={CloseRejectPayment}
                VerifyRejectPaymentLoader={VerifyRejectPaymentLoader}
                TitleText={"Order Confirmation"}
                rejectRemarkValue={rejectRemarkValue}
                confirmationBtnDisable={RejectpaymentConfirmation}
                RejectionText="Are you sure you want to proceed with this order Rejection ?"
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default ApprovePayment;
