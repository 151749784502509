import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Typography14,
  Typography16,
  Typography18,
} from "../../../components/typography/Typography";
import { Stack } from "@mui/system";
import AvailableUsageTable from "./AvailableUsageTable";
import { numberFormat, splitDateConversion } from "../../../utils/Validations";
import { useEffect, useState } from "react";
import ApiService from "../../../api/api";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
import { SuccessMsg } from "../../../components/Messages/SuccessMsg";

const AvailableorderDetails = () => {
  const newToken = useSelector((state: any) => state.user.Token);

  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const orderData = location.state ? location.state.OrderDetails : {};

  const [orderDetails, setorderDetails] = useState<any>({});
  const ApprovedPaymentConfigure = async () => {
    let body = {
      action: "approved by checker",
      // remark: "Good To work with you",
    };
    await ApiService.setToken(newToken);
    const dashboardData: any = await ApiService.post(
      "/checkerRoutes/businessactiononpaymentrequest",
      body
    );
    if (dashboardData.status === 200) {
      SuccessMsg("Payment Request created Successfully");
      navigate("/dashboard/main");
    } else {
      ErrorMsg("Something went wrong, Please Try Again");
    }
  };
  const getUsersList = async () => {
    setloading(true);
    // userToken check
    const id = orderData._id;
    await ApiService.setToken(newToken);
    const dashboardData: any = await ApiService.get(
      `/checkerRoutes/receivable/borrower/${id}`
    );
    if (dashboardData.status === 200) {
      let data: any = dashboardData.data.orders;
      if (data.length > 0) {
        setorderDetails(data);
      } else {
        setorderDetails([]);
      }
      setloading(false);
    } else {
      setloading(false);
      console.log(dashboardData);
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <>
      <Stack mx={4}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"20px"}
          sx={{ mt: 4 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Receivable Details
        </Typography>
        <Typography14
          color={"#212B36"}
          fontWeight={400}
          text={"Available Receivables"}
        ></Typography14>
      </Stack>
      <Box
        justifyContent={"center"}
        component={"div"}
        sx={{
          backgroundColor: "#F7F9FB",
        }}
        pb={2}
      >
        <Box
          boxShadow={0.5}
          mx={4}
          my={2}
          py={2}
          sx={{
            borderRadius: "16px",
            backgroundColor: "white",
            minHeight: "250px",
            border: "1px solid #EEF2FA",
            boxShadow:
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
          }}
          component={"form"}
        >
          <Box
            px={8}
            mx={5}
            my={1.5}
            sx={{
              backgroundColor: " var(--grey-100, #F9FAFB)",
              border: "1px solid var(--grey-300, #DFE3E8)",
              borderRadius: "7px",
            }}
          >
            <Stack py={3}>
              <Typography16
                color={"#000"}
                text={`Order Id: ${orderData.orderID}`}
              ></Typography16>
            </Stack>
            <Box sx={{ px: 2, py: 2 }}>
              <Grid
                container
                sx={{
                  my: 2,
                }}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid
                  style={{
                    padding: "8px",
                  }}
                  sx={{ my: 1 }}
                  xs={2}
                  sm={4}
                  md={4}
                >
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    color={"#919EAB"}
                  >
                    AMOUNT
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    color={"#212B36"}
                    fontWeight={500}
                  >
                    {orderData.amount ? numberFormat(orderData.amount) : 0}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    padding: "8px",
                  }}
                  sx={{ my: 1 }}
                  xs={2}
                  sm={4}
                  md={4}
                >
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    color={"#919EAB"}
                  >
                    PLATFORM FEES
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    color={"red"}
                    fontWeight={500}
                  >
                    {orderData.platformFee > 0
                      ? orderData.platformFee.toFixed(2)
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    padding: "8px",
                  }}
                  sx={{ my: 1 }}
                  xs={2}
                  sm={4}
                  md={4}
                >
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    color={"#919EAB"}
                  >
                    PAYMENT DATE
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    color={"#212B36"}
                    fontWeight={500}
                  >
                    {orderData.dueDate
                      ? splitDateConversion(orderData.dueDate)
                      : "N/A"}
                  </Typography>
                </Grid>

                <Grid
                  style={{
                    padding: "8px",
                  }}
                  xs={2}
                  sx={{ my: 1 }}
                  sm={4}
                  md={4}
                >
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    color={"#919EAB"}
                  >
                    TOTAL RECEIVABLE
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    color={"green"}
                    fontWeight={600}
                    sx={{ m: 0, p: 0 }}
                  >
                    {orderData.eligibleAmount > 0
                      ? orderData.eligibleAmount.toFixed(2)
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    padding: "8px",
                  }}
                  xs={2}
                  sx={{ my: 1 }}
                  sm={4}
                  md={4}
                >
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    color={"#919EAB"}
                  >
                    USED AMOUNT
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    color={"#212B36"}
                    fontWeight={500}
                    sx={{ m: 0, p: 0 }}
                  >
                    {orderData.totalutilisedbySeller
                      ? orderData.totalutilisedbySeller.toFixed(2)
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* Interest Table Details as per Order Details and Seller Business Details */}
          <Box px={5}>
            <Box py={2}>
              <Typography18
                color={"#000"}
                text={"Receivables Usage Details"}
              ></Typography18>
            </Box>
            <AvailableUsageTable props={orderDetails} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AvailableorderDetails;
