import Header from "../components/Header";
import { useProSidebar } from "react-pro-sidebar";

import { AppBar, Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Sidebars from "../components/Sidebars";
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../api/api";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (phones)
      sm: 600, // Small devices (tablets)
      md: 960, // Medium devices (desktops)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra large devices (large desktops)
    },
  },
});

const DashboardRoutes = () => {
  const companyName = "Assured Pay";
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  let newToken = useSelector((state: any) =>
    state.user.Token ? state.user.Token : null
  );
  const { collapseSidebar } = useProSidebar();
  const collapsed = useProSidebar().collapsed;
  const [BankBalanceData, setBankBalanceData] = useState({});

  const getBankBalance = async () => {
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      "/checkerRoutes/getwalletbalance"
    );
    if (responseData.status === 200) {
      let orderData = responseData.data.result;
      setBankBalanceData(orderData);
      console.log("orderData", orderData);
    } else {
      console.log("responseData", responseData);
    }
  };

  useEffect(() => {
    getBankBalance();
  }, [newToken]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box>
          <Grid
            xs={12}
            md={isLargeScreen ? 12 : isMediumScreen ? 6 : 4}
            display={"flex"}
          >
            <Grid lg={3} item>
              <Sidebars />
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                backgroundColor: "#F7F9FB",
              }}
            >
              <AppBar
                style={{
                  backgroundColor: "white",
                  boxShadow:
                    "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <Header
                  collapsed={collapsed}
                  BankBalanceData={BankBalanceData}
                  showWallet={true}
                />
              </AppBar>
              <div style={{ margin: "50px 0 0 0" }}>
                <Outlet />
              </div>
            </Grid>
          </Grid>
          {/* Footer */}
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default DashboardRoutes;
