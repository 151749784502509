import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { StyledEngineProvider } from "@mui/material/styles";
import { ProSidebarProvider } from "react-pro-sidebar";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// Do not write anything here
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </Provider>
  // </React.StrictMode>
);
