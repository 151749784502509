import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AcceptReceivables from "./Accept_Receivables";
import RequestNewReceivables from "./Request_New_Receivables";
import AcceptPayables from "./Accept_Payables";
import NewPreclaim from "./New_Preclaim";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  borderRadius: "12px",
  color: theme.palette.text.secondary,
  // width: "100%",
  border: 0,
  gap: 2,
}));

// Dashboard Actionable Items (Receivables, Payables, Pre-Claims)
const ActionableItem = (props: any) => {
  return (
    <Box  sx={{ px: { xs: 0, sm: 4 } }}>
      <Typography
        fontFamily={"Public Sans"}
        fontSize={"22px"}
        fontWeight={700}
        fontStyle={"normal"}
        color={"#212B36"}
      >
        Actionable Items
      </Typography>

      <>
        <Grid container spacing={3} sx={{width:"100%"}}>
          {props.open ? (
            <Grid item xs={12} sm={12} md={6}>
              <Item
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "16px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  py: 10,
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <CircularProgress
                  color="inherit"
                  sx={{ color: "green" }}
                  size={30}
                />
              </Item>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={6}>
              <Item
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "16px",
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <Grid container alignItems="center" py={1} height={"100%"}>
                  {/* Accept Receivables */}
                  <AcceptReceivables
                    AcceptReceivableAmount={props.ActionableValues}
                  />

                  {/* Request New Receivables */}
                  <RequestNewReceivables
                    RequestNewReceivablesAmount={props.ActionableValues}
                  />
                </Grid>
              </Item>
            </Grid>
          )}

          {props.open ? (
            <Grid item xs={12} sm={12} md={6}>
              <Item
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "16px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  py: 10,
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <CircularProgress
                  sx={{ color: "orange" }}
                  size={30}
                  color="inherit"
                />
              </Item>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={6}>
              <Item
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "16px",
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                <Grid container alignItems="center" py={1} height={"100%"}>
                  {/* Accept Payables */}
                  <AcceptPayables
                    AcceptPayablesAmount={props.ActionableValues}
                  />
                  {/* Pending Pre-Claims Request or Create New Pre-Claims */}
                  <NewPreclaim NewPreclaimAmount={props.ActionableValues} />
                </Grid>
              </Item>
            </Grid>
          )}
        </Grid>
      </>
      <>
        <Grid container spacing={3}></Grid>
      </>
    </Box>
  );
};

export default ActionableItem;
