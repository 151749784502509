import { routerType } from "../types/router.types";
import OnBoardingPayment from "../pages/onBoardingPays/OnBoardingPayment";
import ResetPassword from "../pages/login/ResetPassword";
import CreateUserGstProfile from "../pages/GST/CreateUserGstProfile";
import CreateBusinessProfile from "../pages/GST/CreateBusinessProfile";
import AadharVerify from "../pages/Aadhar/AdharVerify";
import PanVerify from "../pages/Pan/PanVerify";
import PanUploadFiles from "../pages/Pan/PanUploadFiles";
import AdminApproved from "../pages/Pan/AdminApproval";
import Busi1 from "../pages/business/Busi1";
import Busi2 from "../pages/business/Busi2";
import Busi3 from "../pages/business/Busi3";
import ReUpload from "../pages/Reupload/ReuploadDocs";
import Dashboard from "../pages/Payments/Dashboard/Dashboard";
import SelectPayment from "../pages/Payments/SelectPayment";
import CreateNewPaymentBuyer from "../pages/Payments/CreatePaymentBuyer/CreateNewPaymentBuyer";
import AddMaker from "../pages/Maker/AddMaker";
import ViewMaker from "../pages/Maker/ViewMaker";
import PaymentApproved from "../pages/Payments/ViewOrders/Completed/CompletOrder";
import AcceptPaymentMain from "../pages/Payments/AcceptPayment/Accept/AcceptPaymentMain";
import ApproveRejectPayment from "../pages/Payments/AcceptPayment/ApproveRejectPayment";
import MakerRequestMain from "../pages/Payments/MakerRequests/MakerRequestMain";
import BookPayablesMain from "../pages/Payments/BookedPayables/BookPayablesMain";
import AvailableRecievablesMain from "../pages/Payments/AvailableRecievables/AvailableRecievablesMain";
// import PaymentRequestMain from "../pages/Payments/PaymentRequest/PaymentRequestMain";
import CreateNewPaymentSeller from "../pages/Payments/CreatePaymentSeller/CreateNewPaymentSeller";
import AvailableOrderDetails from "../pages/Payments/AvailableRecievables/AvailableOrderDetails";
// import RequestOrderDetails from "../pages/Payments/PaymentRequest/RequestOrderDetails";
import BookPayablesOrder from "../pages/Payments/BookedPayables/BookPayablesOrder";
import ExpiredPaymentRequest from "../pages/Payments/ViewOrders/Expired/ExpiredPaymentRequest";
import RejectedPaymentRequest from "../pages/Payments/ViewOrders/Rejected/RejectedPaymentRequest";
import CancelOrder from "../pages/Payments/ViewOrders/Canceled/CancelOrder";
import ReuploadRequest from "../pages/Reupload/ReuploadRequest";
import RateYourSeller from "../pages/BusinessRating/RateYourSeller";
import GeneratePaymentRequestSeller from "../pages/Payments/ViewOrders/InProcess/Seller/GeneratePaymentRequestSeller";
import AcceptRequestMain from "../pages/Payments/AcceptRequest/Accept/AcceptRequestMain";
import CreateNewPaymentForSeller from "../pages/Payments/CreatePaymentBuyer/CreateNewPaymentForSeller";
import AcceptRequestMainPage from "../pages/Payments/AcceptRequest/Accept/AcceptRequestMainPage";
import ApproveRejectPaymentRequest from "../pages/Payments/AcceptRequest/ApproveRejectPaymentRequest";
import ApprovePaymentRequestList from "../pages/Payments/AcceptRequest/ApprovePaymentRequestList";
import PaymentRequestConfig from "../pages/Payments/CreatePaymentSeller/PaymentRequestConfig";
import BuyerOrder from "../pages/Payments/Orders/Main";
import SellerOrder from "../pages/Payments/RequestOrder/Main";
import Chat from "../pages/Chat/Chat/Main";
import Orders from "../pages/Payments/Dashboard/Orders";
import Reports from "../pages/Reports/Main";

const auth = "auth";
const dashboard = "dashboard";
export const authrouterData: routerType[] = [
  {
    path: `${auth}/reset`,
    element: <ResetPassword />,
    title: "Reset_Password",
  },
  {
    path: `${auth}/onboardingpays`,
    element: <OnBoardingPayment />,
    title: "Boarding_Fees",
  },
  {
    path: `${auth}/userkyc`,
    element: <CreateUserGstProfile />,
    title: "Create_User_Gst_Profile",
  },
  {
    path: `${auth}/upload-files`,
    element: <CreateBusinessProfile />,
    title: "Create_User_Business_Profile",
  },
  {
    path: `${auth}/verifyaadhar`,
    element: <AadharVerify />,
    title: "Aadhar_Verify",
  },
  {
    path: `${auth}/verifypan`,
    element: <PanVerify />,
    title: "Pan_Verify",
  },
  {
    path: `${auth}/panuploadfiles`,
    element: <PanUploadFiles />,
    title: "Pan_Upload_files",
  },
  {
    path: `${auth}/approval`,
    element: <AdminApproved />,
    title: "Approval",
  },
  {
    path: `${auth}/postrequest`,
    element: <ReuploadRequest />,
    title: "Reupload request",
  },
  {
    path: `${auth}/reupload`,
    element: <ReUpload />,
    title: "Reupload",
  },
];
// export default routerData;
export const dashboardRoutesData: routerType[] = [
  {
    path: `${dashboard}/main`,
    element: <Dashboard />,
    title: "Dashboard",
  },
  {
    path: `${dashboard}/orders`,
    element: <Orders />,
    title: "My Orders",
  },
  {
    path: `${dashboard}/addbusinessnetwork`,
    element: <Busi1 />,
    title: "Approval",
  },
  {
    path: `${dashboard}/businesslist`,
    element: <Busi2 />,
    title: "addBusinessNetwork",
  },
  {
    path: `${dashboard}/busi3`,
    element: <Busi3 />,
    title: "Busi3",
  },
  {
    path: `${dashboard}/payment`,
    element: <Dashboard />,
    title: "Payment Dashboard",
  },
  {
    path: `${dashboard}/selectpayment`,
    element: <SelectPayment />,
    title: "select Payment",
  },
  {
    path: `${dashboard}/createpayment`,
    element: <CreateNewPaymentBuyer />,
    title: "Create Payment",
  },
  {
    path: `${dashboard}/createpaymentrequest`,
    element: <CreateNewPaymentSeller />,
    title: "Create Seller Payment",
  },
  {
    path: `${dashboard}/paymentrequestconfig`,
    element: <CreateNewPaymentForSeller />,
    title: "Create Seller Payment Config",
  },
  {
    path: `${dashboard}/createmaker`,
    element: <AddMaker />,
    title: "Add Maker",
  },
  {
    path: `${dashboard}/users`,
    element: <ViewMaker />,
    title: "View Maker",
  },
  //  Order Status Confirmation
  {
    path: `${dashboard}/order`,
    element: <BuyerOrder />,
    title: "Buyer Orders",
  },

  {
    path: `${dashboard}/request`,
    element: <SellerOrder />,
    title: "Buyer Orders",
  },
  {
    path: `${dashboard}/requestedorder`,
    element: <BuyerOrder />,
    title: "Buyer Orders",
  },
  //
  // {
  //   path: `${dashboard}/generaterequest`,
  //   element: <GeneratePaymentRequest />,
  //   title: "Generate Payment Request",
  // },
  {
    path: `${dashboard}/orderrequest`,
    element: <GeneratePaymentRequestSeller />,
    title: "Generate Payment Request Seller",
  },
  {
    path: `${dashboard}/completepayment`,
    element: <PaymentApproved />,
    title: "Complete Payment",
  },
  {
    path: `${dashboard}/acceptpayments`,
    element: <AcceptPaymentMain />,
    title: "Approve Payment",
  },
  // {
  //   path: `${dashboard}/acceptrequests`,
  //   element: <AcceptRequestMain />,
  //   title: "Accept  Request",
  // },
  {
    path: `${dashboard}/approvepayments`,
    element: <ApproveRejectPayment />,
    title: "Accept Payment",
  },
  {
    path: `${dashboard}/makerrequest`,
    element: <MakerRequestMain />,
    title: "Maker Request",
  },
  // Booked Payables order List
  {
    path: `${dashboard}/bookpayables`,
    element: <BookPayablesMain />,
    title: "Booked Payables",
  },
  // booked payables order Details
  {
    path: `${dashboard}/order`,
    element: <BookPayablesOrder />,
    title: "Booked Payables Order",
  },
  {
    path: `${dashboard}/availablerecievables`,
    element: <AvailableRecievablesMain />,
    title: "Booked Payables",
  },
  // requests Order Details as per status and pages
  // receivables
  {
    path: `${dashboard}/recievableorder`,
    element: <AvailableOrderDetails />,
    title: "Available Order Details",
  },

  // request Payments Lists
  {
    path: `${dashboard}/requestpayments`,
    element: <AcceptRequestMain />,
    title: "Booked Payables",
  },
  {
    path: `${dashboard}/requestpayments/order`,
    element: <AcceptRequestMainPage />,
    title: "view Payment Request",
  },
  {
    path: `${dashboard}/acceptrequest`,
    element: <ApprovePaymentRequestList />,
    title: "Accept Reject Payment Request",
  },
  {
    path: `${dashboard}/acceptrequest/order`,
    element: <ApproveRejectPaymentRequest />,
    title: "Accept Reject Payment Request",
  },
  // config/paymentrequest
  {
    path: `${dashboard}/config/paymentrequest`,
    element: <PaymentRequestConfig />,
    title: "Payment Request Config",
  },
  // paymentRequests Order Details
  // {
  //   path: `${dashboard}/requestorder`,
  //   element: <RequestOrderDetails />,
  //   title: "Request Order Details",
  // },
  // Expired Payments Details
  {
    path: `${dashboard}/expireorder`,
    element: <ExpiredPaymentRequest />,
    title: "Expire Order Details",
  },
  // InProcess Payments Details
  {
    path: `${dashboard}/cancelorder`,
    element: <CancelOrder />,
    title: "Canceled Order Details",
  },
  // Rejected Payments Details
  {
    path: `${dashboard}/rejectorder`,
    element: <RejectedPaymentRequest />,
    title: "Rejected Order Details",
  },
  {
    path: `${dashboard}/chat`,
    element: <Chat />,
    title: "Chat",
  },
  {
    path: `${dashboard}/rating`,
    element: <RateYourSeller />,
    title: "Seller Rating",
  },

  { path: `${dashboard}/reports/`, element: <Reports />, title: "Reports" },
];
