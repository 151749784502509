import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import PaymentActionButton from "../../Reusable/PaymentActionButton";
import View from "../../Orders/view";

const CancelOrder = (props: any) => {
  const navigate = useNavigate();
  const orderDetails = (props && props.orderDetails) || {};
  //  Redirect To Chat Page
  const RedirectToSellerChat = () => {
    navigate("/dashboard/chat", { state: { orderDetails: orderDetails } });
  };

  return (
    <div>
      <>
        <Box pb={2}>
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              backgroundColor: "#F7F9FB",
            }}
          >
            <Box
              boxShadow={0.5}
              mx={4}
              sx={{
                borderRadius: "16px",
                backgroundColor: "white",
                border: "1px solid #EEF2FA",
                boxShadow:
                  "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              }}
            >
              <View props={orderDetails} />
              <PaymentActionButton
                RedirectToSellerChat={RedirectToSellerChat}
                ButtonTextOne={"Chat with Seller"}
                Button1TrueFalse={true}
                ButtonColor1={"#303981"}
              />
            </Box>
          </Box>
        </Box>
      </>
    </div>
  );
};

export default CancelOrder;
