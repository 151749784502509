import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../../api/api";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
import GeneratePaymentRequest from "../ViewOrders/InProcess/GeneratePaymentRequest";
import BookPayablesOrder from "../BookedPayables/BookPayablesOrder";
import CancelOrder from "../ViewOrders/Canceled/CancelOrder";
import RejectedPaymentRequest from "../ViewOrders/Rejected/RejectedPaymentRequest";
import ExpiredPaymentRequest from "../ViewOrders/Expired/ExpiredPaymentRequest";
import SimpleLoader from "../../../components/Loader";
import OrderStack from "../Reusable/OrderStack";
import PaymentConfirmIcon from "../../../assets/icons8-complete.gif";
import CancleOrderIcon from "../../../assets/CancleOrder.svg";
import CompleteOrder from "../ViewOrders/Completed/CompletOrder";
import GeneratePaymentRequestOrder from "../ViewOrders/InProcess/GeneratePaymentRequestOrder";

const Main = () => {
  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.user.Token);
  const location = useLocation();
  const orderId = location.state ? location.state.orderDetails : {};
  console.log(orderId, "-------------------orderId, Main.............");
  const [open, setopen] = useState(false);
  const [orderDetails, setorderDetails] = useState<any>({});
  document.title = `Payment order : ${
    orderDetails && orderDetails.order && orderDetails.order.status
  }`;
  const getOrderDetails = async () => {
    let id = orderId ? orderId._id : "";
    if (newToken !== "" && newToken !== undefined) {
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.get(
        `/checkerRoutes/order/${id}`
      );
      if (responseData.status === 200) {
        let referenceData = responseData.data.data;
        let array = [];
        for (let i in referenceData.milestones) {
          array.push(referenceData.milestones[i]);
        }
        referenceData.milestones = array.flat(1);
        setorderDetails(referenceData);
        setopen(false);
      } else {
        ErrorMsg("Something went wrong,Please try again !");
        setopen(true);
      }
    }
  };
  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <Box>
      {open ? (
        <SimpleLoader props={open} />
      ) : (
        <>
          <>
            {/* From Buyer To Seller */}
            {/* Payment Successfully approved from  & showing them into GeneratePaymentRequest Page */}
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "approved by checker" ? (
              <Box pb={2}>
                {orderId?.isPr ? (
                  <>
                    <OrderStack
                      props={orderDetails}
                      OrderText={"Business Order"}
                      titleIcon={PaymentConfirmIcon}
                      titleIconColor={"#36B37E"}
                      confirmationTitle={"Congratulations"}
                      confirmationSubtitle={`${
                        orderDetails &&
                        orderDetails.target &&
                        orderDetails.target.Legal_Name_of_Business
                      } is assured with this payment`}
                    />
                    {/* Chat Enable */}
                    <GeneratePaymentRequestOrder orderDetails={orderDetails} />
                  </>
                ) : (
                  <>
                    <OrderStack
                      props={orderDetails}
                      OrderText={"Business Order"}
                      titleIcon={PaymentConfirmIcon}
                      titleIconColor={"#36B37E"}
                      confirmationTitle={"Congratulations"}
                      confirmationSubtitle={`${
                        orderDetails &&
                        orderDetails.target &&
                        orderDetails.target.Legal_Name_of_Business
                      } is assured with this payment`}
                    />
                    {/* Chat Enable */}
                    <GeneratePaymentRequest orderDetails={orderDetails} />
                  </>
                )}
              </Box>
            ) : (
              ""
            )}
          </>
          <>
            {/* From Buyer To Seller */}
            {/* Payment Successfully approved from checker & showing them into GeneratePaymentRequest Page */}
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "cancelled" ? (
              <>
                <Box pb={2}>
                  <OrderStack
                    props={orderDetails}
                    OrderText={"Business Order"}
                    titleIcon={CancleOrderIcon}
                    titleIconColor={"#B71D18"}
                    confirmationTitle={"Cancelled Payment"}
                    confirmationSubtitle={`${
                      orderDetails &&
                      orderDetails.target &&
                      orderDetails.target.Legal_Name_of_Business
                    } is assured with this payment`}
                  />
                  {/* No Need Chat */}
                  <CancelOrder orderDetails={orderDetails} />
                </Box>
              </>
            ) : (
              ""
            )}
          </>
          <>
            {/* From Buyer To Seller */}
            {/* Payment Successfully confirmed*/}
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "active" ? (
              <>
                {orderId && orderId.order && !orderId.order.isPr ? (
                  <BookPayablesOrder orderDetails={orderDetails} />
                ) : (
                  <BookPayablesOrder orderDetails={orderDetails} />
                )}
              </>
            ) : (
              ""
            )}
          </>
          <>
            {/* From Buyer To Seller */}
            {/* Payment Successfully rejected */}
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "rejected" ? (
              <RejectedPaymentRequest orderDetails={orderDetails} />
            ) : (
              ""
            )}
          </>
          <>
            {/* From Buyer To Seller */}
            {/* Payment Successfully Expired */}
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "expired" ? (
              <ExpiredPaymentRequest orderDetails={orderDetails} />
            ) : (
              ""
            )}
          </>
          <>
            {orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "completed" ? (
              <CompleteOrder orderDetails={orderDetails} />
            ) : (
              ""
            )}
          </>
        </>
      )}
    </Box>
  );
};

export default Main;
