import { Route, Routes } from "react-router-dom";
import { routerType } from "../types/router.types";
import { authrouterData, dashboardRoutesData } from "./routerData";
import ProtectedRoutes from "./ProtectedRoutes";
import Login from "../pages/login/Login";
import Registor from "../pages/Signup/Register";
import PageNotFound from "../pages/PageNotFound";
import OtpVerification from "../pages/Signup/verifyOtp/OtpVerification";
import ForgetPassword from "../pages/login/ForgetPassword";
import DashboardProtectedRoutes from "../routes/DashboardRoutes";
import TwoStepVerification from "../pages/login/TwoStepVerification";
// import { useSelector } from "react-redux";
// import useAuthRedirect from "./VerifyUser";

// Routes Arrange for Our Large Scale Applications
const Router = () => {
  // useAuthRedirect();
  // const newToken = useSelector((state: any) => state.user.Token);

  // This mapping function arranges all routes array recieved from seperate routerData page
  const pageRoutes = authrouterData.map(
    ({ path, title, element }: routerType) => {
      return <Route key={title} path={`/${path}`} element={element} />;
    }
  );
  const DashboardRoutes = dashboardRoutesData.map(
    ({ path, title, element }: routerType) => {
      return <Route key={title} path={`/${path}`} element={element} />;
    }
  );

  return (
    <Routes>
      {/* These are the [Protected Routes necessary for access pages through tokens] */}
      <Route path="/dashboard" element={<DashboardProtectedRoutes />}>
        {DashboardRoutes}
      </Route>
      {/* Define seperate login routes and othes, these are unprotected and user can access their pages without their tokens */}

      <>
        <Route path={"/"} element={<Registor />} />
        <Route path={"/verify"} element={<OtpVerification />} />
        <Route path="/auth" element={<ProtectedRoutes />}>
          {pageRoutes}
        </Route>
        <Route path={"/login"} element={<Login />} />
        <Route path={"/authentication"} element={<TwoStepVerification />} />
        <Route path={"/forget"} element={<ForgetPassword />} />
        <Route path={"*"} element={<PageNotFound />} />
      </>

      {/* for enter wrong url */}
      <Route path={"*"} element={<PageNotFound />} />
    </Routes>
  );
};
export default Router;
