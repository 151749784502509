import React, { useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Payable_wallet from "../../../../../assets/Payable_wallet.svg";
import { useNavigate } from "react-router-dom";
import {
  numberFormat,
  numberToEnglish,
} from "../../../../../utils/Validations";
import ModalDialog from "../../../../../components/Dialog/ModelDialog";

// Booked Order Details (Active Status)
const Ap_Payables = (props: any) => {
  const navigate = useNavigate();
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);
  const modelTitle = "AssuredPay Payables";
  const modelSubtitle =
    "This refers to the payments you are bound to make in the coming future.";

  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          AssuredPay Payables
          <img src={Payable_wallet} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "9px",
            }}
          >
            My booked payments for settlement
          </Typography>
          <InfoIcon
            sx={{ color: "#FFF", height: 25, width: 25 }}
            fontSize="small"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}

          />
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={modelTitle}
            modelSubtitle={modelSubtitle}
            BgColor={"#FB9E37"}
            TextColor={"#FFFFFF"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          {numberFormat(props?.Av_Payables?.bookedPayment?.sumOrderAmount)}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          {props?.Av_Payables?.bookedPayment?.sumOrderAmount > 0
            ? numberToEnglish(
                parseInt(props?.Av_Payables?.bookedPayment?.sumOrderAmount)
              ).toLocaleUpperCase()
            : "  YOU HAVEN'T BOOKED ANY PAYMENT."}
        </Typography>
      </Box>
      <Box my={2} mx={2}>
        <Button
          disabled={props?.Av_Payables?.bookedPayment?.sumOrderAmount <= 0}
          size="small"
          onClick={() => navigate("/dashboard/bookpayables")}
          sx={{
            px: 2,
            ":hover": {
              backgroundColor: "#FB9E37",
            },
          }}
          style={{
            textTransform: "none",
            color: "#FFFFFF",
            borderRadius: "8px",
            fontSize: "11px",
            border: "1px solid #fff",
            fontWeight: "700",
          }}
          type="submit"
        >
          View all Payables
        </Button>
      </Box>
    </Grid>
  );
};

export default Ap_Payables;
