import { Box, Grid, Stack, Typography } from "@mui/material";
import AcceptR_Wallet from "../../../../assets/AcceptR_Wallet.svg";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { numberFormat, numberToEnglish } from "../../../../utils/Validations";
import ModalDialog from "../../../../components/Dialog/ModelDialog";
import { useState } from "react";

const Accept_Receivables = (props: any) => {
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);
  const modelTitle = "Model Title";
  const modelSubtitle =
    "This refers to the assured payments you will receive upon accepting the requests.";
  const navigate = useNavigate();
  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#36A979",
            fontWeight: 700,
          }}
        >
          Accept Receivables
          <img src={AcceptR_Wallet} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            sx={{
              fontFamily: "Public Sans",
              color: "#36A979",
              fontSize: "9px",
            }}
          >
            My available balance to make payments.
          </Typography>
          <InfoIcon
            sx={{ color: "#36A979", height: 25, width: 25 }}
            fontSize="small"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          />
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Public Sans",
              color: "#36A979",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}

          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={modelTitle}
            modelSubtitle={modelSubtitle}
            BgColor={"#FFFFFF"}
            TextColor={"#36A979"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#36A979",
            fontWeight: 700,
          }}
        >
          {numberFormat(
            props.AcceptReceivableAmount.receivedOrderRequests?.sumOrderAmount
          ) || 0}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#36A979",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          {props.AcceptReceivableAmount.receivedOrderRequests?.sumOrderAmount >
          0
            ? numberToEnglish(
                props.AcceptReceivableAmount.receivedOrderRequests
                  ?.sumOrderAmount
              ).toLocaleUpperCase()
            : "YOU DON'T HAVE RECEIVABLES"}
        </Typography>
      </Box>
      <Box my={2} mx={2}>
        <LoadingButton
          disabled={
            props.AcceptReceivableAmount.receivedOrderRequests
              ?.sumOrderAmount <= 0
          }
          size="small"
          fullWidth
          onClick={() => navigate("/dashboard/acceptpayments")}
          sx={{ px: 2 }}
          style={{
            textTransform: "none",
            backgroundColor: "#36A979",
            color: "#fff",
            borderRadius: "8px",
            fontSize: "11px",
            border: "1px solid #36A979",
            fontWeight: "700",
          }}
          type="submit"
        >
          View all
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default Accept_Receivables;
