import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import PaymentActionButton from "../../Reusable/PaymentActionButton";
import PaymentConfirmDialog from "../../Reusable/PaymentConfirmDialog";
import { SuccessMsg } from "../../../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../../../components/Messages/ErrorMsg";
import ApiService from "../../../../api/api";
import View from "../../Orders/view";
// import Timer from "../../../../components/StopWatch/Timer";

const GeneratePaymentRequestOrder = (props: any) => {
  const orderDetails = props ? props.orderDetails : {};
  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.user.Token);
  const location = useLocation();
  const orderId = location.state ? location.state.orderDetails : {};
  const [CancelPaymentDialog, setCancelPaymentDialog] = useState(false);

  // Dialog Open For Cancel Payment
  const openCanclePayment = () => {
    setCancelPaymentDialog(true);
  };
  // Dialog Close For Cancle Payment
  const closeCanclePayment = () => {
    setCancelPaymentDialog(false);
  };
  // Cancle Payment Confirmation
  const CancelPaymentConfirmed = async () => {
    let body = {};
    if (newToken !== "" && newToken !== undefined) {
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.post(
        `/checkerRoutes/cancelorder/${orderDetails.order._id}`,
        body
      );
      if (responseData.status === 200) {
        setCancelPaymentDialog(false);
        SuccessMsg("Payment order canceled successfully !");
        navigate("/dashboard/main");
      } else {
        ErrorMsg(`${responseData.response.data.error!}`);
      }
    }
  };
  //  Redirect To Chat Page
  const RedirectToSellerChat = () => {
    navigate("/dashboard/chat", { state: { orderDetails: orderDetails } });
  };

  return (
    <>
      <Box pb={2}>
        {/*  step 1 */}

        {/* step2 */}
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
        >
          <Box
            boxShadow={0.5}
            mx={4}
            sx={{
              borderRadius: "16px",
              backgroundColor: "white",
              border: "1px solid #EEF2FA",
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            }}
          >
            <View props={orderDetails} />

            {/* Payment Action Btn for Cancle Payment or Redirect to chat with your seller */}

            <>
              <PaymentActionButton
                openAcceptPayment={openCanclePayment}
                RedirectToSellerChat={RedirectToSellerChat}
                ButtonTextOne={"Chat with Seller"}
                Button2TrueFalse={false}
                Button1TrueFalse={true}
                ButtonTextTwo={""}
                ButtonColor1={"#303981"}
                ButtonColor2={""}
              />
            </>

            <PaymentConfirmDialog
              OpenDailog={CancelPaymentDialog}
              ClosePaymentAcceptDialog={closeCanclePayment}
              ApprovedPaymentConfigure={CancelPaymentConfirmed}
              TitleText={"Order Confirmation"}
              ConfirmationText={
                "Are you sure you want to proceed with this order cancellation ?"
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default GeneratePaymentRequestOrder;
