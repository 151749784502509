import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import React from "react";
import BookPayablesList from "./BookPayablesList";
import ApiService from "../../../api/api";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
import LoadingScreen from "../../../components/loadingScreen/LoadingScreen";

// --------------------------BOOKED PAYABLES (ORDERS CREATED PAGE WHEN STATUS ACTIVE)--------------------------------------
const BookPayablesMain = () => {
  // Token
  const newToken = useSelector((state: any) => state.user.Token);

  // Pagination;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [BookPayables, setBookPayables] = useState<any>([]);
  const [loading, setloading] = useState(false);

  const getUsersList = async () => {
    setloading(true);
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      "/checkerRoutes/created/orders"
    );
    if (responseData.status === 200) {
      const referenceData = responseData.data.result;
      if (referenceData.length > 0) {
        setBookPayables(referenceData);
      } else {
        setBookPayables([]);
      }
      setloading(false);
    } else {
      setloading(false);
      ErrorMsg("Something went wrong, Please try again !");
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <>
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              backgroundColor: "#F7F9FB",
            }}
          >
            <Stack mx={4} sx={{marginTop:{xs:"110px",md:"50px"}}}>
              <Typography
                fontFamily={"Public Sans"}
                fontSize={"24px"}
                sx={{ mt: 4 }}
                fontWeight={700}
                fontStyle={"normal"}
                color={"#000000"}
              >
                Booked Payables
              </Typography>
            </Stack>

            <Box
              boxShadow={
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
              }
              mx={4}
              my={2}
              sx={{ borderRadius: "8px", backgroundColor: "white" }}
            >
              <Stack
                sx={{ mx: 4, py: 2 }}
                display={"flex"}
                direction={"row"}
                spacing={1}
              >
                {" "}
                <TextField
                  disabled
                  fullWidth
                  placeholder="Search gst number, mobile number ....."
                  InputProps={{
                    style: { borderRadius: "10px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Box px={2}>
                <BookPayablesList props={BookPayables} />
              </Box>
              <Box>
                <Divider></Divider>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"end"}
                >
                  <TablePagination
                    component="div"
                    count={10}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default BookPayablesMain;
