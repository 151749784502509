// export default PanUploadFiles;
import { Box, Stack, Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CustomizedSteppers from "../../components/Stepper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Docs1 from "../../assets/Docs1.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LinearDeterminate from "../../components/ProgressBar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";

import { globalStatusExecute } from "../../utils/ApiGlobal";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";

const PanUploadFiles = () => {
  document.title = "Let's Get Started with Your Information";
  const REACT_APP_BASE_URL =
    process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL;
  const BaseUrl = "https://dev.api.assuredpay.in";

  const newToken = useSelector((state: any) => state.user.Token);
  const navigate = useNavigate();
  const locationData = useSelector((state: any) => state.user.LocationInfo);
  console.log(locationData, "locationData");
  // const [FileUploadStatus, setFileUploadStatus] = useState(false);
  const [uploadFileData, setuploadFileData] = useState(null);
  const [PanFlie1, setPanFlie1] = useState(false);
  const [PanFlie1BtnDisabled, setPanFlie1BtnDisabled] = useState(false);
  const [PanFlie2BtnDisabled, setPanFlie2BtnDisabled] = useState(false);
  const [PanFlie3BtnDisabled, setPanFlie3BtnDisabled] = useState(false);

  const [PanFlie2, setPanFlie2] = useState(false);
  const [PanFlie3, setPanFlie3] = useState(false);
  const [Token, setToken] = useState<any>("");
  const [activeStep, setActiveStep] = useState(2);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  console.log("file", uploadFileData);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const handleFileUpload = async (e: any) => {
    let file;
    if (e.target.name === "aadharFileUrl") {
      file = e.target.files[0];
      setPanFlie1(true);
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: newToken,
          lat: "22",
          lng: "77",
        },
        params: {
          filename: e.target.name,
        },
      };
      const FetchData = await axios
        .post(
          `${BaseUrl}/uploadRoutes/uploaddoc`,
          { document: file },
          axiosConfig
        )
        .then((res) => {
          const getUserLogin = res;
          if (getUserLogin.status === 200) {
            setPanFlie1(false);
            setPanFlie1BtnDisabled(true);
          }
        })
        .catch((err) => {
          console.log(err, "catch Method");
          // setshowAadharVerifyMsgError(true);
        });
    } else if (e.target.name === "aadharBackUrl") {
      file = e.target.files[0];
      setPanFlie2(true);

      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: newToken ? newToken : "",
          lat: "22",
          lng: "77",
        },
        params: {
          filename: e.target.name,
        },
      };
      const FetchData = await axios
        .post(
          `${BaseUrl}/uploadRoutes/uploaddoc`,
          { document: file },
          axiosConfig
        )
        .then((res) => {
          const getUserLogin = res;
          if (getUserLogin.status === 200) {
            setPanFlie2(false);
            setPanFlie2BtnDisabled(true);
          }
        })
        .catch((err) => {
          console.log(err, "catch Method");
          // setshowAadharVerifyMsgError(true);
        });
    } else if (e.target.name === "PANFile") {
      file = e.target.files[0];
      setPanFlie3(true);

      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: newToken,
          lat: "22",
          lng: "77",
        },
        params: {
          filename: e.target.name,
        },
      };
      const FetchData = await axios
        .post(
          `${BaseUrl}/uploadRoutes/uploaddoc`,
          { document: file },

          axiosConfig
        )
        .then((res) => {
          const getUserLogin = res;
          if (getUserLogin.status === 200) {
            setPanFlie3(false);
            setPanFlie3BtnDisabled(true);
          }
        })
        .catch((err) => {
          console.log(err, "catch Method");
        });
    }
  };
  const handleOnClickfileUploadSuccess = async () => {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: newToken,
        lat: "22",
        lng: "77",
      },
    };
    const res1 = await axios
      .post(`${BaseUrl}/userkyc/generateuuid`, locationData, axiosConfig)
      .then(async (res) => {
        if (res.status === 200) {
          let Uuid = res.data.result.userRequestReference;
          // await globalStatusExecute(newToken, "/auth/approval");
          navigate("/auth/approval", {
            state: Uuid,
          });
        } else {
          ErrorMsg("Something went wrong !");
        }
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F7F9FB",
        height: "90vh",
      }}
    >
      <Stack pt={1}>
      <Box textAlign={"center"}>
        <Stack sx={{ px: "100px" }}>
          <CustomizedSteppers activeStep={3} />
        </Stack>
      </Box>      </Stack>
      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box
          // boxShadow={"1"}
          justifyContent={"center"}
          // sx={{
          //   display: "flex",
          //   flexDirection: "column",
          //   px: 5,
          //   py: 2,
          //   // ml: 15,
          // }}
          boxShadow={
            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
          }
          border={"1px solid #EEF2FA"}
          borderRadius={"16px"}
          // sx={{
          //   backgroundColor: "rgba(48, 57, 129, 1)",
          // }}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            mx: 4,
            py: 3,
            mt: 2,
            backgroundColor: "white",
          }}
        >
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ fontSize: "12px" }}
          >
            <Grid sx={{ order: { xs: 12, sm: 2 }, mt: 4, mb: 2 }}>
              <Typography
                component="div"
                fontFamily={"Public Sans"}
                fontSize={20}
                fontWeight={700}
                lineHeight={"30px"}
                color={"#212B36"}
              >
                Create Your Personal Profile
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                mb: 5,
                color: "#556486",
                fontWeight: 600,
                fontFamily: "Public Sans",
                fontStyle: "normal",
              }}
            >
              {" "}
              List of PAN details required for personal identification
            </Typography>
          </Box>

          <Box padding={0}>
            <Grid container>
              <Grid xs={10}>
                <Item>
                  <Stack display={"flex"} direction={"row"} spacing={5}>
                    <img
                      src={Docs1}
                      style={{
                        height: "20px",
                        width: "20px",
                        margin: "0px 0 0 0",
                      }}
                      alt=""
                    />
                    <Typography
                      fontSize={14}
                      color={"#454F5B"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      fontWeight={500}
                    >
                      {" "}
                      Choose front side of Aadhar Card
                    </Typography>
                  </Stack>{" "}
                </Item>
                <Item>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={5}
                    sx={{ mt: 0.5 }}
                  >
                    <img
                      src={Docs1}
                      style={{
                        height: "20px",
                        width: "20px",
                        margin: "0px",
                      }}
                      alt=""
                    />
                    <Typography
                      fontSize={14}
                      color={"#454F5B"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      fontWeight={500}
                    >
                      {" "}
                      Choose Back side of Aadhar Card
                    </Typography>
                  </Stack>{" "}
                </Item>
                <Item>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    spacing={5}
                    sx={{ mt: 0.5 }}
                  >
                    <img
                      src={Docs1}
                      style={{
                        height: "20px",
                        width: "20px",
                        margin: "0px",
                      }}
                      alt=""
                    />
                    <Typography
                      fontSize={14}
                      color={"#454F5B"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      fontWeight={500}
                    >
                      {" "}
                      Choose PAN Card
                    </Typography>
                  </Stack>{" "}
                </Item>
              </Grid>
              <Grid xs>
                <Item>
                  {/* <Stack display={"flex"} direction={"row"} spacing={0}> */}
                  {PanFlie1 ? (
                    <LinearDeterminate />
                  ) : (
                    <>
                      {!PanFlie1BtnDisabled ? (
                        <Button
                          component="label"
                          // variant="contained"
                          disabled={PanFlie1BtnDisabled}
                          size="small"
                          sx={{
                            border: "1px solid grey",
                            height: "25px",
                            color: "black",
                            px: 2,
                            fontFamily: "Public Sans",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<FileUploadIcon sx={{ mt: 0 }} />}
                        >
                          Upload
                          <VisuallyHiddenInput
                            name="aadharFileUrl"
                            onChange={handleFileUpload}
                            sx={{ px: 2 }}
                            type="file"
                          />
                        </Button>
                      ) : (
                        <Stack direction="row">
                          <>
                            <DoneIcon
                              style={{ fontSize: "12px", color: "#36B37E" }}
                            />{" "}
                            <Typography
                              fontWeight={700}
                              fontSize={10}
                              fontFamily={"Public Sans"}
                              color={"#36B37E"}
                            >
                              {" "}
                              File upload Successfully
                            </Typography>
                          </>
                        </Stack>
                      )}
                    </>
                  )}
                </Item>
                <Item>
                  {/* <Stack display={"flex"} direction={"row"} spacing={0}> */}
                  {PanFlie2 ? (
                    <LinearDeterminate />
                  ) : (
                    <>
                      {!PanFlie2BtnDisabled ? (
                        <Button
                          component="label"
                          // variant="contained"
                          disabled={PanFlie2BtnDisabled}
                          size="small"
                          sx={{
                            border: "1px solid grey",
                            height: "25px",
                            color: "black",
                            px: 2,
                            fontFamily: "Public Sans",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<FileUploadIcon sx={{ mt: 0 }} />}
                        >
                          Upload
                          <VisuallyHiddenInput
                            name="aadharBackUrl"
                            onChange={handleFileUpload}
                            sx={{ px: 2 }}
                            type="file"
                          />
                        </Button>
                      ) : (
                        <Stack direction="row">
                          <>
                            <DoneIcon
                              style={{ fontSize: "12px", color: "#36B37E" }}
                            />{" "}
                            <Typography
                              fontWeight={700}
                              fontSize={10}
                              fontFamily={"Public Sans"}
                              color={"#36B37E"}
                            >
                              {" "}
                              File upload Successfully
                            </Typography>
                          </>
                        </Stack>
                      )}
                    </>
                  )}
                </Item>
                <Item>
                  {/* <Stack display={"flex"} direction={"row"} spacing={0}> */}
                  {PanFlie3 ? (
                    <LinearDeterminate />
                  ) : (
                    <>
                      {!PanFlie3BtnDisabled ? (
                        <Button
                          component="label"
                          // variant="contained"
                          disabled={PanFlie3BtnDisabled}
                          size="small"
                          sx={{
                            border: "1px solid grey",
                            height: "25px",
                            color: "black",
                            px: 2,
                            fontFamily: "Public Sans",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<FileUploadIcon sx={{ mt: 0 }} />}
                        >
                          Upload
                          <VisuallyHiddenInput
                            name="PANFile"
                            onChange={handleFileUpload}
                            sx={{ px: 2 }}
                            type="file"
                          />
                        </Button>
                      ) : (
                        <Stack direction="row">
                          <>
                            <DoneIcon
                              style={{ fontSize: "12px", color: "#36B37E" }}
                            />{" "}
                            <Typography
                              fontWeight={700}
                              fontSize={10}
                              fontFamily={"Public Sans"}
                              color={"#36B37E"}
                            >
                              {" "}
                              File upload Successfully
                            </Typography>
                          </>
                        </Stack>
                      )}
                    </>
                  )}
                </Item>
              </Grid>
            </Grid>
            <Stack display={"flex"} direction={"row"} sx={{ mt: 8, py: 3 }}>
              <Button
                size="small"
                disabled={
                  !PanFlie1BtnDisabled ||
                  !PanFlie2BtnDisabled ||
                  !PanFlie3BtnDisabled
                }
                onClick={handleOnClickfileUploadSuccess}
                sx={{
                  textTransform: "none",
                  px: 3,
                  py: 1,
                  backgroundColor:
                    !PanFlie1BtnDisabled ||
                    !PanFlie2BtnDisabled ||
                    !PanFlie3BtnDisabled
                      ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                      : "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  color:
                    !PanFlie1BtnDisabled ||
                    !PanFlie2BtnDisabled ||
                    !PanFlie3BtnDisabled
                      ? "black"
                      : "white",
                  "&:hover": {
                    backgroundColor:
                      !PanFlie1BtnDisabled ||
                      !PanFlie2BtnDisabled ||
                      !PanFlie3BtnDisabled
                        ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                        : "#303981",
                  },
                  fontSize: "12px",
                }}
                color="inherit"
              >
                Continue
              </Button>
            </Stack>
          </Box>
          {/* Gst Verify Check for Button and Success Msg showing after successfull gst verify check */}
        </Box>
      </Container>
    </Box>
  );
};

export default PanUploadFiles;
