// Create User Gst - onBoarding
export const BusinessType = [
  "Individual",
  "Limited Liability Partnership (LLP)",
  "One Person Company",
  "Partnership",
  "Private Limited Company",
  "Sole Proprietorship",
];

// All state -
export const State = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu",
  "Kashmir",
  "Daman",
  "Diu",
];

export const identifierType = [
  { value: "orders", label: "ORDERS" },
  { value: "paymentRequest", label: "INVOICE" },
  { value: "invoice", label: "PAYMENT" },
];
