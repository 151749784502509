import ApprovalSVG from "../../assets/illustration_upload.svg";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import ApiService from "../../api/api";

const ReuploadRequest = () => {
  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.user.Token);
  const Latitude = useSelector(
    (state: any) => state.user.LocationInfo.latitude
  );
  const Longitude = useSelector(
    (state: any) => state.user.LocationInfo.longitude
  );
  const Accuracy = useSelector(
    (state: any) => state.user.LocationInfo.accuracy
  );
  const TimeStamp = useSelector(
    (state: any) => state.user.LocationInfo.timestamp
  );

  const [verifyLoading, setverifyLoading] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const ClearRejectDoucs = async () => {
    setbtnDisabled(true);
    setverifyLoading(true);
    let body = {
      timestamp: TimeStamp,
      latitude: Latitude,
      longitude: Longitude,
      accuracy: Accuracy,
    };
    const token = await ApiService.setToken(newToken);
    const dashboardData: any = await ApiService.post(
      `/userkyc/kycRedoRequested`,
      body
    );
    if (dashboardData.status === 200) {
      setverifyLoading(false);
      SuccessMsg("Reupload request created successfully");
      navigate("/auth/reupload");
    } else {
      console.log("Reupload Error ---", dashboardData);
      setverifyLoading(false);
      setbtnDisabled(false);
      ErrorMsg("Something went wrong, Please try again");
    }
  };

  return (
    <Box margin={2} sx={{ flexGrow: 1 }}>
      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box justifyContent={"center"} textAlign={"center"} sx={{ mt: 4 }}>
          <img src={ApprovalSVG} alt="" />
          <Typography
            sx={{
              fontSize: "20px",
              mt: 2,
              color: "Black",
              fontWeight: 700,
              fontFamily: "Public Sans",
            }}
          >
            {" "}
            Your KYC has been rejected!
          </Typography>
          <Box justifyContent={"center"} mt={1}>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "Black",
                  fontWeight: 500,
                  fontFamily: "Public Sans",
                }}
              >
                {" "}
                To proceed with the verification process, kindly re-upload the
                necessary documents ensuring they meet the specified
                requirements.
              </Typography>
            </Box>

            <Box mx={2} my={6}>
              <LoadingButton
                size="medium"
                loading={verifyLoading}
                disabled={btnDisabled}
                sx={{
                  px: 3,
                  "&:hover": {
                    backgroundColor: btnDisabled
                      ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                      : "#303981",
                  },
                }}
                onClick={ClearRejectDoucs}
                style={{
                  textTransform: "none",
                  backgroundColor: btnDisabled
                    ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                    : "#303981",
                  color: btnDisabled ? "" : "white",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "700",

                  // height: "4vh",
                }}
              >
                Go To Upload
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ReuploadRequest;
