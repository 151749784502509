import React from "react";
import OrderDetails from "./OrderDetails";
import BuisnessDetails from "./BuisnessDetails";
import InterestDetailsData from "../Reusable/InterestDetailsData";
import { Box } from "@mui/system";
import InterestTableList from "../ViewOrders/InProcess/InterestTableList";

const View = (props: any) => {
  const orderDetails = props ? props.props : {};
  return (
    <>
      {/*  Order Details*/}
      <OrderDetails props={orderDetails} />
      {/* Business Details */}
      <BuisnessDetails props={orderDetails} />
      {/* Blocked or Used Bank Balance AND Receivables Balance */}
      <InterestDetailsData
        props={orderDetails}
        Text1={"Balance Used"}
        Text2={"Receivables Used"}
      />
      {/* Table list details of all milestones And their Interest Details */}

      <Box>
        <InterestTableList
          TableText={"Payment Configure Details"}
          props={orderDetails}
        />
      </Box>
    </>
  );
};

export default View;
