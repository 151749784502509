// These are the protected Routes pages for handling or providing safe accessibility for applications
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";

const ProtectedRoutes = () => {
  const newToken = useSelector((state: any) => state.user.Token);
  if (newToken !== "" && newToken !== null && newToken !== undefined) {
    return (
      <div>
        <Header />
        <Outlet />
        <Footer />
      </div>
    );
  } else {
    //   if user have to redirect to login page if tokens not provided
    return <Navigate to={"/login"} />;
  }
};

export default ProtectedRoutes;
