import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import { Box, Chip, Stack, Typography, Popover, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  PaymentType,
  StringDateConversion,
  numberFormat,
} from "../../../utils/Validations";
import "../../../App.css";
import NoRecordFound from "../../../components/NoRecordFound";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";

export default function ApproveRequestList(props: any) {
  const navigate = useNavigate();
  const AcceptPaymentLists = props ? props.props : [];

  const handleViewRequestOnClick = (item: any) => {
    console.log("chekc dalalala ", item);
    navigate("/dashboard/acceptrequest/order", {
      state: { orderDetails: item },
    });
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
            <TableCell
              sx={{
                borderBottom: "none",
                px: 4,
              }}
            >
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Date
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Order ID
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Business Name
              </Typography>
            </TableCell>
            {/* <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Receiving Date
              </Typography>
            </TableCell> */}
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Amount
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Payment Type
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Actions
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {AcceptPaymentLists.length > 0 &&
            AcceptPaymentLists.map((item: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      px: 3,
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={600}
                    >
                      {item && item.proposalCreatedDate !== ""
                        ? StringDateConversion(item.proposalCreatedDate)
                        : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={500}
                    >
                      {item.orderID ? item.orderID : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#303981"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={600}
                    >
                      {item.seller_name !== "" ? item.seller_name : "N/A"}{" "}
                    </Typography>
                  </TableCell>

                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      // px: 3,
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"green"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={600}
                    >
                      {item.orderAmount
                        ? numberFormat(item.orderAmount)
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={fontFamilys.publicSans}
                    >
                      {PaymentType(item.paymentType)}
                    </Typography>
                  </TableCell>
                  {item.status === "approved by checker" ? (
                    <>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => handleViewRequestOnClick(item)}
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Public Sans",
                            backgroundColor: "",
                            color: "var(--text-light-primary, #212B36)",
                            borderRadius: "8px",
                            fontWeight: 700,

                            fontStyle: "normal",
                            border: "1px solid  rgba(145, 158, 171, 0.32)",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#36B37E",
                              color: "white",
                            },
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        N/A
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {AcceptPaymentLists.length <= 0 ? (
        <NoRecordFound text1={"Accept Payments"} text2={"accept payments"} />
      ) : (
        ""
      )}
    </>
    // </TableContainer>
  );
}
