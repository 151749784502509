import { enqueueSnackbar } from "notistack";

export const SuccessMsg = (value: any) => {
  return enqueueSnackbar(
    `${value}`,
    // "Verification Codes has been sent to your registered Email & Mobile Number",
    {
      variant: "success",
      style: {
        color: " var(--success-darker, #0A5554)",
        backgroundColor: "var(--success-lighter, #D8FBDE)",
        marginRight: "100px",
        fontFamily: "Public Sans",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22px",
        maxWidth: "400px",
        margin: "50px 0 0 0",
      },
    }
  );
};
