//  General Mixing - As per reusable code and structure for ui / code / feature

// UI -
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

// Code Functions -
const numberTextFieldValidation = () => {
  console.log("hiii this is the value payment");
  return "hii";
};
// Export Elements -
export { MenuProps };

export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
