import Logo from "../assets/LogoSvg.svg";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import { fIndianCurrency } from "../utils/Validations";
import Label from "./Label/Label";
import { useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import { dispatch } from "../redux/config";
import {
  arrangeReceivableState,
  clearToken,
  loginUser,
} from "../redux/slices/user";
import { useDispatch } from "react-redux";

const Header = (props: any) => {
  // Navigate & locate
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // Identify path
  const path = location.pathname;
  const login = useSelector((state: any) => state.user.userLogin);
  const collapsed = props.collapsed;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleAvatarClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const walletStyle = {
    textTransform: "capitalize",
    borderColor: "primary",
    borderRadius: 8,
    borderWidth: "2px",
    borderStyle: "solid",
  };
  const UserProfilePhoto = useSelector((state: any) =>
    state.user ? state.user.receivableState : ""
  );
  const handleClearToken: any = () => {
    dispatch(loginUser(false));
    dispatch(arrangeReceivableState(""));
    dispatch(clearToken(""));
    navigate("/login");
    console.log("navigate");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          boxShadow:
            "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
        }}
      >
        <Toolbar sx={{px:{xs:"0",sm:"20px"}}}>
          <div style={{ flexGrow: 1 }}>
            {collapsed === undefined ? <img src={Logo} alt="" /> : "" }
          </div>
          <div>
            {path === "/login" || path === "/forget" || path === "/verify" ? (
              <Link to={"/"}>
                <Button
                  size="small"
                  color="inherit"
                  sx={{ px: 4 }}
                  style={{
                    maxWidth: "320px",
                    textTransform: "none",
                    fontSize: 15,
                    borderRadius: "8px",
                    backgroundColor: "rgba(145, 158, 171, 0.24)",
                    fontFamily: "Public Sans",
                    fontWeight: "700",
                    color: "black",
                  }}
                  fullWidth
                >
                  Sign Up
                </Button>
              </Link>
            ) : (
              <>
                <Stack direction={"row"} spacing={2} alignItems={"center"}
                 sx={{
                  justifyContent: { xs: 'flex-start', md: 'center' },
                }}>
                  {props.showWallet ? (
                    <>
                      <Stack pt={1} spacing={1} direction={{xs:"column",md:"row"}}>
                        <Label
                          variant="soft"
                          color={"primary"}
                          sx={walletStyle}
                        >
                          AP Balance :{" "}
                          {fIndianCurrency(
                            props.BankBalanceData &&
                              props.BankBalanceData.availableBalance
                          ) || "₹" + " " + 0}
                        </Label>
                        <Label
                          variant="soft"
                          color={"secondary"}
                          sx={walletStyle}
                        >
                          Locked :{" "}
                          {fIndianCurrency(
                            props.BankBalanceData &&
                              props.BankBalanceData.lockedBalance
                          ) || "₹" + " " + 0}
                        </Label>
                        <Label
                          variant="soft"
                          color={"warning"}
                          sx={walletStyle}
                        >
                          Receivables :{" "}
                          {fIndianCurrency(
                            props.BankBalanceData &&
                              props.BankBalanceData.assuredRecievables
                          ) || "₹" + " " + 0}
                        </Label>
                      </Stack>
                    </>
                  ) : (
                    ""
                  )}
                  {/* <>
                    {!login ? (
                      <LoginIcon
                        sx={{ color: "#303981" }}
                        onClick={handleAvatarClick}
                      />
                    ) : (
                      <Avatar
                        src={UserProfilePhoto?.toString()}
                        onClick={handleAvatarClick}
                        alt=""
                      />
                    )}

                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      sx={{
                        borderRadius: "12px",
                        mt: 1,
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "12px",
                          px: 2,
                        }}
                      >
                        {!login ? (
                          <Typography
                            variant="body1"
                            fontFamily={"Public Sans"}
                            fontWeight={600}
                            sx={{ py: 1, px: 2 }}
                            onClick={() => navigate("/login")}
                            style={{ cursor: "pointer" }}
                          >
                            Login
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            fontFamily={"Public Sans"}
                            fontWeight={600}
                            sx={{ py: 1, px: 2 }}
                            onClick={handleClearToken}
                            style={{ cursor: "pointer" }}
                          >
                            Logout
                          </Typography>
                        )}
                      </Box>
                    </Popover>
                  </> */}
                  {!login ? (
                    <Link to={"/login"}>
                      <Button
                        size="small"
                        color="inherit"
                        sx={{ px: 4 }}
                        style={{
                          maxWidth: "320px",
                          maxHeight:"40px",
                          textTransform: "none",
                          fontSize: 15,
                          borderRadius: "8px",
                          backgroundColor: "rgba(145, 158, 171, 0.24)",
                          fontFamily: "Public Sans",
                          fontWeight: "700",
                          color: "black",
                        }}
                        fullWidth
                      >
                        Login
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      size="small"
                      color="inherit"
                      sx={{ px: 4 }}
                      onClick={handleClearToken}
                      style={{
                        maxWidth: "320px",
                        maxHeight:"40px",
                        textTransform: "none",
                        fontSize: 15,
                        borderRadius: "8px",
                        backgroundColor: "rgba(145, 158, 171, 0.24)",
                        fontFamily: "Public Sans",
                        fontWeight: "700",
                        color: "black",
                      }}
                      fullWidth
                    >
                      Logout
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
