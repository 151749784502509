import { Box, Stack, Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CustomizedSteppers from "../../components/Stepper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LinearDeterminate from "../../components/ProgressBar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";

type DocumentType =
  | "AOA"
  | "AOF"
  | "Board Resolution"
  | "MOA"
  | "Present List Of Directors"
  | "MOP"
  | "Company PAN"
  | "Certificate Of Incorporation"
  | "KYC Authorised Signatory Or Partner"
  | "NTB Declaration"
  | "Entity KYC"
  | "Approval"
  | "Escrow RERA And Lender Details"
  | "Benificial Ownership Declaration"
  | "Present List Of Partners"
  | "Address Proof"
  | "KYC"
  | "Business License"
  | "KYC Authorised"
  | "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed"
  | "Proprietor PAN"
  | "Firm Proof"
  | "Certificate License As Per Naure Of Business Obtained"
  | "Address Proof Prop"
  | "Mandate Holder (if Any)"
  | "KYC Of Proprietor"
  | "Entity KYC Of Propriter";

const CreateBusinessProfile = () => {
  const BaseUrl = "https://dev.api.assuredpay.in";
  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.user.Token);
  const userRole = localStorage.getItem("userRole") || "";

  const [files, setFiles] = useState<Record<DocumentType, File | null>>({
    AOA: null,
    AOF: null,
    "Board Resolution": null,
    MOA: null,
    "Present List Of Directors": null,
    MOP: null,
    "Company PAN": null,
    "Certificate Of Incorporation": null,
    "KYC Authorised Signatory Or Partner": null,
    "NTB Declaration": null,
    "Entity KYC": null,
    Approval: null,
    "Escrow RERA And Lender Details": null,
    "Benificial Ownership Declaration": null,
    "Present List Of Partners": null,
    "Address Proof": null,
    KYC: null,
    "Business License": null,
    "KYC Authorised": null,
    "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed":
      null,
    "Proprietor PAN": null,
    "Firm Proof": null,
    "Certificate License As Per Naure Of Business Obtained": null,
    "Address Proof Prop": null,
    "Mandate Holder (if Any)": null,
    "KYC Of Proprietor": null,
    "Entity KYC Of Propriter": null,
  });
  const [fileErrors, setFileErrors] = useState<
    Record<DocumentType, string | null>
  >({
    AOA: null,
    AOF: null,
    "Board Resolution": null,
    MOA: null,
    "Present List Of Directors": null,
    MOP: null,
    "Company PAN": null,
    "Certificate Of Incorporation": null,
    "KYC Authorised Signatory Or Partner": null,
    "NTB Declaration": null,
    "Entity KYC": null,
    Approval: null,
    "Escrow RERA And Lender Details": null,
    "Benificial Ownership Declaration": null,
    "Present List Of Partners": null,
    "Address Proof": null,
    KYC: null,
    "Business License": null,
    "KYC Authorised": null,
    "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed":
      null,
    "Proprietor PAN": null,
    "Firm Proof": null,
    "Certificate License As Per Naure Of Business Obtained": null,
    "Address Proof Prop": null,
    "Mandate Holder (if Any)": null,
    "KYC Of Proprietor": null,
    "Entity KYC Of Propriter": null,
  });

  const [uploading, setUploading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files || files.length === 0) {
      setErrorMessage("No file selected.");
      return;
    }

    const file = files[0];
    const MAX_SIZE = 10 * 1024 * 1024;
    if (file.size > MAX_SIZE) {
      setErrorMessage("File size exceeds 10 MB. Please upload a smaller file.");
      return;
    }

    setUploading(true);
    setErrorMessage("");

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: newToken !== "" ? newToken : "",
      },
    };

    try {
      const formData = new FormData();
      formData.append("document", file);

      const res = await axios.post(
        `${BaseUrl}/uploadRoutes/uploaddoc?filename=GSTFILE`,
        formData,
        axiosConfig
      );

      if (res.status === 200) {
        setFiles((prev) => ({ ...prev, [e.target.name]: file }));
        setBtnDisabled(false);
      }
    } catch (err) {
      console.log(err, "catch Method");
      setErrorMessage("File upload failed. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleOnClickContinue = () => {
    navigate("/auth/verifyaadhar");
  };

  const documentDescription: { [key in DocumentType]: string } = {
    AOA: "Signed and stamped by authorised signatory / director/ CS on the first 3 pages and last 3 pages of AOA.",
    AOF: `Dedupe to check if the entity and authorised signatory have an existing customer ID.
    Verify that the account title matches with PAN, MCA copy, MOA/AOA, COI, and Address proof of the company.
    Ensure registered and communication addresses match with the provided address proof.
    Confirm that the nature of business on the AOF matches with the MOA.
    Additional information such as line of business, type of entity, nature of industry, annual turnover, expected monthly transaction, cash/deposit transactions (in lakhs), and number of employees must be provided.
    Key contact person details (Name, Contact Number, Email ID) should be included on the AOF.
    PAN number and registration date (from MCA copy/PAN copy) must match the AOF.
    Place of registration and GST number should be mentioned on the form.
    Existing credit facility should be detailed in the CA annexure and verified with the CRILIC report.
    OVD and PAN details of authorized signatory/ies must match with the AOF.
    Signature of authorised signatories is required along with the entity’s stamp.
    For existing customer IDs, ensure the signature matches the system records.
    Internet declaration is required only if the internet clause is mentioned in the board resolution.
    If no COL access is available, approval is needed as per the delegation matrix.
    FATCA page on AOF (page 5) must be fully filled out.
    Signature and entity stamp are required as per the details in the Board resolution on the AOF.
    Any overwriting on the AOF must be counter-signed by the client with the entity stamp.
    Sourcing RM’s name, ECN, segment, and RM signature should be provided on AOF (page 4).`,
    MOA: "Signed and stamped by authorised signatory / director/ CS on the first 3 pages and last 3 pages of MOA.",
    "Company PAN": "PAN card of the firm.",
    "Certificate Of Incorporation":
      "Signed and stamped by authorised signatory / director/company secretary.",
    "Address Proof":
      "Address proof of the entity (registered and communication). Principal place of business if different from registered address, to specify in AOF and proof of the same to be obtained. Documentary proof as specified in Table 2A shall be required. Signed and stamped by authorised signatory / director/company secretary.",
    KYC: `• Entity kyc is attested with entity for stamp
          • OSV with RM certification to be done on all kyc documents of Company and Authorised signatories, Beneficiary and Identity/Signature of director/Company who is signing the board resolution.`,
    "Business License":
      "Certificate / license as per nature of business obtained.",
    "Board Resolution": `• Date of the board resolution should be less than 6 months. If more than 6 months, the client can mention on the last page of the board resolution as "certified true and updated copy as on ______________" with attestation from the company secretary or two directors.
    • Account title on the board resolution matches with AOF.
    • Branch name and type of account should be mentioned on the board resolution.
    • Number of authorized signatories mentioned on the board resolution matches with the AOF copy.
    • Company declaration is required if the board resolution is not provided in Appendix C format.
    • Mode of operation should be uniform throughout the board resolution.
    • Board resolution must be provided on company letterhead, stamped & signed by 2 directors or by the company secretary. ID with signature proof of the official signing the board resolution is required (all pages to be signed).
    • The board resolution can be signed singly by a director or company secretary, provided they are not the authorized signatory/ies.
    • ID with signature proof of the official signing the board resolution is mandatory if they are not an authorized signatory.`,
    MOP: `
• Authroised signatory part of MOP should not have any validity date (eg: signature valid till XX date) 
• Authorized signatory details should carry full name (designation is not mandatory)
• Authorized signatory should not be defined on the basis of designation
• MOP should be very clear like single signatory or joint signatory
• BR must clearly mention it’s for “Opening account” and/or “Operation of account”.
• OVD for Signature proof of signatories as per BR and signatories signing BR must be provided .
`,
    "Present List Of Directors": `• List of directors provided on company letterhead matches with list downloaded from mca website - to be signed and stamped by at least 2 directors or company secretary
    • List of present directors as downloaded from mca site signed by at least one director can be accepted since the list of directors is being verified at branches on mca site 
    • The names and address of the relevant persons holding senior management position in the company 
    (Applicable to all - Listed and Unlisted companies).
     `,
    "Benificial Ownership Declaration": `• Ownership of/entitlement to more than 10% of share capital of the juridical person, where the juridical person is a company. Self attested KYC (OVD and Photograph) of person who owns 10% and more share holding in Company.
    • Beneficial ownership declaration obtained in annexure 3 format  - signed and stamped by 2 directors or company secretary.
     if benefical owner is a non-individual entity, details of ultimate natural beneficiary/ senior most official is mentioned on bo declaration . Self-attested KYC (OVD and Photograph) of senior most official required 
    • Beneficial ownership declaration is not required if company listed on a recognized stock exchange. If the Beneficiary company / Entity is listed in Recognized Stock exchange than the BO declaration is required, however the Ultimate beneficiary details is not required.
    • The names and address of the relevant persons holding senior management position in the company 
    (Applicable to all - Listed and Unlisted companies).`,
    "NTB Declaration":
      "To be taken on letter head with exposure details- signed and stamped as per mop /cs",
    "Entity KYC": `• Entity kyc is attested with entity for stamp.
 • OSV with RM certification to be done on all kyc documents of Company and Authorised signatories, Beneficiary and Identity/Signature of director/Company who is signing the board resolution.`,
    Approval: "Approvals as per deviation / deferral matrix attached if any",
    "Escrow RERA And Lender Details": `• Account title to be mentioned as per GBO mail and  escrow agreement on account opening form 
    • Account Title and Account number should be filled in Agreement
• Internal AOF to be filled by RM 
• All mandatory (*) information under is entered on Two pager account opening form                                                                Duly filled AOF by All parties 
• Copy of BR / Authority Letter with specimen signatures of Lender’s Agent / Security Trustee
• KYC of Lender’s Agent / Security Trustee as per constitution  (mention a/c number / CIF if any of the party is an existing customer)
• KYC of the authorised signatoris of Lender’s Agent / Security Trustee obtained(if existing a/c holder, pl
specify a/c number for KYC reference)
• Copy of duly executed Escrow agreement signed by all parties 
• Duly executed Escrow agreement. Escrow Agreement to be executed as specified by GBO basis stamp duty tracker. 
• POA of IBL staff who has signed the Escrow agreement on behalf of Indusind Bank
• Agreement date to be same as of stamp date or later than stamp date / franking date                                             Execution date should be within 6 months from Stamp paper purchase date
• All blanks are filled in the agreement .                                                                                                                                                         No pages are missing or tampered in the agreement                                                                                                                                              Schedule 1 should be properly filled in the agreement
• Initials on all pages by Escrow parties and complete signature required on signature pages along with kyc proof who has executed the Escrow Agreement. 
• Custody letter from Trustee/LA/Lender along with a True Certified copy of the agreement from the relevant Trustee/LA/Lender
• Email indemnity reqd or else confirm whether email indemnity clause mentioned on escrow agreement, confirm page no/point no
• In IBL Lending case, BU to kindly confirm the approving authority (i.e. CAD/RE/CFD Zone) in order to updated the same in Capiot deal.. kindly confirm approving authority
• Project name should match with RERA Certificate.                                                                                                                            Rera Certifcate to be taken and signed stamped by authorised signatory . Rere Certificate expiry date to be checked 
• Account creation sheet annexure 5 is provided by RM (RERA and Collection both)
• RERA letter to be taken with Account Title and Account number
• Refer GBO guidelines`,
    "KYC Authorised Signatory Or Partner": `• Self-attested PAN and OVD – proof of ID and address of all partners / authorised signatories/ person holding an attorney to transact on its behalf."
• Appendix s along with approval as specified in KYC deviation deferral matrix required if signatory/ies self-attestation differs with given kyc as per delegation matrix  "
• Latest colour photograph of all authorized signatory obtained (to be signed across)`,
    "Present List Of Partners":
      "Present list of partners to be obtained with name and address  - to be signed and stamped by 2 partners",
    "KYC Authorised":
      "Self-attested PAN and OVD – proof of ID and address of all partners / authorised signatories/ person holding an attorney to transact on its behalf.",
    "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed": `• Date of execution of partnership agreement is not before the purchase date of the stamp paper
• Registered partnership deed - signed and stamped by authorised signatory on all pages as per mop 
• Unregistred partnership deed - notarized partnership deed need to be attested by at least one partner along with entity stamp or copy of deed must bear attestation of all partners at least on last page under firm’s rubber stamp (for capacity) and all pages must be originally seen & verified by bank official.`,
    "Proprietor PAN": "Signed by proprietor",
    "Firm Proof": `• Two valid existence proof required - to be signed and stamped by proprietor. Refer table 2a 
• Two existence proofs are mandatory – in case of non-availability of the second proof, obtain any one document from table 2 a and obtain any one document from table2 b,  a customer declaration should be obtained confirming reason for inability to provide second existence proof. (appendix d) (for entity less than one year) along with fv report 
• The fv will be done by an external agency appointed by the bank and where the external agency is not available, a bank official  rm (other than sourcing executive) will provide the fv report 
• Fvr obtained along with appendix d and approved by rm other than sourcing executive
• At least one existence proof to have name of proprietor.`,
    "Certificate License As Per Naure Of Business Obtained":
      "Signed and stamped by proprietor",
    "Address Proof Prop": `• Valid communication proof obtained signed and stamped by proprietor
• Resi cum office declaration obtained if communication address and proprietor’s address is same`,
    "Mandate Holder (if Any)":
      "Mandate letter is signed as per the banks format in case account is getting operated by mandate holder appendix r.",
    "KYC Of Proprietor": `• Self-attested id proof & address proof of proprietor /authorized signatory/ies obtained.
• Appendix s along with approval as specified in KYC deviation deferral matrix required if signatory/ies self-attestation differs with given kyc as per delegation matrix.
• Latest photograph of proprietor /authorized signatory/ies obtained (to be signed across)`,
    "Entity KYC Of Propriter": `• Entity kyc is attested with entity for stamp
• Osv done on all kyc documents`,
  };

  const roleDocumentKeys: { [key: string]: DocumentType[] } = {
    privateLimited: [
      "AOA",
      "MOA",
      "Company PAN",
      "Certificate Of Incorporation",
      "Address Proof",
      "KYC",
      "Entity KYC",
    ],
    company: [
      "AOA",
      "AOF",
      "MOA",
      "MOP",
      "Board Resolution",
      "Present List Of Directors",
      "Company PAN",
      "Certificate Of Incorporation",
      "NTB Declaration",
      "Benificial Ownership Declaration",
      "Escrow RERA And Lender Details",
      "KYC Authorised Signatory Or Partner",
      "Address Proof",
      "KYC",
      "Entity KYC",
      "Approval",
      "Business License",
    ],
    partnership: [
      "Company PAN",
      "Certificate Of Incorporation",
      "Present List Of Partners",
      "KYC Authorised Signatory Or Partner",
      "Address Proof",
      "KYC Authorised",
      "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed",
    ],
    proprietor: [
      "Proprietor PAN",
      "Firm Proof",
      "Certificate License As Per Naure Of Business Obtained",
      "Address Proof Prop",
      "Mandate Holder (if Any)",
      "KYC Of Proprietor",
      "Entity KYC Of Propriter",
    ],
  };

  const documentKeysForRole = roleDocumentKeys[userRole] || [];
  const filteredDocumentKeysForRole = documentKeysForRole.filter((docKey) => {
    if (userRole === "company") {
      return [
        "AOA",
        "AOF",
        "MOA",
        "MOP",
        "Board Resolution",
        "Present List Of Directors",
        "Company PAN",
        "Certificate Of Incorporation",
        "NTB Declaration",
        "Benificial Ownership Declaration",
        "Escrow RERA And Lender Details",
        "KYC Authorised Signatory Or Partner",
        "Address Proof",
        "KYC",
        "Entity KYC",
        "Approval",
        "Business License",
      ].includes(docKey);
    }
    if (userRole === "private limited") {
      return [
        "AOA",
        "MOA",
        "Company PAN",
        "Certificate Of Incorporation",
        "Address Proof",
        "KYC",
        "Entity KYC",
      ].includes(docKey);
    }
    if (userRole === "partnership") {
      return [
        "Company PAN",
        "Certificate Of Incorporation",
        "Present List Of Partners",
        "KYC Authorised Signatory Or Partner",
        "Address Proof",
        "KYC Authorised",
        "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed",
      ].includes(docKey);
    }
    if (userRole === "proprietor") {
      return [
        "Proprietor PAN",
        "Firm Proof",
        "Certificate License As Per Naure Of Business Obtained",
        "Address Proof Prop",
        "Mandate Holder (if Any)",
        "KYC Of Proprietor",
        "Entity KYC Of Propriter",
      ].includes(docKey);
    }
    return false;
  });

  return (
    <Box margin={1} sx={{ flexGrow: 1 }}>
      <Box textAlign={"center"}>
        <Stack sx={{ px: "100px" }}>
          <CustomizedSteppers activeStep={1} />
        </Stack>
      </Box>

      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box
          boxShadow={"1"}
          justifyContent={"center"}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            py: 2,
            pb: 4,
          }}
        >
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ fontSize: "12px" }}
          >
            <Grid sx={{ order: { xs: 12, sm: 2 }, mt: 4, mb: 2 }}>
              <Typography
                component="div"
                fontFamily={"Public Sans"}
                fontSize={20}
                fontWeight={700}
                lineHeight={"30px"}
                color={"#212B36"}
              >
                Create Your Business Profile
              </Typography>
            </Grid>
          </Grid>

          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                mb: 5,
                color: "#556486",
                fontWeight: 600,
                fontFamily: "Public Sans",
                fontStyle: "normal",
              }}
            >
              List of KYC documents for {userRole}
            </Typography>
          </Box>

          <Box padding={0}>
            {filteredDocumentKeysForRole.map((docKey) => (
              <Grid container key={docKey} sx={{ mb: 2 }}>
                <Item sx={{ width: "90%" }}>
                  <Stack display={"flex"} direction={"row"} spacing={5}>
                    <Stack
                      sx={{
                        background: "#454F5B",
                        height: "25px",
                        width: "25px",
                        padding: "5px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        fontSize={20}
                        fontWeight={700}
                        color={"white"}
                        sx={{
                          height: "40px",
                          width: "25px",
                          mt: "-2px",
                        }}
                      >
                        {filteredDocumentKeysForRole.indexOf(docKey) + 1}
                      </Typography>
                    </Stack>

                    <Stack>
                      <Typography
                        fontSize={16}
                        color={"#454F5B"}
                        fontStyle={"normal"}
                        fontFamily={"Public Sans"}
                        fontWeight={600}
                      >
                        {docKey.replace(/([''])/g, " $1").trim()}
                      </Typography>
                      <Typography variant="body2" color={"#adacac"}>
                        {docKey === "AOF" ? (
                          <ul style={{ paddingLeft: "20px", margin: "0" }}>
                            {documentDescription.AOF.split("\n")
                              .filter((item) => item.trim())
                              .map((item, index) => (
                                <li
                                  key={index}
                                  style={{ listStyleType: "disc" }}
                                >
                                  {item.trim()}
                                </li>
                              ))}
                          </ul>
                        ) : (
                          <div>
                            {documentDescription[docKey]
                              .split("\n")
                              .map((line, index) => (
                                <p key={index}>{line.trim()}</p>
                              ))}
                          </div>
                        )}
                      </Typography>

                    {fileErrors[docKey] && (
                        <Typography color="error" fontSize="12px">
                          {fileErrors[docKey]}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Item>
                <Grid sx={{ display: "flex", flexDirection: "column" }} xs>
                  <Item>
                    {uploading &&
                    filteredDocumentKeysForRole.includes(docKey) &&
                    !files[docKey] ? (
                      <LinearDeterminate />
                    ) : (
                      <>
                        {files[docKey] ? (
                          <Stack direction="row">
                            <DoneIcon
                              style={{ fontSize: "12px", color: "#36B37E" }}
                            />
                            <Typography
                              fontWeight={700}
                              fontSize={10}
                              fontFamily={"Public Sans"}
                              color={"#36B37E"}
                            >
                              File uploaded successfully
                            </Typography>
                          </Stack>
                        ) : (
                          <>
                            <Button
                              component="label"
                              size="small"
                              sx={{
                                border: "1px solid",
                                mb: 4,
                                color: "black",
                                px: 1,
                                fontFamily: "Public Sans",
                                fontSize: "14px",
                                textTransform: "none",
                              }}
                            >
                              Upload
                              <VisuallyHiddenInput
                              name={docKey}
                              onChange={handleFileUpload}
                              type="file"
                            />
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </Item>
                </Grid>
              </Grid>
            ))}

            <Stack display={"flex"} direction={"row"} sx={{ mt: 4 }}>
              <Button
                size="small"
                onClick={handleOnClickContinue}
                disabled={!!errorMessage || btnDisabled}
                sx={{
                  px: 2,
                  textTransform: "none",
                  backgroundColor:
                    errorMessage || btnDisabled
                      ? "rgba(145, 158, 171, 0.24)"
                      : "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  borderRadius: "8px",
                  color: errorMessage || btnDisabled ? "" : "white",
                  "&:hover": {
                    backgroundColor:
                      errorMessage || btnDisabled
                        ? "rgba(145, 158, 171, 0.24)"
                        : "#303981",
                  },
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateBusinessProfile;
