import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import PreClaim_Wallet_Y from "../../../../assets/PreClaim_Wallet_Y.svg";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import ModalDialog from "../../../../components/Dialog/ModelDialog";

const New_Preclaim = (props: any) => {
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);
  const modelTitle = "Model Title";
  const modelSubtitle =
    "This refers to the pre-claims that have been assured against your assured receivables.";
  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 1 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FB9E37",
            fontWeight: 700,
          }}
        >
          AssuredPay Pre-Claimed
          <img src={PreClaim_Wallet_Y} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Public Sans",
              color: "#FB9E37",
              fontSize: "9px",
            }}
          >
            Loan taken against my future receivables
          </Typography>
          {/* <InfoIcon sx={{ color: "#FB9E37" }} fontSize="small" />
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Public Sans",
              color: "#FB9E37",
              fontSize: "9px",
              textDecoration: "underline",
            }}
          >
            Know More
          </Typography> */}
          <InfoIcon
            sx={{ color: "#FB9E37", height: 25, width: 25 }}
            fontSize="small"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}

          />
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Public Sans",
              color: "#FB9E37",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}
          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={modelTitle}
            modelSubtitle={modelSubtitle}
            BgColor={"#FFFFFF"}
            TextColor={"#FB9E37"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FB9E37",
            fontWeight: 700,
          }}
        >
          0
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#FB9E37",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          NO PRE-CLAIMED RECEIVABLES
        </Typography>
      </Box>

      <Box my={2} mx={2}>
        <Stack direction={{xs:"column",md:"row"}} spacing={0.5}>
          <LoadingButton
            // onClick={() => navigate("/dashboard/requestpayments")}
            size="small"
            sx={{ px: 1 }}
            style={{
              textTransform: "none",
              backgroundColor: "#FB9E37",
              color: "#fff",
              borderRadius: "8px",
              fontSize: "11px",
              border: "1px solid #FB9E37",
              fontWeight: "600",
            }}
            type="submit"
          >
            Pending Pre-Claims
          </LoadingButton>
          <LoadingButton
            size="small"
            sx={{ px: 1 }}
            // whiteSpace: "noWrap"
            // onClick={() =>
            //   navigate("/dashboard/selectpayment", {
            //     state: { order: "buyer" },
            //   })
            // }
            style={{
              textTransform: "none",

              backgroundColor: "#FB9E37",
              color: "#fff",
              borderRadius: "8px",
              fontSize: "11px",
              border: "1px solid #FB9E37",
              fontWeight: "600",
            }}
            type="submit"
          >
            New Pre-Claim
            <Add fontSize="small" />
          </LoadingButton>
        </Stack>
      </Box>
    </Grid>
  );
};

export default New_Preclaim;
