import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Typography14 } from "../../../components/typography/Typography";

const PaymentActionButton = (props: any) => {
  // First Button (Redirect To Chat Only)
  const Button1TrueFalse = props ? props.Button1TrueFalse : false;
  const ButtonTextOne = props ? props.ButtonTextOne : "";
  const ButtonColor1 = props ? props.ButtonColor1 : "";

  // Second Button (Cancle Or Accept Or Configure(Create Pr Configure into Order))
  const ButtonTextTwo = props ? props.ButtonTextTwo : "";
  const ButtonColor2 = props ? props.ButtonColor2 : "";
  const Button2TrueFalse = props ? props.Button2TrueFalse : false;

  // Third Button (Reject Order Configure)
  const Button3TrueFalse = props ? props.Button3TrueFalse : false;
  const ButtonColor3 = props ? props.ButtonColor3 : "";
  const ButtonTextThree = props ? props.ButtonTextThree : "";

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} my={2}>
        <Stack
          display={"flex"}
          textAlign={"center"}
          justifyContent={"center"}
          direction={"row"}
          spacing={4}
        >
          {Button1TrueFalse ? (
            <Button
              size="medium"
              sx={{
                color: "white",
                borderRadius: "8px",
                px: 2,
                backgroundColor: `${ButtonColor1}`,
                textAlign: "right",
                fontFamily: "Public Sans",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: `${ButtonColor1}`,
                },
              }}
              onClick={props.RedirectToSellerChat}
            >
              <Typography14 color={"white"} text={ButtonTextOne}></Typography14>
            </Button>
          ) : (
            ""
          )}
          {Button2TrueFalse ? (
            <Button
              size="medium"
              onClick={props.openAcceptPayment}
              sx={{
                color: "white",
                borderRadius: "8px",
                px: 2,
                backgroundColor: `${ButtonColor2}`,
                textAlign: "right",
                fontFamily: "Public Sans",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: `${ButtonColor2}`,
                },
              }}
            >
              <Typography14 color={"white"} text={ButtonTextTwo}></Typography14>
            </Button>
          ) : (
            ""
          )}
          {Button3TrueFalse ? (
            <Button
              size="medium"
              onClick={props.openRejectPayment}
              sx={{
                color: "white",
                borderRadius: "8px",
                px: 2,
                backgroundColor: `${ButtonColor3}`,
                textAlign: "right",
                fontFamily: "Public Sans",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: `${ButtonColor3}`,
                },
              }}
            >
              <Typography14
                color={"white"}
                text={ButtonTextThree}
              ></Typography14>
            </Button>
          ) : (
            ""
          )}
        </Stack>
      </Box>
    </>
  );
};

export default PaymentActionButton;
