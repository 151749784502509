import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddtoBusinessNetworkSvg from "../../assets/AddtoBusinessNetworkSvg.svg";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import ApiService from "../../api/api";
import { useSelector } from "react-redux";
const Busi2 = () => {
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const newToken = useSelector((state: any) => state.user.Token);

  const [loading, setLoading] = useState(false);

  const [searchByValue, setsearchByValue] = useState("gst");
  const [businessNameList, setbusinessNameList] = useState<any>([]);
  const [industryNameList, setindustryNameList] = useState<any>([]);
  const [categoryNameList, setcategoryNameList] = useState<any>([]);
  const [productNameList, setproductNameList] = useState<any>([]);
  const [ShowProducts, setShowProducts] = useState<any>([]);
  const [selectBusinessSearchObj, setselectBusinessSearchObj] = useState<any>(
    {}
  );
  const [selectIndustrySearchObj, setselectIndustrySearchObj] = useState<any>(
    {}
  );
  const [selectCategorySearchObj, setselectCategorySearchObj] = useState<any>(
    {}
  );
  const [selectProductSearchObj, setselectProductSearchObj] = useState<any>([]);
  const [mainBtnDisabled, setmainBtnDisabled] = useState(true);

  const handleChangeSelectedProductsState = (value: any) => {
    setselectProductSearchObj(value);
  };
  console.log("selectedprodctsssss", selectProductSearchObj);

  const handleSearchState = (value: any) => {
    setsearchByValue(value);
  };
  const navigate = useNavigate();
  const dynamiclabelForSearch = (value: any) => {
    if (value === "gst") {
      return "Search By GST";
    } else if (value === "businessName") {
      return "Search By Business Name";
    } else if (value === "email") {
      return "Search By Email";
    } else {
      return "Search By Mobile";
    }
  };
  const HandleOnChangeIndustryList = async (e: any) => {
    let params = {
      searchKey: e.target.value,
    };
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/userkyc/getIndustry`,
      params
    );
    if (responseData.status === 200) {
      setindustryNameList(responseData.data.result);
    } else {
      console.log(responseData, "err get Industry");
    }
  };
  const HandleOnChangeProductList = async (e: any) => {
    let params;
    if (
      checkValueOfObject(selectCategorySearchObj) > 0 &&
      selectCategorySearchObj._id !== ""
    ) {
      params = {
        searchBy: e.target.value,
        categoryId: selectCategorySearchObj._id,
      };
    } else {
      params = {
        searchBy: e.target.value,
      };
    }
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/userkyc/getProduct`,
      params
    );
    if (responseData.status === 200) {
      setproductNameList(responseData.data.result);
    } else {
      console.log(responseData, "erroinProductselect");
    }
  };
  const HandleOnChangeCategoryList = async (e: any) => {
    setcategoryNameList([]);
    let params;

    if (
      checkValueOfObject(selectIndustrySearchObj) > 0 &&
      selectIndustrySearchObj._id !== ""
    ) {
      params = {
        searchBy: e.target.value,
        industryId: selectIndustrySearchObj._id,
      };
    } else {
      params = {
        searchBy: e.target.value,
      };
    }
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/userkyc/getcategory`,
      params
    );
    if (responseData.status === 200) {
      setcategoryNameList(responseData.data.result);
    } else {
      console.log(responseData, "err business network");
    }
  };
  const handleOnChangeForBusinessSearch = async (e: any) => {
    // let paramsData = {};
    let params = {};
    setLoading(true);
    if (searchByValue === "gst") {
      params = {
        gst: e.target.value,
      };
    } else if (searchByValue === "businessName") {
      params = {
        businessName: e.target.value,
      };
    } else if (searchByValue === "email") {
      params = {
        email: e.target.value,
      };
    } else if (searchByValue === "mobile") {
      params = {
        mobile: e.target.value,
      };
    } else {
      params = {};
    }

    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/userkyc/getbusinessDetails`,
      params
    );
    if (responseData.status === 200) {
      setLoading(false);
      setbusinessNameList(responseData.data.result);
    } else {
      setLoading(false);
      console.log(responseData);
    }
  };

  const HandleOnClickAddBusinessNetwork = async () => {
    let productsIdds = selectProductSearchObj.map((item: any) => {
      return item._id;
    });
    let body = {
      businessId: selectBusinessSearchObj.user,
      industryId: selectIndustrySearchObj._id,
      categoryId: selectCategorySearchObj._id,
      productIds: productsIdds,
    };

    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.post(
      `/userkyc/addBusinessNetwork`,
      body
    );
    if (responseData.status === 200) {
      enqueueSnackbar("New Business Network Added Successfully", {
        variant: "success",
      });
      navigate("/dashboard/addbusinessnetwork");
    } else {
      enqueueSnackbar("This Business Network is Already Added", {
        variant: "error",
      });
    }
  };

  const checkValueOfObject = (obj: any) => {
    return Object.keys(obj).length;
  };
  useEffect(() => {
    if (
      checkValueOfObject(selectBusinessSearchObj) > 0 &&
      checkValueOfObject(selectBusinessSearchObj) > 0 &&
      checkValueOfObject(selectCategorySearchObj) > 0 &&
      checkValueOfObject(selectIndustrySearchObj) > 0 &&
      checkValueOfObject(selectProductSearchObj) > 0
    ) {
      setmainBtnDisabled(false);
    } else {
      setmainBtnDisabled(true);
    }
  }, [
    selectBusinessSearchObj,
    selectBusinessSearchObj,
    selectCategorySearchObj,
    selectIndustrySearchObj,
    selectProductSearchObj,
  ]);

  return (
    <>
      <Box component={"div"} sx={{ px: 2, pt: 2 ,my:{xs:14}}}>
        <Stack>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"22px"}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Add Business Networks
          </Typography>
        </Stack>

        <Stack display={"flex"} direction={"row"} spacing={2}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Public Sans",
            }}
          >
            Dashboard
          </Typography>
          <Typography>.</Typography>

          <Typography
            fontFamily={"Public Sans"}
            fontSize={"12px"}
            fontWeight={400}
            fontStyle={"normal"}
          >
            Business Network
          </Typography>
          <Typography>.</Typography>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"14px"}
            fontWeight={400}
            fontStyle={"normal"}
            color={"rgba(145, 158, 171, 1)"}
          >
            Add Business Network
          </Typography>
        </Stack>
        <Grid display={{xs:"block",md:"flex"}} spacing={2}>
          <Grid container sx={{ mt: 2, mr: 2 }}>
            <Box
              boxShadow={1}
              borderRadius={"16px"}
              // gap={"40px"}
              display={"flex"}
              sx={{
                borderRadius: "8px",
                backgroundColor: "white",
                // width: "100%",
                flexBasis:"100%"
              }}
              height={"auto"}
            >
              <Box sx={{width:"100%"}}>
                <Stack
                  spacing={2.5}
                  display={"flex"}
                  direction={"row"}
                  sx={{ px: 3, py: 3 }}
                >
                  <Button
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      padding: "6px 20px",
                      fontWeight: 700,
                      fontSize: "12px",
                      border: "1px solid #919EAB",
                      fontFamily: "Public Sans",
                      color: searchByValue === "gst" ? "white" : "#919EAB",
                      backgroundColor:
                        searchByValue === "gst"
                          ? "rgba(48, 57, 129, 1)"
                          : "#F7F9FB",
                      "&:hover": {
                        backgroundColor:
                          searchByValue === "gst"
                            ? "rgba(48, 57, 129, 1)"
                            : "#F7F9FB",
                      },
                    }}
                    onClick={() => handleSearchState("gst")}
                  >
                    Search by GST
                  </Button>
                  {/* <Button
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      padding: "6px 16px",
                      fontWeight: 700,
                      fontSize: "12px",
                      border: "1px solid #919EAB",
                      fontFamily: "Public Sans",
                      color:
                        searchByValue === "businessName" ? "white" : "#919EAB",
                      backgroundColor:
                        searchByValue === "businessName"
                          ? "rgba(48, 57, 129, 1)"
                          : "#F7F9FB",
                      "&:hover": {
                        backgroundColor:
                          searchByValue === "businessName"
                            ? "rgba(48, 57, 129, 1)"
                            : "#F7F9FB",
                      },
                    }}
                    onClick={() => handleSearchState("businessName")}
                  >
                    Search by Name
                  </Button>
                  <Button
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      padding: "6px 16px",
                      fontWeight: 700,
                      fontSize: "12px",
                      border: "1px solid #919EAB",
                      fontFamily: "Public Sans",
                      color: searchByValue === "email" ? "white" : "#919EAB",
                      backgroundColor:
                        searchByValue === "email"
                          ? "rgba(48, 57, 129, 1)"
                          : "#F7F9FB",
                      "&:hover": {
                        backgroundColor:
                          searchByValue === "email"
                            ? "rgba(48, 57, 129, 1)"
                            : "#F7F9FB",
                      },
                    }}
                    onClick={() => handleSearchState("email")}
                  >
                    Search by Email
                  </Button>
                  <Button
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      textTransform: "none",
                      padding: "6px 16px",
                      fontWeight: 700,
                      fontSize: "12px",
                      border: "1px solid #919EAB",
                      fontFamily: "Public Sans",
                      color: searchByValue === "mobile" ? "white" : "#919EAB",
                      backgroundColor:
                        searchByValue === "mobile"
                          ? "rgba(48, 57, 129, 1)"
                          : "#F7F9FB",
                      "&:hover": {
                        backgroundColor:
                          searchByValue === "mobile"
                            ? "rgba(48, 57, 129, 1)"
                            : "#F7F9FB",
                      },
                    }}
                    onClick={() => handleSearchState("mobile")}
                  >
                    Search by Mobile
                  </Button> */}
                </Stack>
                <Stack sx={{ px: 3 }}>
                  <Autocomplete
                    options={businessNameList}
                    getOptionLabel={(option: any) =>
                      option.Legal_Name_of_Business
                    }
                    size="medium"
                    loading={loading}
                    onChange={(event, value) =>
                      setselectBusinessSearchObj(value !== null ? value : {})
                    }
                    renderInput={(params: any) => (
                      <TextField
                        sx={{
                          // width: "55vw",
                          "& .MuiFormHelperText-root": {
                            color: "Red",
                            fontFamily: "Public Sans",
                            fontWeight: "600",
                            fontSize: "8",
                            textAlign: "end",
                          },
                        }}
                        {...params}
                        onChange={handleOnChangeForBusinessSearch}
                        onFocus={handleOnChangeForBusinessSearch}
                        InputLabelProps={{
                          sx: {
                            fontSize: "16px",
                            fontFamily: "Public Sans",
                            color: "#919EAB",
                            fontWeight: 400,
                          },
                        }}
                        label={dynamiclabelForSearch(searchByValue)}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            fontSize: "12px",
                            borderRadius: "8px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              {" "}
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                <SearchIcon />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Stack>
                {selectBusinessSearchObj &&
                selectBusinessSearchObj.Place_of_Business ? (
                  <Stack>
                    <Grid container>
                      <Grid xs={6}>
                        <Box
                          sx={{
                            pl: 3,
                            pr: 1.5,
                          }}
                        >
                          <Stack
                            display={"flex"}
                            direction={"row"}
                            textAlign={"start"}
                            spacing={5}
                            sx={{
                              px: 1.5,
                              py: 1.5,
                              mt: 1.5,
                              borderRadius: "8px",
                              backgroundColor: "#F9FAFB",
                            }}
                          >
                            <Box sx={{ px: 0.5 }}>
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#919EAB"}
                              >
                                Rating
                              </Typography>
                              <Rating
                                name="simple-controlled"
                                value={4}
                                size="small"
                                // onChange={(event, newValue) => {
                                //   setValue(newValue);
                                // }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#919EAB"}
                              >
                                Since
                              </Typography>
                              <Typography
                                fontFamily={"Public Sans"}
                                fontSize={"14px"}
                                lineHeight={"22px"}
                                fontWeight={600}
                              >
                                2023
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                component="legend"
                                fontSize={"12px"}
                                color={"#919EAB"}
                              >
                                Orders Full-filed
                              </Typography>
                              <Stack
                                spacing={1}
                                display={"flex"}
                                direction={"row"}
                                // mt={1}
                              >
                                {/* <LinearProgress
                                sx={{
                                  width: "80px",

                                  "&.MuiLinearProgress": {
                                    backgroundColor:
                                      "var(--warning-main, #FFAB00)",
                                  },
                                }}
                                style={{
                                  marginTop: 5,
                                  borderRadius: "50px",
                                  backgroundColor:
                                    "var(--transparent-warning-24, rgba(255, 171, 0, 0.24)",

                                  color: "var(--warning-main, #FFAB00)",
                                }}
                              /> */}
                                <Typography
                                  fontSize={"12px"}
                                  fontFamily={"Public Sans"}
                                >
                                  30/100
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid xs={6}>
                        {" "}
                        <Box
                          sx={{
                            ml: 1.3,
                            pr: 3,
                          }}
                        >
                          <Stack
                            textAlign={"start"}
                            //   spacing={5}
                            sx={{
                              px: 1.5,
                              py: 0.9,
                              mt: 1.5,

                              borderRadius: "8px",
                              backgroundColor: "#F9FAFB",
                            }}
                          >
                            <Box>
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#919EAB"}
                              >
                                Address
                              </Typography>
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#000"}
                              >
                                {selectBusinessSearchObj.Place_of_Business !==
                                ""
                                  ? selectBusinessSearchObj.Place_of_Business
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                ) : (
                  ""
                )}
                {selectBusinessSearchObj &&
                selectBusinessSearchObj.nameInAadhaar ? (
                  <Stack>
                    <Grid container>
                      <Grid xs={6}>
                        <Box
                          sx={{
                            pl: 3,
                            pr: 1.5,
                          }}
                        >
                          <Stack
                            minHeight={"50px"}
                            display={"flex"}
                            direction={"row"}
                            textAlign={"start"}
                            spacing={5}
                            sx={{
                              px: 1.5,
                              py: 0.9,
                              mt: 1.5,
                              borderRadius: "8px",
                              backgroundColor: "#F9FAFB",
                            }}
                          >
                            <Box sx={{ px: 0.5 }}>
                              <Typography fontSize={"11px"} color={"#919EAB"}>
                                GST Number
                              </Typography>
                              <Typography fontSize={"11px"}>
                                {selectBusinessSearchObj.GSTIN_of_the_entity !==
                                ""
                                  ? selectBusinessSearchObj.GSTIN_of_the_entity
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid xs={6}>
                        {" "}
                        <Box
                          sx={{
                            ml: 1.3,
                            pr: 3,
                          }}
                        >
                          <Stack
                            textAlign={"start"}
                            height={"40px"}
                            //   spacing={5}
                            sx={{
                              px: 1.5,
                              py: 1.5,
                              mt: 1.5,
                              borderRadius: "8px",
                              backgroundColor: "#F9FAFB",
                            }}
                          >
                            <Box>
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#919EAB"}
                              >
                                Authorized Business Representative
                              </Typography>
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#000"}
                              >
                                {selectBusinessSearchObj.nameInAadhaar !== ""
                                  ? selectBusinessSearchObj.nameInAadhaar
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                ) : (
                  ""
                )}

                <Stack
                  display={"flex"}
                  direction={"row"}
                  px={3}
                  py={1.5}
                  spacing={3}
                >
                  <Autocomplete
                    options={industryNameList}
                    disableClearable
                    clearOnBlur
                    getOptionLabel={(option: any) => option.industryName}
                    onChange={(event, value) =>
                      setselectIndustrySearchObj(value !== null ? value : {})
                    }
                    fullWidth
                    renderInput={(params: any) => (
                      <TextField
                        sx={{
                          "& .MuiFormHelperText-root": {
                            color: "Red",
                            fontFamily: "Public Sans",
                            fontWeight: "600",
                            fontSize: "8",
                          },
                        }}
                        {...params}
                        onFocus={HandleOnChangeIndustryList}
                        onChange={HandleOnChangeIndustryList}
                        InputLabelProps={{
                          sx: {
                            fontSize: "14px",
                            fontFamily: "Public Sans",
                            color: "#919EAB",
                            fontWeight: 400,
                          },
                        }}
                        label="Search Industry"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            borderRadius: "8px",
                          },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    options={categoryNameList}
                    disableClearable
                    getOptionLabel={(option: any) => option.categoryName}
                    onChange={(event, value) =>
                      setselectCategorySearchObj(value !== null ? value : {})
                    }
                    fullWidth
                    renderInput={(params: any) => (
                      <TextField
                        fullWidth
                        // helperText={
                        //   searchByValue === "gst" &&
                        //   GSTNumberValidation(searchValue) === false
                        //     ? "Gst Number Should be 16 character's"
                        //     : ""
                        // }
                        sx={{
                          "& .MuiFormHelperText-root": {
                            color: "Red",
                            fontFamily: "Public Sans",
                            fontWeight: "600",
                            fontSize: "8",
                          },
                        }}
                        {...params}
                        onChange={HandleOnChangeCategoryList}
                        onFocus={HandleOnChangeCategoryList}
                        InputLabelProps={{
                          sx: {
                            fontSize: "14px",
                            fontFamily: "Public Sans",
                            color: "#919EAB",
                            fontWeight: 400,
                          },
                        }}
                        label="Search Category"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            borderRadius: "8px",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack display={"flex"} direction={"row"} px={3} spacing={3}>
                  <Autocomplete
                    options={productNameList}
                    disableClearable
                    getOptionLabel={(option: any) => option.productName}
                    onChange={(event, value) => {
                      handleChangeSelectedProductsState(value);
                      // setShowProducts([value[0]]);
                    }}
                    fullWidth
                    multiple
                    renderInput={(params: any) => (
                      <TextField
                        fullWidth
                        sx={{
                          "& .MuiFormHelperText-root": {
                            color: "Red",
                            fontFamily: "Public Sans",
                            fontWeight: "600",
                            fontSize: "8",
                          },
                        }}
                        {...params}
                        onChange={HandleOnChangeProductList}
                        onFocus={HandleOnChangeProductList}
                        InputLabelProps={{
                          sx: {
                            fontSize: "16px",
                            fontFamily: "Public Sans",
                            color: "#919EAB",
                            fontWeight: 400,
                          },
                        }}
                        label="Search Product"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            borderRadius: "8px",
                          },
                        }}
                      />
                    )}
                  />

                  {/* <Stack
                      
                    > */}
                  <Box
                    my={1}
                    sx={{
                      width: "100%",
                    }}
                    borderRadius={"8px"}
                    display={"flex"}
                    // textAlign={"start"}
                    // justifyContent={"start"}
                  >
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      //  textAlign={"center"} flexWrap="wrap-reverse"
                    >
                      {selectProductSearchObj.length > 0
                        ? selectProductSearchObj.map(
                            (item: any, index: any) => {
                              return (
                                <Box
                                  sx={{
                                    backgroundColor: "lightgreen",
                                    p: 0.5,
                                    mx: 0.5,
                                    height: "fit-content",
                                  }}
                                  borderRadius={"8px"}
                                >
                                  <Typography
                                    fontFamily={"Public Sans"}
                                    fontSize={"12px"}
                                  >
                                    {item.productName}
                                  </Typography>
                                </Box>
                              );
                            }
                          )
                        : ""}
                    </Stack>
                  </Box>
                </Stack>
                <Box>
                  <Grid container>
                    <Grid xs={6}></Grid>
                    <Grid xs={6}>
                      {" "}
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"start"}
                        pb={2}
                      >
                        <Button
                          size="medium"
                          disabled={mainBtnDisabled}
                          onClick={HandleOnClickAddBusinessNetwork}
                          sx={{
                            borderRadius: "8px",
                            mt: 4,
                            px: 4,
                            mx: 4,
                            // backgroundColor: "rgba(145, 158, 171, 0.24)",303981)
                            backgroundColor: mainBtnDisabled
                              ? "rgba(145, 158, 171, 0.24)"
                              : "#303981",
                            textAlign: "right",
                            fontFamily: "Public Sans",
                            textTransform: "none",
                            color: mainBtnDisabled ? "dark" : "white",
                            "&:hover": {
                              backgroundColor: mainBtnDisabled
                                ? "rgba(145, 158, 171, 0.24)"
                                : "#303981",
                            },
                          }}
                        >
                          Add Business to my Networks
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid display={"flex"} container sx={{ mt: 2,flexBasis:"30%" }}>
            <Box
              boxShadow={1}
              borderRadius={"16px"}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                borderRadius: "8px",
                // ml: 4,

                backgroundColor: "white",
                width: "100%",
                // minWidth: "40vh",
                maxWidth:{xs:"100vh",md:"40vh"},
                // maxHeight: "65vh",
                // px: 5,
                px: 3,
              }}
            >
              <Stack
                display={"flex"}
                sx={{ mt: 2 }}
                // justifyContent={"center"}
                textAlign={"center"}
              >
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"18px"}
                  fontWeight={700}
                  fontStyle={"normal"}
                  color={"#161C24"}
                  lineHeight={"28px"}
                >
                  Can’t Find business? Invite to AP.
                </Typography>
                <Box>
                  <img
                    src={AddtoBusinessNetworkSvg}
                    alt=""
                    height={"152px"}
                    //   width={"207px"}
                    style={{margin:"50px 0 0 0"}}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Stack spacing={1}>
                    <TextField
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          maxWidth: "412px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "16px",
                          fontFamily: "Public Sans",
                          color: "#919EAB",
                          fontWeight: 400,
                        },
                      }}
                      label="Email"
                    />
                    <TextField
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          maxWidth: "412px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "16px",
                          fontFamily: "Public Sans",
                          color: "#919EAB",
                          fontWeight: 400,
                          zIndex:0
                        },
                      }}
                      label="Mobile Number"
                    />
                  </Stack>
                  <div>
                    <Button
                      size="medium"
                      sx={{
                        // display: "flex",
                        color: "white",
                        borderRadius: "8px",
                        my: 2,
                        px: 2,
                        backgroundColor: "#303981",
                        textAlign: "right",
                        fontFamily: "Public Sans",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#303981",
                        },
                      }}
                    >
                      Invite
                    </Button>
                  </div>
                </Box>
              </Stack>
            </Box>
          </Grid>
          {/* <Grid container></Grid> */}
        </Grid>
      </Box>

      <Stack mt={8}></Stack>
    </>
  );
};

export default Busi2;
